import React, { useRef, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { BiRupee } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SelfTestimg } from "assets/images";
import "./CoursePages.scss";

import ContactusForm from "components/contactus-form/Contactus-form";
import DemoLacture from "components/demo-lacture/DemoLacture";
import FAQ from "components/FAQs/FAQs";
import Testimonials from "components/testimonial/Testimonial";
import InstituteToppers from "components/institute-toppers/InstituteToppers";
import { toast } from "react-hot-toast";
import { getCourseDetail } from "services/courses/courses";
import { useAddons, useLanguage } from "context/context";
import {
  courseAccess,
  isLoggedIn,
  momentDateFormat,
  timeFormatWith12Hour,
} from "config/utils";
import CoreTakeAway from "components/core-take-away/CoreTakeAway";
import YoutubeVideo from "components/video-player/YoutubeVideo";
import RecommendedCourses from "components/recommended-courses/RecommendedCourses";
import BillingSummery from "pages/course/Section/BillingSummery";
import CourseDetailSkeleton from "components/skeleton/CourseDetailSkeleton";
import Slider from "react-slick";
import CollapseSuperTag from "components/SuperTag/CollapseSuperTag";
import { errorResponse, failResponse, normalEncryptData } from "config/config";
import moment from "moment";
import { useLocation } from "react-router-dom";

const QEPGSCrashCourseforPrelims2024ClassroomCourse = () => {
  //const { id, name } = useParams();
  
  const [index, setIndex] = React.useState(0);
  const [courseData, setCourseData] = useState({});
  const [detailVideo, setDetailVideo] = useState({});
  const [coreTakeAway, setCoreTakeAway] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [demoLecture, setDemoLecture] = useState([]);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [allFaculties, setAllFaculties] = useState([]);
  const [allAddOns, setAllAddOns] = useState([]);
  const { addons } = useAddons();
  const [onlineIndex, setOnlineIndex] = useState(0);
  const [classroom, setClassroom] = useState([]);
  const [online, setOnline] = useState([]);
  const [regional, setRegional] = useState([]);
  const [toppers, setToppers] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [regionalCenters, setRegionalCenters] = useState([]);
  const location = useLocation();
  const [isFromUpcomingPage, setIsFromUpcomingPage] = useState(false);
  const [eventKey, setEventKey] = useState("modes");
  

  const courseRef = useRef();

  const [loading, setLoading] = useState(true);

  const { language } = useLanguage();
  const navigate = useNavigate();

  const id = "a5db0ae6-f2b0-4de6-a4ac-356262aea68d";
  const name = "(QEP)%20GS%20Crash%20Course%20for%20Prelims%202024%20Classroom%20Course";

  // Slider //

  var sliderFaculties = {
    dots: false,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 8000,
    centerMode: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          centerPadding: "40px",
        },
      },
    ],
  };
  
  // scroll to addons
  const handleScroll = async () => {
    await setEventKey("modes");
    courseRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // course enroll handler
  const courseEnrollHandler = (id, name) => {
    if (courseAccess(courseData?.AdmissionStartDate)) {
      if (isLoggedIn()) {
        navigate(`/course-subscription/${name}/${id}`);
      } else {
        navigate("/login", {
          state: { id, name, freeCourse: courseData.CourseFee === 0 },
        });
      }
    } else {
      toast.error("this is upcoming course ");
    }
  };

  // ----------------------- getting course detail data -------------------------//
  React.useLayoutEffect(() => {
      // Extract the previous path from the location state
      const previousPath = location.state?.previousPath;
  
      setIsFromUpcomingPage(previousPath == "/upcoming-courses");
  
    const getCourseDetailData = async () => {
      try {
        setLoading(true);
        const res = await getCourseDetail({ courseId: id, language });

        if (res.code === 200) {
          const { courseDetails } = res.data;

          setCourseData(courseDetails || {});
          setDetailVideo(courseDetails.DetailVideo || {});
          setCoreTakeAway(courseDetails.coreTakeAway || []);
          setFaqs(courseDetails.faq || []);
          setDemoLecture(courseDetails.demoLectures || []);
          setAllFaculties(courseDetails.faculties?.rows || []);
          setRecommendedCourses(courseDetails.RecommendedCourse || []);
          setAllAddOns(courseDetails.addOns || []);
          setClassroom(courseDetails.platformDetail?.classroom.details || []);
          setOnline(courseDetails.platformDetail?.online || []);
          setRegional(courseDetails.platformDetail?.regional.details || []);
          setToppers(courseDetails?.ourToppers || []);
          setTestimonials(courseDetails.testimonial?.rows || []);
          setRegionalCenters(courseDetails?.masterCenter || []);
          setLoading(false);
        } else {
          failResponse(res);
          setLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setLoading(false);
      }
    };

    getCourseDetailData();
  }, [id, language]);

  // ----------------------- getting course detail with addons data -------------------------//
  React.useLayoutEffect(() => {
    // get course detail with addons
    const getCourseDetailData = async () => {
      try {
        const addonsData = addons[id]?.map((add) => add.id);

        const res = await getCourseDetail({
          courseId: id,
          language,
          addon_ids: addonsData,
        });

        if (res.code === 200) {
          const { courseDetails } = res.data;
          localStorage.setItem("supcoursename",name);
          localStorage.setItem("supcourseid",id);
          setCourseData(courseDetails || {});
        } else {
          navigate("/course-listing");
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    getCourseDetailData();
    // eslint-disable-next-line
  }, [addons]);

  return (
    <section className="course_detail_section">
      {/* Breadcrumb */}
      <div className="breadcrumb">
        <div className="container">
          <ul>
            <li>
              <Link to="/course-listing">course listing</Link>
            </li>
            <li>
              <Link to={`/course-detail/${name}/${id}`}>Course details</Link>
            </li>
          </ul>
        </div>
      </div>
      {/* // loading component */}
      {loading && <CourseDetailSkeleton />}

      {/* main component */}
      {!loading && (
        <>
          <div className="banner spacing">
            <div className="container">
              <div className="banner_content">
                <div className="content">
                  {!courseAccess(courseData?.AdmissionStartDate) && (
                    <span className="_tag">UPCOMING</span>
                  )}
                  <h3>
                    {courseData.DisplayNameHindi || courseData.DisplayNameEng}
                  </h3>

                  <CollapseSuperTag
                    limit={300}
                    scroller={() =>
                      window.scrollTo(0, 0, { behavior: "smooth" })
                    }
                  >
                    {courseData.CourseDescription || ""}
                  </CollapseSuperTag>
                  {/* <p></p> */}
                  {/* <b>Delhi Classroom Mode</b> */}

                  <div className="button_wrap">
                    <button className="button" onClick={handleScroll}>
                      View Details
                      <span className="icon">
                        <BsArrowRight />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="video_section">
                  {/* course detail video */}
                  {detailVideo.videoPlateform === "youtube" && (
                    <YoutubeVideo src={detailVideo.videoURL} />
                  )}

                  {/* <video width="600" height="300" controls>
                <source
                  src={courseData.videoURL || Dummyvideo}
                  type="video/ogg"
                />
                Your browser does not support the html video tag.
              </video> */}
                </div>
              </div>
            </div>
          </div>

          {/* ////////////////////// Number Data /////////////////// */}
          {courseData.id !== '280e4431-6460-463c-aace-081bc8c4cce7' && (
          <div className="number_data spacing">
            <div className="container">
              <div className="number_data_content">
                <ul>
                  <li>
                    <h3>{courseData.BatchNumber} </h3>
                    <span>Batch Number</span>
                  </li>
                  <li>
                    <h3>{courseData.CourseDuration} months </h3>
                    <span>Duration</span>
                  </li>
                  {+courseData?.isOnDemand === 0 && (
                    <li>
                      <h3>
                        {timeFormatWith12Hour(courseData.BatchTime) ||
                          "-- : --"}
                      </h3>
                      <span>Class Time </span>
                    </li>
                  )}
                  <li>
                  <h3>{+courseData?.isOnDemand === 1 ? moment().format("DD MMM YYYY") : momentDateFormat(courseData.CourseStartDate)} </h3>
                  <span>Starting Date</span>
                </li>

                  {/* <li>
                    <h3>{momentDateFormat(courseData.CourseStartDate)} </h3>
                    <span>Starting Date</span>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          )}
          {/* ////////////////////// Tab Content /////////////////// */}
          <div className="tab_content multitab spacing">
            <div className="container">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="modes"
                activeKey={eventKey}
                onSelect={(k) => setEventKey(k)}
              >
                <div className="courseTitle">
                  <Nav variant="pills" className="">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="modes"
                        onClick={() => setEventKey("modes")}
                      >
                        Modes & Pricing
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Faculty"
                        onClick={() => setEventKey("Faculty")}
                      >
                        Faculty
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                  <Nav.Link eventKey="course-content">Course Content</Nav.Link>
                </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Interviews"
                        onClick={() => setEventKey("Interviews")}
                      >
                        Reviews & Topper’s Interviews
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="navtab_content_wrap">
                  <Tab.Content>
                    <Tab.Pane eventKey={"modes"}>
                      <div className="navtab_content">
                        {/* COMPARE CHOSEN COURSES */}
                        <div className="compare_course">
                          <div className="page_title">
                            <h3>
                              Get complete clarity on the course modes and their
                              pricings
                            </h3>
                            <p>
                              Course offerings differ based on the modes they
                              are conducted in as displayed below
                            </p>
                          </div>
                          <div className="feescard_wrap">
                            {/* online card */}
                            {(courseData.CourseType === "Online" ||
                              courseData.CourseType === "Both") && (
                              <div className="feescard">
                                <div className="card_header">
                                  <h4>Online</h4>
                                  <span>Online classes</span>
                                  <strong className="course_price">
                                    <BiRupee />{" "}
                                    {courseData.TotalDiscountedAmount}
                                    /-
                                  </strong>
                                </div>
                                <div className="card_content">
                                  <ul>
                                    {online[onlineIndex]?.details &&
                                      online[onlineIndex]?.details?.map(
                                        (data, i) => {
                                          return <li key={i}>{data}</li>;
                                        }
                                      )}
                                  </ul>

                                  {/* hide selection if data isn't available */}
                                  {online?.length > 0 && (
                                    <div className="button_wrap">
                                      <select
                                        className="input dropdown_select"
                                        value={onlineIndex}
                                        onChange={({ target }) =>
                                          setOnlineIndex(target.value)
                                        }
                                      >
                                        {online &&
                                          online.map((data, i) => {
                                            return (
                                              <option key={i} value={i}>
                                                {`${data.platform}`}
                                              </option>
                                            );
                                          })}
                                      </select>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            {/* classroom card */}
                            {((courseData.CourseType === "Classroom" &&
                              courseData.CourseFor !== "RC") ||
                              courseData.CourseType === "Both") && (
                              <div className="feescard">
                                <div className="card_header">
                                  <h4>Classroom</h4>
                                  <span>Offline Classes </span>
                                  <strong className="course_price">
                                    <BiRupee />
                                    {courseData.TotalDiscountedAmount}
                                    /-
                                  </strong>
                                  <span className="recommended">
                                    Recommended
                                  </span>
                                </div>
                                <div className="card_content">
                                  <ul>
                                    <ul>
                                      {classroom &&
                                        classroom.map((data, i) => {
                                          return <li key={i}>{data}</li>;
                                        })}
                                    </ul>
                                  </ul>

                                  {/* <div className="button_wrap">
                                    <Link
                                      to=""
                                      className="button dropdown_select"
                                    >
                                      Enroll Now
                                    </Link>
                                  </div> */}
                                </div>
                              </div>
                            )}

                            {/* regional card will visible if centers are available*/}
                            {regionalCenters?.length > 0 && (
                              <div className="feescard">
                                <div className="card_header">
                                  <h4>Regional</h4>
                                  <span>Classes in your city</span>
                                  {/* <strong className="course_price">
                                  <BiRupee /> {courseData.TotalDiscountedAmount}
                                  /-
                                </strong> */}
                                  <Link
                                    // to={`/${regionalCenters[
                                    //   index
                                    // ]?.CenterData?.City?.replace(
                                    //   /\s/g,
                                    //   "-"
                                    // )}/center/${normalEncryptData(
                                    //   regionalCenters[index]?.CenterData
                                    //     ?.centerPageData?.id,
                                    //   "route"
                                    // )}`}
                                    to={`/IAS_Institute_${regionalCenters[index]?.CenterData?.City?.replace(/\s/g, "-").replace(",", "")}`}

                                  >
                                    Go To&nbsp;
                                    {regionalCenters[index]?.CenterData?.City}
                                    &nbsp;Page
                                  </Link>
                                </div>
                                <div className="card_content">
                                  <ul>
                                    {regional &&
                                      regional.map((data, i) => {
                                        return <li key={i}>{data}</li>;
                                      })}
                                  </ul>

                                  <div className="button_wrap">
                                    <select
                                      className="input dropdown_select"
                                      value={index}
                                      onChange={({ target }) =>
                                        setIndex(target.value)
                                      }
                                    >
                                      {regionalCenters &&
                                        regionalCenters.map(
                                          ({ CenterData }, i) => {
                                            return (
                                              <option key={i} value={i}>
                                                {`${CenterData?.CenterName}` ||
                                                  "N/A"}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Billing summery */}
                        <BillingSummery
                          courseRef={courseRef}
                          courseData={courseData}
                          allAddOns={allAddOns}
                          enrollAction={() =>
                            courseEnrollHandler(
                              courseData.id,
                              courseData.DisplayNameHindi ||
                                courseData.DisplayNameEng
                            )
                          }
                          id={id}
                        />

                        {/* Recommended Course  */}

                        <RecommendedCourses
                          courses={recommendedCourses}
                          loading={loading}
                          title={
                            "Recommended course combos with general studies"
                          }
                        />
                      </div>
                    </Tab.Pane>

                    {/* Faculty */}

                    <Tab.Pane eventKey={"Faculty"}>
                      <div className="navtab_content">
                        <div className="page_title">
                          {/* <h3>
                            Learn about faculty who are behind conducting this
                            course
                          </h3> */}
                          <h3>
                          Learn about faculties of this course.
                          </h3>
                          <p>
                          Faculties are highly qualified and experienced , who understand the dynamics of the exam and integrate it in their teachings.
                          </p>
                        </div>
                        {allFaculties?.length > 0 && (
                          <>
                            {/* <FacultiesList
															faculties={allFaculties}
															loading={loading}
														/> */}
                            <div className="all_faculties_listing spacing">
                              <Slider {...sliderFaculties}>
                                {allFaculties.map((item, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <div className="item">
                                        <Link
                                          to={`/teacher/${item.name}/${item.id}`}
                                        ></Link>
                                        <div className="image_wrap">
                                          <img
                                            src={item.image}
                                            alt={item.name}
                                          />
                                        </div>
                                        <h5>{item.name}</h5>
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                              </Slider>
                            </div>
                          </>
                        )}
                      </div>
                    </Tab.Pane>

                    {/* Reviews & Topper’s Interviews */}

                    <Tab.Pane eventKey={"Interviews"}>
                      <div className="navtab_content">
                        <Testimonials
                          testimonials={testimonials}
                          loading={loading}
                        />
                        <InstituteToppers
                          toppersArray={toppers}
                          title={"Institute Toppers"}
                          loading={loading}
                        />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </div>

          {/* ////////////////////// self Assessment test /////////////////// */}

          <div className="self_assessment spacing">
            <div className="container">
              <div className="assessment_content">
                <div className="image_wrap">
                  <img src={SelfTestimg} alt="" loading="lazy" />
                </div>

                <div className="content">
                  <h5>Take our self-assessment test</h5>
                  <p>
                    Evaluate your current skillsets with our unique analysis
                    tool
                  </p>
                </div>

                <div className="button_wrap fill">
                  <Link to="/daily-quiz" className="button">
                    Take quiz
                    <span className="icon">
                      <BsArrowRight />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
                                
          
          
          {/* Demo Lecture */}
          {courseData.id !== '280e4431-6460-463c-aace-081bc8c4cce7' && <DemoLacture loading={loading} lectures={demoLecture} />}

          {/* Core takeaways from this course */}
          <CoreTakeAway
            title={"Core takeaways from this course"}
            description={
              "At Shubhra Ranjan, we strongly believe in designing courses that go beyond the classroom, and enhance your knowledge from a              holistic point of view"
            }
            loading={loading}
            coreTakeAway={coreTakeAway}
          />

          {/* FAQs */}
          <FAQ loading={loading} faqs={faqs} />
          {/* Contact us Form */}
          <ContactusForm
          center={isFromUpcomingPage ? regionalCenters[index]?.CenterData?.City : undefined}
          address={isFromUpcomingPage ? regionalCenters[index]?.CenterData?.Address : undefined}
          centerId={isFromUpcomingPage ? regionalCenters[index]?.CenterData?.CenterID : undefined}
          coursetitle={isFromUpcomingPage ? courseData.DisplayNameEng : undefined}
           />
        </>
        
      )}
    </section>
  );
};


export default QEPGSCrashCourseforPrelims2024ClassroomCourse;
