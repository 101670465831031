import React, { useEffect, useState } from "react";
import "./Syllabus.scss";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { errorResponse, failResponse } from "config/config";

const GS1 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial mobile check
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setContent(`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        .Eligibilityh3 {
            font-size: 18px;
            margin-bottom: 10px;
            text-align: center;
        }

        .Eligibilityp {
            margin-bottom: 8px;
            font-size: 15px;
            font-weight: 500;
            margin-top: 10px;
        }
		body{
		line-height:35px;
		}
		.title{
		color:#00239c;
		}
		.title1{
		color:#D2042D;
		}
		.pagetitle{
		color:#00239c;
		text-align: center;font-size: 26px;
		}
    </style>
</head>
<body>
    <h3 class="pagetitle">UPSC Mains General Studies Paper 1 Overview</h3><br/>
    <p class="Eligibilityp"><strong>General Studies Paper 1</strong> in the UPSC Mains exam is one of the core components that shapes the preparation of IAS aspirants. This paper covers a broad spectrum of topics segmented into distinct subjects like History, Geography, Art and Culture, and Indian Society. Each subject requires a unique approach and diverse source materials for effective preparation.</p>
    
    <strong class="title1">Question Format</strong>
    <ul style="list-style-type: disc; margin-left: 20px;padding-left:20px;">
        <li><strong>Number of Questions:</strong> 20 compulsory questions</li>
        <li><strong>Languages:</strong> Questions are presented in both Hindi and English. Answers must be written in the language specified during the application process; other languages will not be evaluated.</li>
        <li><strong>Total Marks:</strong> 250 marks</li>
    </ul>
    <strong class="title1">Word Limits</strong>
    <ul style="list-style-type: disc; margin-left: 20px;padding-left:20px;">
         <li><strong>10-mark Questions:</strong> Maximum of 150 words</li>
        <li><strong>15-mark Questions:</strong> Maximum of 250 words</li>
    </ul>
    <strong class="title1">Key Subject Areas</strong>
        <br/>
          <strong class="title" style="padding-left:20px">1.  Geography</strong><br/>
          <strong class="title" style="padding-left:32px">Major Topics:</strong>
          <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
                <li>World’s Physical Geography.</li>
                <li>Distribution of Key Natural Resources.</li>
                <li>Geophysical Phenomena like earthquakes, tsunamis, and cyclones.</li>
                <li>Environmental changes and their effects on geography and biodiversity.</li>
           </ul><br/>
          
           <strong class="title" style="padding-left:32px">Sources and Approach:</strong>
          <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
                <li> NCERT Textbooks from Classes VI to XII are fundamental for building a strong physical and human geography base.</li>
           </ul><br/>
          
           <strong class="title" style="padding-left:32px">Exam Analysis:</strong>
          <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
               <li><strong>Climate: </strong> The fluctuation in marks, especially the higher emphasis in 2017 (60 marks) and 2023 (35 marks), underscores the growing importance of climate-related topics.</li>
                <li><strong>Disaster and Urbanization:</strong> Both these topics have had minimal and inconsistent focus over the years, reflecting a selective approach to these aspects within the syllabus.</li>
                <li><strong>Physical Geography: </strong> Consistently receiving attention, with an increased focus in 2023 (40 marks) and 2024 (40 marks), indicating its core importance in geographical studies.</li>
                <li><strong>Resources and Industrial Location: </strong>While the focus on resources has seen high variation, industrial location topics have gradually diminished, being completely absent in recent years.</li>
          </ul><br/>
          
          <strong class="title" style="padding-left:20px">2. Indian Society</strong>
          <br/>
          <strong class="title" style="padding-left:35px">Major Topics:</strong>
          <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
                <li>Salient features of Indian Society and Diversity.</li>
                <li>Role of Women, Women’s Organizations, and Population Issues.</li>
                <li>Poverty, Developmental Challenges, Urbanization.</li>
                <li>Effects of Globalization, Social Empowerment, Communalism, Regionalism, and Secularism.</li>
           </ul><br/>
          
           <strong class="title" style="padding-left:35px">Sources and Approach:</strong>
          <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
                <li>Regular reading of Newspapers and Magazines like Economic and Political Weekly (EPW), along with reports from NGOs and international organizations, can provide contemporary insights and data.</li>
           </ul><br/>
          
           <strong class="title" style="padding-left:35px">Exam Analysis:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li><strong>Religion, Region, Empowerment:</strong> There has been an increasing trend towards questions on these themes, especially notable in 2023 (50 marks) and 2024 (55 marks), reflecting growing importance.</li>
           <li><strong>Poverty, Population, Development:</strong> This category shows sporadic emphasis, with a more significant focus in recent years, suggesting rising importance in addressing these issues.</li>
           <li><strong>Globalization Impact:  </strong> The impact of globalization saw no attention in some years but peaked at 40 marks in 2020. The inconsistency could point to selective emphasis based on current global trends.</li>
           <li><strong>Women:</strong> This topic has varied focus over the years with some years like 2017 seeing higher marks (35) and others like 2022 and 2023 showing minimal focus.</li>
           </ul><br/>
           
           <strong class="title" style="padding-left:20px">3. History</strong><br>
           <strong class="title" style="padding-left:35px">Major Topics:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li><strong>The Freedom Struggle:</strong>Various stages and significant contributions from different regions.</li>
           <li><strong>Post-independence :</strong> Consolidation and Reorganization.</li>
           <li><strong>World History:</strong> Events from the 18th century like the Industrial Revolution, world wars, and political philosophies (Communism, Capitalism, Socialism).</li>
           <li><strong>Art & Culture: </strong>Salient aspects of Art Forms, Literature, and Architecture from ancient times.</li>
           </ul><br/>

           <strong class="title" style="padding-left:32px">•	Focus Area:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li>Art and culture including classical dances, temple architecture, ancient Indian themes, literature, and musical instruments.</li>
           <li>Modern History focuses on important movements like the Swadeshi and Boycott movement, Khilafat and Non-cooperation movement, Civil Disobedience movement, and Quit India movement. It also covers the contributions of significant personalities and social/religious reform movements.</li>
           <li>Post-independence developments, the integration of princely states, and political philosophies such as Communism, Capitalism, and Socialism.</li>
           <li>World History encompasses global events like the American, French, and Russian revolutions, World Wars I and II, and industrial developments.</li>
           </ul><br/>
      
           <strong class="title"style="padding-left:35px">• Sources and Approach:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li>
           
                <strong class="title">Art & Culture:</strong>
                <ul style="list-style-type: disc; margin-left: 20px;padding-left:20px;font-style:italic">
                  <li>Books: "India’s Ancient Past" by RS Sharma, "Facets of Indian Culture" by Spectrum Publications</li>
                  <li>Website: Centre for Cultural Resources & Training (CCRT)</li>
                </ul>
           </li>
           <li>
                <strong class="title">Modern History:</strong>
               <ul style="list-style-type: disc; margin-left: 20px;padding-left:20px;font-style:italic">
                <li>Books: "India’s Struggle for Independence" by Bipan Chandra, "A Brief History of Modern India" by Rajiv Ahir (Spectrum Publications)</li>
               </ul>
           </li>
           <li>               
               <strong class="title">Post-Independence India:</strong>
               <ul style="list-style-type: disc; margin-left: 20px;padding-left:20px;font-style:italic">
               <li>Book: "India Since Independence" by Bipan Chandras</li>
               </ul>
           </li>
           <li>
               <strong class="title">World History:</strong>
              <ul style="list-style-type: disc; margin-left: 20px;padding-left:20px;font-style:italic">
               <li>Books: "Mastering World History" by Norman Lowe, "History of the World" by Arjun Dev</li>
              </ul>
           </li>
           </ul><br/>
      
           <strong class="title" style="padding-left:35px">Exam Analysis:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li><strong>Culture:</strong> The focus on culture has fluctuated significantly, with a peak in 2020 (50 marks) and notable decreases in years like 2017 and 2019. This indicates varying emphasis on cultural topics from year to year.</li>
           <li><strong>Freedom Struggle: </strong>This topic showed a high emphasis in 2017 (65 marks) but generally maintains a moderate focus, suggesting a consistent relevance in the syllabus.</li>
           <li><strong>World History: </strong> Notably absent in 2020 and 2022, World History has seen varied focus, peaking initially in 2013 (40 marks) and showing a resurgence in 2024 (30 marks).</li>
           <li><strong>Post-independence: </strong> This area has been largely neglected, with zero marks allocated in several years and a minimal focus when included.</li>
           </ul><br/>


    </body>
</html>
`);
  });

  useEffect(() => {
    // Function to fetch the content
    const fetchUPSCcontent = async () => {
      try {
        // const res = await GetUPSCcontent();
        // if (res.code === 200) {
        //   const { htmlcontent } = res.data.content[3];
        //   setContent(htmlcontent);
        // } else {
        //   failResponse(res);
        // }
      } catch (err) {
        errorResponse(err);
      }
    };

    // Function to update isMobile state on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    fetchUPSCcontent(); // Fetch content on component mount

    window.addEventListener("resize", handleResize); // Listen for window resize
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up listener on unmount
    };
  }, []); // Empty dependency array ensures this runs only once (on mount)

  // Handle back button functionality
  const handleBack = () => {
    navigate("/freeresources/FRMains"); // Use navigate to go back to the specified route
  };

  return (
    <div className="syllabus-container">
      {isMobile && (
        <div className="mobile-back-arrow" onClick={handleBack}>
          <FaChevronLeft style={{ color: "#181822", fontSize: "24px" }} />{" "}
          {/* Font Awesome Arrow Icon */}
        </div>
      )}
      <div className="subsection-prelims-syllabus-container">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default GS1;
