import { Userimg } from "assets/icons";
import CommonModal from "components/modal-box/CommonModal";
import { errorResponse, failResponse } from "config/config";
import { useCourse } from "context/context";
import useButtonLoader from "hooks/useButtonLoader";
import useCookie from "hooks/useCookie";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { FaExclamationCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { userLogout } from "services/auth";

const Avatar = ({ type = "dashboard", onClick }) => {
  const Toggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      role="button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  const [logout, setLogout] = React.useState(false);
  const { MentorshipFlag} = useCourse();



  const { removeCookie } = useCookie();
  const { setCourse } = useCourse();
  const navigate = useNavigate();
  const [buttonRef, setSubmitLoading] = useButtonLoader("Yes, log me out");

  //logout handler
  const logoutHandler = async (e) => {
    e.preventDefault();
    try {
      setSubmitLoading(true);
      const res = await userLogout();
      if (res.code === 200) {
        removeCookie();
        sessionStorage.removeItem("course");
        setCourse({});
        navigate("/");
        setSubmitLoading(false);
      } else {
        failResponse(res);
        setSubmitLoading(false);
      }
    } catch (err) {
      setSubmitLoading(false);
      errorResponse(err);
    }
  };
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as={Toggle} id="dropdown-custom-components">
          <img src={Userimg} alt="" loading="lazy" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/dashboard/home" onClick={onClick}>
            Dashboard
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/dashboard/profile" onClick={onClick}>
            Profile
          </Dropdown.Item>
          {/* <Dropdown.Item as={Link} to="/dashboard/postal-folder-listing" onClick={onClick}>
            Postal Course Document
          </Dropdown.Item> */}

          <Dropdown.Item
            as={Link}
            to="/dashboard/re-upload-answer-sheet"
            onClick={onClick}
          >
            Re-upload Answer Sheet
          </Dropdown.Item>

          {MentorshipFlag === true && (<Dropdown.Item
            as={Link}
            to="/dashboard/my-appointments"
            onClick={onClick}
          >
            My Appointments
          </Dropdown.Item>)}

          {MentorshipFlag === true && (<Dropdown.Item
            as={Link}
            to="/dashboard/upcoming-appointments"
            onClick={onClick}
          >
            Upcoming Appointments
          </Dropdown.Item>)}

          {/* <Dropdown.Item
            as={Link}
            to="/dashboard/videos"
            onClick={onClick}
          >
            Play Apex Video
          </Dropdown.Item> */}

          {/* disabled these button according to page */}
          {type === "dashboard" && (
            <>
              {/* <Dropdown.Item as={Link} to="">
                Change Password
              </Dropdown.Item> */}
              <Dropdown.Item onClick={() => setLogout(true)}>
                Logout
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>

      {/* logout modal */}
      {/* <CommonModal
				show={logout}
				handleClose={() => setLogout(false)}
				title={"Logout"}
				subTitle={"Are you sure, you want to logout ?"}
				customizeFooter={
					<>
						<button
							className="dashboard_button"
							onClick={() => setLogout(false)}
						>
							Close
						</button>
						<button
							className="dashboard_button green"
							onClick={logoutHandler}
						>
							Logout
						</button>
					</>
				}
			/> */}
      <CommonModal show={logout} handleClose={() => setLogout(false)} size="md">
        <div className="logout">
          <span className="logout_icon">
            <FaExclamationCircle />
          </span>
          <h3>Logout</h3>
          <p>Are you sure want to logout?</p>

          <div className="button_wrap center">
            <button
              className="dashboard_button"
              onClick={() => setLogout(false)}
            >
              No
            </button>
            <button
              className="dashboard_button green"
              onClick={logoutHandler}
              ref={buttonRef}
            >
              Yes, log me out
            </button>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default Avatar;
