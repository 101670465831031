import { decryptData, encryptData } from "config/config";
import { instance as axios, setMultiPartHeader } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";

// get user profile
export const userProfile = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/user-profile?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

//update user profile
export const updateUserProfile = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/update-user-profile`, {
      reqData: encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      }),
    })
  );
};

// get user courses
export const userCourses = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/my-courses-for-live-website?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// get user courses detail by id
export const userCoursesDetail = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/my-course-detail?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// get user lectures overview
export const userLectureOverview = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/lecture-overview?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// get user lectures subject view
export const userLectureSubjectView = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/lecture-by-subject?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// get master subjects list
export const subjectListForHelp = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/help/subject?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// send request to help
export const sendHelp = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/help/submit`, {
      reqData: encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      }),
    })
  );
};

// get user's group meeting
export const userGroupMeetings = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/group/meeting/list?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// create group meeting log
export const createGroupMeetingLog = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/group/meeting/create/log?reqData=${encryptData({
        ...data,
        platform: "Web",
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};
// get user's purchase billing courses
export const userPurchaseBillingCourses = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/course/purchase/list?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// download purchase invoice api
export const getUserPurchaseInvoice = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/course/purchase/invoice?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// get user's lecture watching data
export const getUserWatchingData = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/dashboard/continue/watching?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

export const getUserProgressData = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/dashboard/your/progress?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

export const getOnDemandProgress = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/ondemand-lecture-report?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// get notification list
export const notificationList = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/notification/list?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// delete Notification
export const deleteNotifications = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/notification/delete?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// get re-upload answer sheet list
export const getReUploadAnswerSheet = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/test/reupload/request?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

// attempt reupload mains test
export const reuploadMainsTest = async (data = {}, options) => {
  return decryptData(await axios.post(`/test/reupload/pdf`, data, options));
};

// get test list
export const getTestByCourse = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/test/coursewise/list?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

export const getTestGraph = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/test/graph?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};

export const updatekycflagbyStudent = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.get(
      `/update-kyc-flag-by-student?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      })}`
    )
  );
};
