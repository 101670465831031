import React, { useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { AddStudentMentorShipForm } from "services/mentor/mentor";
import toast from "react-hot-toast";
import { Input, Select, TextArea } from "components/Form";





const StudentMentorshipForm = () => {
    const [formData, setFormData] = useState({
        education: '',
        studyfield: '',
        graduationyear: '',
        coachingdetail: '',
        upsctotalattempt: '',
        upscrecentyear: '',
        GSrecentmarks: '',
        CSArecentmarks: '',
        prelimsinstitutename: '',
        prelimsnumberoftest: '',
        mainsinstitutename: '',
        mainsnumberoftest: '',
        otherinformation: ''
    });

    const [errors, setErrors] = useState({
        education: '',
        studyfield: '',
        graduationyear: '',
        upsctotalattempt: '',
        GSrecentmarks: '',
        CSArecentmarks: '',
        prelimsinstitutename: '',
        prelimsnumberoftest: '',
        mainsinstitutename: '',
        mainsnumberoftest: '',

    });

    const handleValidation = () => {
        let errorField = {};
        let formIsValid = true;

        // if (!contactData.centerId) {
        //   errorField["centerId"] = "Please Select Center";
        //   formIsValid = false;
        // }
        if (!formData.education) {
            errorField["education"] = "Please select your education level";
            formIsValid = false;
        }

        if (!formData.studyfield) {
            errorField["studyfield"] = "Please enter your field of study";
            formIsValid = false;
        }

        if (!formData.graduationyear) {
            errorField["graduationyear"] = "Please enter your graduation year";
            formIsValid = false;
        }

        if (!formData.upsctotalattempt) {
            errorField["upsctotalattempt"] = "Please enter the total number of UPSC attempts";
            formIsValid = false;
        }
        if (!formData.GSrecentmarks) {
            errorField["GSrecentmarks"] = "Please enter the total number of General Studies recent marks";
            formIsValid = false;
        }
        
        if (!formData.CSArecentmarks) {
            errorField["CSArecentmarks"] = "Please enter the total number of Civil Services Aptitude Test recent marks";
            formIsValid = false;
        }
        
        if (!formData.prelimsinstitutename) {
            errorField["prelimsinstitutename"] = "Please enter the name of the institution where you took the Prelims";
            formIsValid = false;
        }
        
        if (!formData.prelimsnumberoftest) {
            errorField["upsctotalattempt"] = "Please enter the total number of UPSC Prelims tests you have attempted";
            formIsValid = false;
        }
        
        if (!formData.mainsinstitutename) {
            errorField["mainsinstitutename"] = "Please enter the name of the institution where you took the Mains";
            formIsValid = false;
        }
        
        if (!formData.mainsnumberoftest) {
            errorField["mainsnumberoftest"] = "Please enter the total number of UPSC Mains tests you have attempted";
            formIsValid = false;
        }
        


        setErrors(errorField);
        return formIsValid;
    };
    const navigate = useNavigate();


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Submitting Form Data: ", formData);
        // if (handleValidation()) {
        try {
            const res = await AddStudentMentorShipForm(formData);

            if (res.code === 200) {
                toast.success(res.message);
                navigate('/dashboard/book-mentor-appointment');
            } else {
                toast.error(res.message || 'Something went wrong!');
            }
        } catch (err) {
            toast.error('An error occurred while submitting the form!');
            console.error(err);
        }
    // } else {
    //     toast.error("Please fill all fields");
    //   }
    };

    return (
        <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto", background: "#f9f9f9", borderRadius: "10px" }}>
            <h2 style={{ textAlign: "center", marginBottom: "20px", fontSize: "25px" }}>
                Manthan Mentorship Programme<br />Student Information Form
            </h2>
            

                <div style={{
                    maxWidth: "800px",
                    width: "100%",
                    margin: "20px auto",
                    padding: "20px",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    textAlign: "left",
                }}>
                    <h6 style={{ fontWeight: "bold", color: "#181822", marginBottom: "15px" }}>
                        Educational Background
                    </h6>

                    <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                        {/* Column 1 */}
                        <div style={{ flex: "1" }}>
                            <label style={{ fontWeight: "500", display: "block", marginBottom: "5px" }}>
                                Highest Education Qualification:
                            </label>
                            <Input
                                type="text"
                                name="education"
                                value={formData.education}
                                onChange={handleInputChange}
                                required
                                //error={errors.education}
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    marginBottom: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />

                            <label style={{ fontWeight: "500", display: "block", marginBottom: "5px" }}>
                                Graduation Year:
                            </label>
                            <select
                                name="graduationyear"
                                value={formData.graduationyear}
                                onChange={handleInputChange}
                                required
                                //error={errors.graduationyear}
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    marginBottom: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            >
                                <option value="" disabled>Select</option> {/* Default option */}
                                {/* Generate years from 2010 to 2024 */}
                                {Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - i).map(year => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>



                        </div>

                        {/* Column 2 */}
                        <div style={{ flex: "1" }}>
                            <label style={{ fontWeight: "500", display: "block", marginBottom: "5px" }}>
                                Field of Study:
                            </label>
                            <Input
                                type="text"
                                name="studyfield"
                                value={formData.studyfield}
                                onChange={handleInputChange}
                                required
                                //error={errors.studyfield}
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    marginBottom: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />
                        </div>
                    </div>





                </div>

                <div style={{
                    maxWidth: "800px",
                    width: "100%",
                    margin: "20px auto",
                    padding: "20px",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    textAlign: "left",
                }}>
                    <h6 style={{ fontWeight: "bold", color: "#181822", marginBottom: "15px" }}>
                        Coaching Details:
                    </h6>
                    <Input
                        type="text"
                        name="coachingdetail"
                        value={formData.coachingdetail}
                        onChange={handleInputChange}
                        required
                        style={{
                            width: "100%",
                            padding: "8px",
                            marginBottom: "15px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                        }}
                    />
                </div>

                <div style={{
                    maxWidth: "800px",
                    width: "100%",
                    margin: "20px auto",
                    padding: "20px",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    textAlign: "left",
                }}>
                    <h6 style={{ fontWeight: "bold", color: "#181822", marginBottom: "15px" }}>
                        UPSC Attempts
                    </h6>

                    <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                        {/* Column 1 */}
                        <div style={{ flex: "1" }}>
                            <label style={{ fontWeight: "500", display: "block", marginBottom: "5px" }}>
                                Total Attempts:
                            </label>
                            <Input
                                type="text"
                                name="upsctotalattempt"
                                value={formData.upsctotalattempt}
                                // onChange={handleInputChange}
                                onChange={(e)=>{
                                    const {name,value} = e.target;
                                    setFormData({...formData,[name]:value.replace(/\D/g, "") })
                                }}
                                required
                                //error={errors.upsctotalattempt}
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    marginBottom: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />
                        </div>
                        <div style={{ flex: "1" }}>
                            <label style={{ fontWeight: "500", display: "block", marginBottom: "5px" }}>
                                Most Recent Attempt (Year):
                            </label>
                            <Input
                                type="text"
                                name="upscrecentyear"
                                value={formData.upscrecentyear}
                                // onChange={handleInputChange}
                                onChange={(e)=>{
                                    const {name,value} = e.target;
                                    setFormData({...formData,[name]:value.replace(/\D/g, "") })
                                }}
                                required
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    marginBottom: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />

                        </div>
                    </div>
                </div>

                <div style={{
                    maxWidth: "800px",
                    width: "100%",
                    margin: "20px auto",
                    padding: "20px",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    textAlign: "left",
                }}>
                    <h6 style={{ fontWeight: "bold", color: "#181822", marginBottom: "15px" }}>
                        Marks in Most Recent Attempt
                    </h6>

                    <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                        {/* Column 1 */}
                        <div style={{ flex: "1" }}>
                            <label style={{ fontWeight: "500", display: "block", marginBottom: "5px" }}>
                                GS Marks:
                            </label>
                            <Input
                                type="text"
                                name="GSrecentmarks"
                                value={formData.GSrecentmarks}
                                // onChange={handleInputChange}
                                onChange={(e)=>{
                                    const {name,value} = e.target;
                                    setFormData({...formData,[name]:value.replace(/\D/g, "") })
                                }}
                                required
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    marginBottom: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />
                        </div>
                        <div style={{ flex: "1" }}>
                            <label style={{ fontWeight: "500", display: "block", marginBottom: "5px" }}>
                                CSAT Marks:
                            </label>
                            <Input
                                type="text"
                                name="CSArecentmarks"
                                value={formData.CSArecentmarks}
                                // onChange={handleInputChange}
                                onChange={(e)=>{
                                    const {name,value} = e.target;
                                    setFormData({...formData,[name]:value.replace(/\D/g, "") })
                                }}
                                required
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    marginBottom: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />

                        </div>
                    </div>
                </div>

                <div style={{
                    maxWidth: "800px",
                    width: "100%",
                    margin: "20px auto",
                    padding: "20px",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    textAlign: "left",
                }}>
                    <h6 style={{ fontWeight: "bold", color: "#181822", marginBottom: "15px" }}>
                        Test Series Enrollment
                        <br/>
                        <span style={{fontSize:"14px", fontWeight:"400"}}>(If enrolled in different institute
                        )</span>
                    </h6>

                    <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                        {/* Column 1 */}
                        <h6 style={{ fontWeight: "500", color: "#181822", marginBottom: "15px" }}>
                            Prelims:
                        </h6>

                        <div style={{ flex: "1" }}>
                            <label style={{ fontWeight: "500", display: "block", marginBottom: "5px" }}>
                                Institute Name:
                            </label>
                            <Input
                                type="text"
                                name="prelimsinstitutename"
                                value={formData.prelimsinstitutename}
                                onChange={handleInputChange}
                                required
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    marginBottom: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />
                        </div>
                        <div style={{ flex: "1" }}>
                            <label style={{ fontWeight: "500", display: "block", marginBottom: "5px" }}>
                                Number of Tests:
                            </label>
                            <Input
                                type="text"
                                name="prelimsnumberoftest"
                                value={formData.prelimsnumberoftest}
                                // onChange={handleInputChange}
                                onChange={(e)=>{
                                    const {name,value} = e.target;
                                    setFormData({...formData,[name]:value.replace(/\D/g, "") })
                                }}
                                required
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    marginBottom: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />

                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "32px" }}>
                        {/* Column 1 */}
                        <h6 style={{ fontWeight: "500", color: "#181822", marginBottom: "15px" }}>
                            Mains:
                        </h6>
                        <div style={{ flex: "1" }}>
                            <label style={{ fontWeight: "500", display: "block", marginBottom: "5px" }}>
                                Institute Name:
                            </label>
                            <Input
                                type="text"
                                name="mainsinstitutename"
                                value={formData.mainsinstitutename}
                                onChange={handleInputChange}
                                required
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    marginBottom: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />
                        </div>
                        <div style={{ flex: "1" }}>
                            <label style={{ fontWeight: "500", display: "block", marginBottom: "5px" }}>
                                Number of Tests:
                            </label>
                            <Input
                                type="text"
                                name="mainsnumberoftest"
                                value={formData.mainsnumberoftest}
                                // onChange={handleInputChange}
                                onChange={(e)=>{
                                    const {name,value} = e.target;
                                    setFormData({...formData,[name]:value.replace(/\D/g, "") })
                                }}
                                required
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    marginBottom: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />

                        </div>
                    </div>

                    
                </div>
                <div style={{
                    maxWidth: "800px",
                    width: "100%",
                    margin: "20px auto",
                    padding: "20px",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    textAlign: "left",
                }}>
                    <h6 style={{ fontWeight: "bold", color: "#181822", marginBottom: "15px" }}>
                        Other Relevant Information
                        <span style={{fontSize:"14px", fontWeight:"400"}}>(Any additional details you want to share, such as employment status, specific challenges faced, etc.)</span>
                    </h6>
                    <TextArea
                        type="text"
                        name="otherinformation"
                        value={formData.otherinformation}
                        onChange={handleInputChange}
                        required
                        style={{
                            width: "100%",
                            padding: "8px",
                            marginBottom: "15px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                        }}
                    />
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        style={{
                            backgroundColor: "#3A69B0",
                            color: "#fff",
                            padding: "10px 20px",
                            border: "none",
                            borderRadius: "5px",
                        }}
                    >
                        Submit
                    </button>
                </div>


            
        </div>
    );
};

export default StudentMentorshipForm;
