import React from "react";
import { FaTimesCircle } from "react-icons/fa";

const NoSlotsMessage = ({ message , submessage}) => {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "30px",
        backgroundColor: "#fef4f4",
        borderRadius: "10px",
        border: "1px solid #ff6f61",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        maxWidth: "fit-content",
        margin: "20px auto",
      }}
    >
      <i
        className="fas fa-calendar-times"
        style={{
          fontSize: "60px",
          color: "#ff6f61",
          marginBottom: "15px",
        }}
      ></i>
      <h2
        style={{
          fontSize: "18px",
          color: "red",
          fontWeight: "600",
          marginBottom: "5px",
        }}
      >
        <FaTimesCircle/> &nbsp;&nbsp;{message || "No Slots Available"}
      </h2>
      <p style={{ fontSize: "14px", color: "#666" }}>
        {submessage}
      </p>
    </div>
  );
};

export default NoSlotsMessage;
