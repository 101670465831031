import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GetMyAppointmentStudentDetails } from "services/mentor/mentor";
import "./ViewStudentDetails.scss"

export default function ViewStudentDetails() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    FirstName: "",
    LastName: "",
    EmailID: "",
    Mobile: "",
    education: "",
    studyfield: "",
    graduationyear: "",
    coachingdetail: "",
    upsctotalattempt: "",
    upscrecentyear: "",
    GSrecentmarks: "",
    CSArecentmarks: "",
    prelimsinstitutename: "",
    prelimsnumberoftest: "",
    mainsinstitutename: "",
    mainsnumberoftest: "",
    otherinformation: "",
  });

  //   ******************************** Fetch Student Details **********************************************

  const getStudentDetails = async () => {
    try {
      setLoading(true); // Add the selected date to API params
      const res = await GetMyAppointmentStudentDetails(); // Pass page and selected date to the API
      if (res.code === 200) {
        setData((prevDetails) => ({
          ...prevDetails,
          FirstName: res.data.studentDetails.FirstName || "",
          LastName: res.data.studentDetails.LastName || "",
          EmailID: res.data.studentDetails.EmailID || "",
          Mobile: res.data.studentDetails.Mobile || "",
        }));

        if (res.data.studentDetails.StudentMentorshipInfo) {
          setData((prevDetails) => ({
            ...prevDetails,
            education:
              res.data.studentDetails.StudentMentorshipInfo.education || "",
            studyfield:
              res.data.studentDetails.StudentMentorshipInfo.studyfield || "",
            graduationyear:
              res.data.studentDetails.StudentMentorshipInfo.graduationyear ||
              "",
            coachingdetail:
              res.data.studentDetails.StudentMentorshipInfo.coachingdetail ||
              "",
            upsctotalattempt:
              res.data.studentDetails.StudentMentorshipInfo.upsctotalattempt ||
              "",
            upscrecentyear:
              res.data.studentDetails.StudentMentorshipInfo.upscrecentyear ||
              "",
            GSrecentmarks:
              res.data.studentDetails.StudentMentorshipInfo.GSrecentmarks || "",
            CSArecentmarks:
              res.data.studentDetails.StudentMentorshipInfo.CSArecentmarks ||
              "",
            prelimsinstitutename:
              res.data.studentDetails.StudentMentorshipInfo
                .prelimsinstitutename || "",
            prelimsnumberoftest:
              res.data.studentDetails.StudentMentorshipInfo
                .prelimsnumberoftest || "",
            mainsinstitutename:
              res.data.studentDetails.StudentMentorshipInfo
                .mainsinstitutename || "",
            mainsnumberoftest:
              res.data.studentDetails.StudentMentorshipInfo.mainsnumberoftest ||
              "",
            otherinformation:
              res.data.studentDetails.StudentMentorshipInfo.otherinformation ||
              "",
          }));
        }
      } else {
        console.error("Error fetching appointments:", res.message);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getStudentDetails(); // Fetch data when the page or selected date changes
  }, []);

  return (
    <>
      <section className="courser_report spacing upload_answer_sheet">
        <div className="container">
          <div className="_box">
            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <h4>View Student Details</h4>

              <button className="student-details-btn h-50">
                <Link to={-1}>Back</Link>
              </button>
            </div>

            <>
              <div className="form-group row mt-1">
                <div className="col-sm-6 col-form-label order-1 d-flex flex-column justify-content-end">
                  <div className="row">
                    <div>
                      <Table
                        bordered
                        className="table"
                        style={{ border: "1px solid #4971B2" }}
                      >
                        <thead>
                          <tr>
                            <td colSpan={3}>
                              <h6
                                className="text-danger"
                                style={{ textAlign: "left" }}
                              >
                                Educational Background
                              </h6>
                            </td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                padding: 5,
                                textAlign: "left",
                                color: "#7986CB",
                              }}
                            >
                              Highest Education Qualification
                            </th>
                            <td style={{ textAlign: "left" }}>
                              {data.education || " "}
                            </td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                padding: 5,
                                textAlign: "left",
                                color: "#7986CB",
                              }}
                            >
                              Field Of Study
                            </th>
                            <td style={{ textAlign: "left" }}>
                              {data.studyfield || " "}
                            </td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                padding: 5,
                                textAlign: "left",
                                color: "#7986CB",
                              }}
                            >
                              Graduation Year
                            </th>
                            <td style={{ textAlign: "left" }}>
                              {data.graduationyear || " "}
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-form-label order-1 d-flex flex-column justify-content-start">
                  <div className="row">
                    <div>
                      <Table
                        bordered
                        className="table"
                        style={{ border: "1px solid #4971B2" }}
                      >
                        <tbody>
                          <tr>
                            <td colSpan={2}>
                              <h6
                                className="text-danger"
                                style={{ textAlign: "left" }}
                              >
                                Coaching Details
                              </h6>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>
                              {data.coachingdetail || " "}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-form-label order-1 d-flex flex-column justify-content-end">
                  <div className="row">
                    <div>
                      <Table
                        bordered
                        className="table"
                        style={{ border: "1px solid #4971B2" }}
                      >
                        <thead>
                          <tr>
                            <td colSpan={2}>
                              <h6
                                className="text-danger"
                                style={{ textAlign: "left" }}
                              >
                                UPSC Attempts
                              </h6>
                            </td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                padding: 5,
                                textAlign: "left",
                                color: "#7986CB",
                              }}
                            >
                              Total Attempts
                            </th>
                            <td style={{ textAlign: "left" }}>
                              {data.upsctotalattempt || " "}
                            </td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                padding: 5,
                                textAlign: "left",
                                color: "#7986CB",
                              }}
                            >
                              Most Recent Attempt (Year)
                            </th>
                            <td style={{ textAlign: "left" }}>
                              {data.upscrecentyear || " "}
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-form-label order-1 d-flex flex-column justify-content-end">
                  <div className="row">
                    <div>
                      <Table
                        bordered
                        className="table"
                        style={{ border: "1px solid #4971B2" }}
                      >
                        <thead>
                          <tr>
                            <td colSpan={2}>
                              <h6
                                className="text-danger"
                                style={{ textAlign: "left" }}
                              >
                                Marks in Most Recent Attempt
                              </h6>
                            </td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                padding: 5,
                                textAlign: "left",
                                color: "#7986CB",
                              }}
                            >
                              GS Marks
                            </th>
                            <td style={{ textAlign: "left" }}>
                              {data.GSrecentmarks || " "}
                            </td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                padding: 5,
                                textAlign: "left",
                                color: "#7986CB",
                              }}
                            >
                              CSAT Marks
                            </th>
                            <td style={{ textAlign: "left" }}>
                              {data.CSArecentmarks || " "}
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-form-label order-1 d-flex flex-column justify-content-end">
                  <div className="row">
                    <div>
                      <Table
                        bordered
                        className="table"
                        style={{ border: "1px solid #4971B2" }}
                      >
                        <thead>
                          <tr>
                            <td colSpan={4}>
                              <h6
                                className="text-danger"
                                style={{ textAlign: "left" }}
                              >
                                Test Series Enrollment
                              </h6>
                            </td>
                          </tr>
                          <tr>
                            <th className="text-center text-danger" colSpan={2}>
                              Prelims
                            </th>
                            <th className="text-center text-danger" colSpan={2}>
                              Mains
                            </th>
                          </tr>
                          <tr>
                            <th
                              style={{
                                padding: 5,
                                textAlign: "left",
                                color: "#7986CB",
                              }}
                            >
                              Institute Name
                            </th>
                            <td style={{ textAlign: "left" }}>{data.prelimsinstitutename || " "}</td>
                            <th
                              style={{
                                padding: 5,
                                textAlign: "left",
                                color: "#7986CB",
                              }}
                            >
                              Institute Name
                            </th>
                            <td style={{ textAlign: "left" }}>
                              {data.mainsinstitutename || " "}
                            </td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                padding: 5,
                                textAlign: "left",
                                color: "#7986CB",
                              }}
                            >
                              Number Of Tests
                            </th>
                            <td style={{ textAlign: "left" }}>
                              {data.prelimsnumberoftest || " "}
                            </td>
                            <th
                              style={{
                                padding: 5,
                                textAlign: "left",
                                color: "#7986CB",
                              }}
                            >
                              Number Of Tests
                            </th>
                            <td style={{ textAlign: "left" }}>
                              {data.mainsnumberoftest || " "}
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-form-label order-1 d-flex flex-column justify-content-end">
                  <div className="row">
                    <div>
                      <Table
                        bordered
                        className="table"
                        style={{ border: "1px solid #4971B2" }}
                      >
                        <thead>
                          <tr>
                            <td>
                              <h6
                                className="text-danger"
                                style={{ textAlign: "left" }}
                              >
                                Other Relevant Information
                              </h6>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ textAlign: "left" }}>
                              {data.otherinformation || " "}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </section>
    </>
  );
}
