import React, { useEffect, useState } from "react";
import "./Syllabus.scss";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { errorResponse, failResponse } from "config/config";

const GS3 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial mobile check
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setContent(`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        .Eligibilityh3 {
            font-size: 18px;
            margin-bottom: 10px;
            text-align: center;
        }

        .Eligibilityp {
            margin-bottom: 8px;
            font-size: 15px;
            font-weight: 500;
            margin-top: 10px;
        }
		body{
		line-height:35px;
		}
		.title{
		color:#00239c;
		}
		.title1{
		color:#D2042D;
		}
		.pagetitle{
		color:#00239c;
		text-align: center;font-size: 26px;
		}
    </style>
</head>
<body>
    <h3 class="pagetitle">UPSC Mains General Studies Paper 3 Overview</h3><br/>
    <p class="Eligibilityp"><strong>General Studies Paper 3</strong> in the UPSC Mains exam is one of the core components shaping the preparation of UPSC aspirants. This paper covers a broad spectrum of topics segmented into distinct subjects like the Economy, Agriculture, Environment, Disaster management, Science and Technology and Internal Security. Each subject requires a unique approach and diverse source materials for effective preparation.</p>
    
    <strong class="title1">Question Format</strong>
    <ul style="list-style-type: disc; margin-left: 20px;padding-left:20px;">
        <li><strong>Number of Questions:</strong> 20 compulsory questions</li>
        <li><strong>Languages:</strong> Questions are presented in both Hindi and English. Answers must be written in the language specified during the application process; other languages will not be evaluated.</li>
        <li><strong>Total Marks:</strong> 250 marks</li>
    </ul>
    <strong class="title1">Word Limits</strong>
    <ul style="list-style-type: disc; margin-left: 20px;padding-left:20px;">
         <li><strong>10-mark Questions:</strong> Maximum of 150 words</li>
        <li><strong>15-mark Questions:</strong> Maximum of 250 words</li>
    </ul>
    <strong class="title1">Key Subject Areas</strong>
        <br/>
          <strong class="title" style="padding-left:20px">1.	ECONOMY</strong><br/>
          <strong class="title" style="padding-left:32px">Major Topics:</strong>
          <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
                <li>Growth and Resource mobilization: Issues relating to Planning, Mobilization of Resources, Growth, Development and Employment.</li>
                <li>Inclusive growth and issues therein</li>
                <li>Government Budgeting.</li>
                <li>Infrastructure (energy, ports, roads, airports, railways) and Investment models</li>
                <li>Effects of Liberalisation on the economy. </li>
                <li>Changes in Industrial policy & their effects on industrial growth);</li>
           </ul><br/>
          
           <strong class="title" style="padding-left:32px">Sources and Approach:</strong>
          <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
                <li><em>NCERT Textbooks:</em> Understanding Economic Development for Class X, Indian Economic Development for Class XI and Macroeconomics for Class XII are fundamental for building a strong base for Economy.</li>
                <li>Economic Survey and Economic Budget</li>
                <li>NITI Aayog Reports and India Yearbook</li>
                <li>Regular newspapers and government websites: <em>The Indian Express or The Hindu</em></li>
                <li>oIndian Economy for Civil Services Examinations by Ramesh Singh</li>
           </ul><br/>
          
           <strong class="title" style="padding-left:32px">Exam Analysis:</strong>
          <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
               <li><strong>Growth and resource mobilization: </strong>Always in consistent, this topic has been in focus every year. As growth is the driver for government of India’s vision for 2047 to transform India into a developed nation, the weightage of this section has therefore risen especially in recent times like 2021 (40 marks), 2023 (40 marks) and 2024 (35 marks). </li>
                <li><strong>Infrastructure and Investment: </strong>There have been questions from this section almost every year, reflecting a dynamic trend in India’s economic policy especially in 2024 (25 marks) after 2021 (15 marks) and 2022 (10 marks). </li>
                <li><strong>Budget and Liberalisation: </strong>There less focus on theses section in recent times.</li>
          </ul><br/>
          
          <strong class="title" style="padding-left:20px">2.	AGRICULTURE</strong>
          <br/>
          <strong class="title" style="padding-left:35px">Major Topics:</strong>
          <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
                <li>Major Crops - Cropping Patterns in various parts of the country, - Different Types of Irrigation and Irrigation Systems; Storage, Transport and Marketing of Agricultural Produce and Issues and Related Constraints. </li>
                <li>Issues related to Direct and Indirect Farm Subsidies and Minimum Support Prices; Public Distribution System - Objectives, Functioning, Limitations, Revamping; Issues of Buffer Stocks and Food Security; Technology Missions; Economics of Animal-Rearing</li>
                <li>Land Reforms in India</li>
                <li>E-technology in the aid of farmers.</li>
                <li>Food Processing and Related Industries in India- Scope’ and Significance, Location, Upstream and Downstream Requirements, Supply Chain Management.</li>
           </ul><br/>
          
           <strong class="title" style="padding-left:35px">Sources and Approach:</strong>
          <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
                <li>Daily newspaper <em>(The Hindu and Indian Express)</em> and Magazines <em>(Yojana and Kurukshetra)</em></li>
                <li>State of Agriculture Report by the Ministry of Agriculture</li>
                <li>Class XII NCERT: Human Geography</li>
                <li><em>Websites:</em>  ICAR and APEDA</li>
           </ul><br/>
          
           <strong class="title" style="padding-left:35px">Exam Analysis:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li><strong>Cropping and irrigation: </strong> Agriculture is the core of India’s economy with cropping and irrigation being its backbone esp. post the Green Revolution. Therefore, this topic has always been consistent with major focus every year. </li>
           <li><strong>MSP and PDS: </strong>The trend in this section has been dynamic. Questions are asked for 2-3 years in consistent and again the process repeats after a year or two.</li>
           <li><strong>Food Processing:  </strong>Although very important section of Indian Economic development and government focus in this section, there has been a change of pattern as seen in recent times like 2020 (20 marks) and 2022 (25 marks) but no question since then.</li>
           </ul><br/>
           
           <strong class="title" style="padding-left:20px">3.	SCIENCE AND TECHNOLOGY</strong><br>
           <strong class="title" style="padding-left:35px">Major Topics:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li>Science and Technology- Developments and their Applications and Effects in Everyday Life.</li>
           <li>Achievements of Indians in Science & Technology; Indigenization of Technology and Developing New Technology.</li>
           <li>Awareness in the fields of IT, Space, Computers, Robotics,<br/> Nanotechnology, Biotechnology and issues relating to Intellectual Property Rights.</li>
           </ul><br/>

           <strong class="title"style="padding-left:35px">Sources and Approach:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li>Regular reading of <em>Newspapers and Magazines</em> like Science Reporter can provide contemporary insights and data.</li>  
           <li>PIB and websites of ISRO</li>  
         </ul><br/>
      
           <strong class="title" style="padding-left:35px">Exam Analysis:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li><strong>Transparency, Accountability and e-Governnce: </strong>These sections are an important and core areas of focus of the government as well as the UPSC, thereby being asked almost every year.</li>
           <li><strong>NGOs, Pressure Groups and Civil Services: </strong>These topics are relevant every day for the good governance of the country and therefore dynamic in nature with questions being asked every year.</li>
           </ul><br/>

           <strong class="title" style="padding-left:20px">4.	ENIRONMENT AND DISASTER MANAGEMENT</strong><br>
           <strong class="title" style="padding-left:35px">Major Topics:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li>Conservation, Environmental Pollution and Degradation, Environmental Impact Assessment.</li>
           <li>Disaster and Disaster Management.</li>
           </ul><br/>

           <strong class="title"style="padding-left:35px">Sources and Approach:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li><em>Website</em> (ndma.gov.in) and Disaster Management notes of IGNOU</li>  
           <li>NCERT Class XI -Natural Hazards & Disaster Management</li>  
           <li>Regular Newspaper: <em>The Hindu or Indian Express </em>and pib</li>  
           <li>Book: Environmental Studies by Rajagopalan</li>  
         </ul><br/>
      
           <strong class="title" style="padding-left:35px">Exam Analysis:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li><strong>Environmental degradation and Conservation: </strong>The most important and pressing topic to 21<sup>st</sup> century to keep our planet safe by ensuring climate mitigation and adaptation, this section is relevant and consistent with at least 3 questions every year. The weightage in recent times is at least 30 marks like 2024 (35 marks), 2023 (65 marks) and 2022 (40 marks).</li>
           <li><strong>Disaster Management: </strong>There has been consistency in this section with at least one question every year like 2024 (30 marks), 2022 (25 marks), etc. This can be correlated with the rise in climate change related disasters and focus on disaster resilience.</li>
           </ul><br/>

           <strong class="title" style="padding-left:20px">5.	INTERNAL SECURITY </strong><br>
           <strong class="title" style="padding-left:35px">Major Topics:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li>Linkages between Development and Spread of Extremism.</li>
           <li>Linkages of Organized Crime with Terrorism.</li>
           <li>Border Areas (security challenges and management thereof); Security forces and agencies and their mandate;</li>
           <li>Role of External State & Non-State actors in creating internal security challenges</li>
           <li>Money laundering & Prevention.</li>
           <li>Basics of Cyber Security; Role of media and social-networking sites in internal security challenges; Internal security challenges through communication networks</li>
           </ul><br/>

           <strong class="title"style="padding-left:35px">Sources and Approach:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li>Daily newspaper <em>(The Hindu and Indian Express)</em> and Magazines <em>(Yojana and Kurukshetra)</em></li>  
           <li>IDSA (Institute for Defence Studies and Analyses) website and MHA reports</li>  
           <li>Book: Internal Security of India and Disaster Management by Syed Waquar Raza, IPS.</li>  
         </ul><br/>
      
           <strong class="title" style="padding-left:35px">Exam Analysis:</strong>
           <ul style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li><strong>Border Management:</strong>With hostile neighbours and instability in few, government is focusing on border management with resources mobilisation and other initiatives. Therefore, this section hold significance with questions every year like 2024 (30 marks), 2022 (25 marks) and so on.</li>
           <li><strong>Cyber Security: </strong>The trend in this section has consistent except 2023. Otherwise, every year questions are asked like 2024 (25 marks). The section holds relevance in the backdrop of Industrial Revolution 4.0 and rising security threats like data security.</li>
           <li><strong>Money Laundering and Organized Crime:  </strong>There has been questions from section since 2021 every year. This is because of the security threat posed by the geographical location of India being close to Golden Crescent and Golden Triangle, further exacerbated by the instability in these regions in recent times.</li>
           </ul><br/>


    </body>
</html>
`);
  });

  useEffect(() => {
    // Function to fetch the content
    const fetchUPSCcontent = async () => {
      try {
        // const res = await GetUPSCcontent();
        // if (res.code === 200) {
        //   const { htmlcontent } = res.data.content[3];
        //   setContent(htmlcontent);
        // } else {
        //   failResponse(res);
        // }
      } catch (err) {
        errorResponse(err);
      }
    };

    // Function to update isMobile state on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    fetchUPSCcontent(); // Fetch content on component mount

    window.addEventListener("resize", handleResize); // Listen for window resize
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up listener on unmount
    };
  }, []); // Empty dependency array ensures this runs only once (on mount)

  // Handle back button functionality
  const handleBack = () => {
    navigate("/freeresources/FRMains"); // Use navigate to go back to the specified route
  };

  return (
    <div className="syllabus-container">
      {isMobile && (
        <div className="mobile-back-arrow" onClick={handleBack}>
          <FaChevronLeft style={{ color: "#181822", fontSize: "24px" }} />{" "}
          {/* Font Awesome Arrow Icon */}
        </div>
      )}
      <div className="subsection-prelims-syllabus-container">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default GS3;
