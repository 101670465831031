import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  getMentorsList,
  getSlotList,
  getAvailableSlots,
  BookMyAppointment,
  getAppointmentDetailsBySlot,
  getMentorsSlots,
  getSelectedMentorAvailableSlots,
  CancelMyAppointment,
  GetUpcomingAppointmentList,
  GetUpcomingDates,
} from "services/mentor/mentor";
import { errorResponse, failResponse } from "config/config";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import NoSlotsMessage from "./NoSlotsMessage";

const UpcomingAppointments = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState(null);
  const [selectedMentorType, setselectedMentorType] = useState(null);
  const [selectedSlotOption, setSelectedSlotOption] = useState(null);
  const [mentorlength, setMentorlength] = useState(0);
  const [mentors, setMentors] = useState([]);
  const [slots, setSlots] = useState([]);
  const [AvailableSlots, setAvailableSlots] = useState([]);
  const [AvailableMentors, setAvailableMentors] = useState([]);
  const [SelectedAvailableSlots, setSelectedAvailableSlots] = useState([]);
  const [BookingDetails, setBookingDetails] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMentorAvailableDate, setSelectedMentorAvailableDate] =
    useState(null);
  const [selectedAvailableMentor, setSelectedAvailableMentor] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [dates, setDates] = useState([]);
  const [mentordates, setMentorDates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAnotherModalOpen, setIsAnotherModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();

  const getupcomingdates = async () => {
    try {
      const res = await GetUpcomingDates({});
      if (res.code === 200) {
        setMentorlength(res.data.upcomingdates.length);
        if (res.data.upcomingdates.length === 0) {
          setMentorlength(res.data.upcomingdates.length);
          return null;
        } else {
          setMentorlength(res.data.upcomingdates.length);

          // Use a Set to keep track of unique dates
          const uniqueDates = new Set();
          const mentordates = res.data.upcomingdates
            .filter((slot) => {
              const date = slot.date;
              if (uniqueDates.has(date)) {
                return false; // Skip duplicate dates
              }
              uniqueDates.add(date);
              return true;
            })
            .map((slot) => {
              const originalDate = new Date(slot.date);

              // Get the day name and date components
              const options = {
                weekday: "long",
                day: "2-digit",
                month: "short",
              };
              const formattedDate = originalDate.toLocaleDateString(
                "en-GB",
                options
              );

              // Split the formatted date into dayName, day, and month
              const [dayName, day, month] = formattedDate.split(" ");

              return {
                date: originalDate.toISOString().split("T")[0], // Store the date in 'YYYY-MM-DD' format
                dayName,
                day,
                month,
              };
            })
            .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort the dates in ascending order

          setMentorDates(mentordates);

          console.log("Unique Dates:", mentordates);
        }
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };

  useEffect(() => {
    if (mentordates.length > 0) {
      const formatteddate = new Date(mentordates[0].date);
      setSelectedDate(formatteddate);
      getupcomingappointmentlist(formatteddate);
    }
  }, [mentordates]);

  useEffect(() => {
    // Fetch the list of mentors
    const getmentorslist = async () => {
      try {
        const res = await getMentorsList();
        if (res.code === 200) {
          setMentors(res.data.mentorslist || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };
    getmentorslist();
  }, []);

  useEffect(() => {
    // Fetch the list of mentors
    const getslotlist = async () => {
      try {
        const res = await getSlotList();
        if (res.code === 200) {
          setSlots(res.data.slots || []);
          getupcomingdates();
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };
    getslotlist();
  }, []);

  const getupcomingappointmentlist = async (date, slotid) => {
    try {
      const res = await GetUpcomingAppointmentList({
        date: date,
      });
      if (res.code === 200) {
        setAvailableSlots(res.data.upcomingappointments || []);
        const mentorDetails = res.data.upcomingappointments.map((slot) => ({
          mentorid: slot.mentorid,
          mentorname: slot.mentorname,
          showmentor: slot.showmentor,
        }));

        const uniqueMentors = Array.from(
          new Map(
            res.data.upcomingappointments.map((slot) => [slot.mentorid, slot])
          ).values()
        );
        setAvailableMentors(uniqueMentors);
        console.log("AvailableMentors", mentorDetails);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };
  const availableSlotIds = new Set(AvailableSlots.map((slot) => slot.slotid));

  const cancelappointment = async (
    e,
    mentorId,
    mentorName,
    slotId,
    slotName,
    date
  ) => {
    e.preventDefault();
    try {
      const res = await CancelMyAppointment({
        mentorid: mentorId,
        mentorname: mentorName,
        slotid: slotId,
        slotname: slotName,
        date: date,
      });

      if (res.code === 200) {
        toast.success(res.message);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    } finally {
      // Clear selected values and refresh available slots immediately
      setSelectedAvailableMentor(null);
      setSelectedTimeSlot(null);
      setIsAnotherModalOpen(false);
      getupcomingdates();
      getupcomingappointmentlist(date);
    }
  };

  const getappointmentdetailsbyslot = async (date, slotid) => {
    try {
      const res = await getAppointmentDetailsBySlot({
        date: date,
        slotid: slotid || "",
      });
      if (res.code === 200) {
        const bookingdetails = res.data.appointmentdetails.map((slot) => ({
          mentorid: slot.mentorid,
          mentorname: slot.mentorname,
          slotname: slot.slotname,
          slotid: slot.slotid,
          date: slot.date,
          type: slot.type,
          meetinglink: slot.meetinglink,
          cancelmeeting: slot.cancelmeeting,
        }));

        setBookingDetails(bookingdetails);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };

  useEffect(() => {
    const generateDates = () => {
      const tempDates = [];
      const today = new Date();
      for (let i = 0; i < 7; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        const dayName = date.toLocaleDateString("en-US", { weekday: "short" });
        const day = date.getDate();
        const month = date.toLocaleDateString("en-US", { month: "short" });
        tempDates.push({ dayName, day, month, date });
      }
      setDates(tempDates);
    };
    generateDates();
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Detect mobile view (width <= 600px)
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it initially to set the correct state

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maincontainerstyle = {
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f7f7f9",
    minHeight: "90vh",
  };

  const outerContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f7f7f9",
    minHeight: "40vh",
  };

  const innerContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "880px",
  };

  const cardStyle1 = {
    width: "100%",
    padding: "20px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    textAlign: "center",
    marginTop: "20px",
    fontWeight: "500",
    display: "flex",
    gap: "100px",
    justifyContent: "center",
  };

  const cardStyle = {
    width: "101%",
    padding: "20px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    textAlign: "center",
    marginTop: "20px",
    fontWeight: "500",
    maxHeight: "470px",
    overflowY: "auto",
  };

  const optionRowStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "10px",
    cursor: "pointer",
  };

  const checkboxSlotStyle = (isSelected) => ({
    width: "18px",
    height: isMobile ? "15px" : "18px",
    borderRadius: "50%",
    marginRight: "10px",
    border: isSelected
      ? "2px solid transparent"
      : "2px solid rgb(204, 204, 204)",
    backgroundColor: isSelected ? "#3A69B0" : "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff",
    fontSize: "12px",
    fontWeight: "bold",
  });

  const buttonContainerStyle = {
    display: "grid",
    gridTemplateColumns: isMobile ? "1fr" : "repeat(3, 1fr)",
    gap: "8px", // Adds space between items
  };

  const buttonContainerStyle1 = {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)", // Ensures 3 items per row
    gap: "8px", // Adds space between items
  };

  const optionStyle = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    margin: "10px",
    cursor: "pointer",
    backgroundColor: "#ffffff",
    transition: "background-color 0.3s",
    height: "75px",
    flex: "1 1 calc(33% - 20px)",
    boxSizing: "border-box",
  };

  const optionStyle1 = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "30px",
    margin: "10px",
    cursor: "pointer",
    backgroundColor: "#ffffff",
    transition: "background-color 0.3s",
    height: "fit-content",
    flex: "1 1 calc(33% - 20px)",
    boxSizing: "border-box",
    justifyContent: "center",
    fontWeight: "500",
    fontSize: "13px",
  };

  const selectedOptionStyle = {
    ...optionStyle,
    border: "1px solid #3A69B0",
    backgroundColor: "#e6f0ff",
  };

  const selectedOptionStyle1 = {
    ...optionStyle1,
    border: "1px solid #3A69B0",
    backgroundColor: "#e6f0ff",
    color: "#3A69B0",
    fontWeight: "500",
    fontSize: "13px",
  };

  const checkboxStyle = (isSelected) => ({
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: isSelected
      ? "2px solid transparent"
      : "2px solid rgb(204, 204, 204)",
    backgroundColor: isSelected ? "#3A69B0" : "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: "bold",
  });

  const buttonStyle = {
    padding: "8px 16px",
    borderRadius: "5px",
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
    margin: "0 5px",
    transition: "background-color 0.3s",
  };

  const continueButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#3A69B0",
    color: "#ffffff",
    border: "none",
  };

  const modalcontinueButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#DD5D59",
    color: "#ffffff",
    border: "none",
  };

  const backButtonStyle = {
    ...buttonStyle,
    backgroundColor: "transparent",
    color: "#3A69B0",
    border: "1px solid #3A69B0",
    width: "88px",
  };

  const dateSelectorContainer = {
    display: "flex",
    gap: "20px",
    overflowX: "auto",
    padding: "10px",
  };

  const dateStyle = (isSelected) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "12px 16px", // Increased padding for larger width
    minWidth: "100px", // Ensures a wider button
    cursor: "pointer",
    border: isSelected ? "1px solid #3A69B0" : "1px solid #ccc",
    borderRadius: "8px",
    backgroundColor: isSelected ? "#e6f0ff" : "#ffffff",
  });
  const grayOutStyle = {
    opacity: 0.5,
    backgroundColor: "rgb(208 208 208 / 76%)", // Change the background to light gray or any other color
    cursor: "not-allowed", // Make the cursor a "not-allowed" sign to indicate that the slot is not available
  };

  const handleDateSelect = (date) => {
    const selectedDateObj = new Date(date); // Store the Date object
    setSelectedDate(selectedDateObj); // Set the selected date as a Date object
    getupcomingappointmentlist(selectedDateObj); // Pass the Date object if needed
    setSelectedTimeSlot(null);
  };

  const handleTimeSlotSelection = (slot) => {
    setSelectedTimeSlot(slot);
    setIsModalOpen(true);
    setSelectedDate(selectedDate);
    getupcomingappointmentlist(selectedDate, slot.id);
  };

  const handleTimeSlotSelection2 = (slot) => {
    setSelectedTimeSlot(slot);
    setIsAnotherModalOpen(true);
    setSelectedDate(selectedDate || selectedMentorAvailableDate);
    getappointmentdetailsbyslot(
      selectedDate || selectedMentorAvailableDate,
      slot.id
    );
  };

  const handleCloseModal2 = () => {
    setIsAnotherModalOpen(false);
    setSelectedTimeSlot(null);
    if (selectedSlotOption === "mentorselection") {
      setSelectedDate(null);
    } else {
      setSelectedDate(selectedDate);
    }
    setSelectedAvailableMentor(null);
    getupcomingappointmentlist(selectedDate);
    setErrorMessage("");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTimeSlot(null);
    setSelectedDate(selectedDate);
    setSelectedAvailableMentor(null);
    getupcomingappointmentlist(selectedDate);
    setErrorMessage("");
  };

  return (
    <div style={maincontainerstyle}>
      <div style={outerContainerStyle}>
        <div style={innerContainerStyle}>
          <h4 style={{ textAlign: "center" }}>Upcoming Appointments</h4>

          <div style={cardStyle}>
            {mentorlength === 0 ? (
              <NoSlotsMessage
                message={`No Upcoming Appointments Available !`}
              />
            ) : (
              <div>
                <h4
                  style={{
                    fontWeight: "bold",
                    fontSize: "17px",
                    textAlign: "left",
                    marginLeft: "12px",
                  }}
                >
                  Choose your date
                </h4>
                {/* Date Selection */}
                <div style={dateSelectorContainer}>
                  {mentordates.map((dateObj, index) => (
                    <div
                      key={index}
                      style={dateStyle(
                        selectedDate &&
                          selectedDate.toLocaleDateString() ===
                            new Date(dateObj.date).toLocaleDateString()
                      )}
                      onClick={() => handleDateSelect(dateObj.date)}
                    >
                      <span style={{ fontSize: "12px" }}>
                        {dateObj.dayName}
                      </span>
                      <strong>
                        <span
                          style={{ fontSize: "14px" }}
                        >{`${dateObj.day} ${dateObj.month}`}</span>
                      </strong>
                    </div>
                  ))}
                </div>

                {/* Time Slot Selection */}
                {selectedDate && (
                  <div style={{ marginTop: "15px" }}>
                    <h4
                      style={{
                        fontWeight: "bold",
                        fontSize: "17px",
                        textAlign: "left",
                        marginLeft: "12px",
                      }}
                    >
                      Upcoming appointments
                    </h4>
                    <div style={buttonContainerStyle1}>
                      {slots.map((slot) => {
                        // Check if the current slot is missing (not in availableSlotIds)
                        const isMissing = !availableSlotIds.has(slot.id);
                        const isShowMentor = AvailableSlots.some(
                          (availableSlot) =>
                            availableSlot.slotid === slot.id &&
                            availableSlot.showmentor === "your slot"
                        );

                        return (
                          <div
                            key={slot.id}
                            style={
                              isMissing
                                ? {
                                    ...(selectedTimeSlot === slot
                                      ? selectedOptionStyle1
                                      : optionStyle1),
                                    ...grayOutStyle,
                                  }
                                : selectedTimeSlot === slot
                                ? selectedOptionStyle1
                                : isShowMentor
                                ? {
                                    ...optionStyle1,
                                    backgroundColor: "#ffc0cbb0",
                                    position: "relative", // Ensures tick is positioned at the right corner
                                  }
                                : optionStyle1
                            }
                            onClick={() => {
                              if (!isMissing && !isShowMentor) {
                                // Regular slot click
                                handleTimeSlotSelection(slot);
                                setIsModalOpen(true); // Open regular modal
                              } else if (isShowMentor) {
                                // "Your slot" click
                                handleTimeSlotSelection2(slot);
                                setIsAnotherModalOpen(true); // Open different modal for "your slot"
                              }
                            }}
                          >
                            <span>{slot.slottime}</span>
                            {isShowMentor && (
                              <span
                                style={{
                                  position: "absolute",
                                  right: "0px",
                                  top: "5px",
                                  transform: "translateY(-50%)",
                                  fontSize: "12px",
                                  color: "darkgreen",
                                  border: "1px solid darkgreen",
                                  borderRadius: "15px",
                                  width: "20px",
                                  backgroundColor: "whitesmoke",
                                  height: "20px",
                                }}
                              >
                                ✔
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <Modal
                  show={isModalOpen}
                  onRequestClose={handleCloseModal}
                  style={{
                    content: {
                      width: "400px",
                      margin: "auto",
                      padding: "20px",
                      borderRadius: "10px",
                      textAlign: "center",
                      position: "relative",
                      zIndex: 1000,
                    },
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 999,
                    },
                  }}
                >
                  {/* Date and Time Display with Card Styling */}
                  <div
                    style={{
                      padding: "15px 20px",
                      borderRadius: "8px",
                      backgroundColor: "#3A69B0",
                      border: "1px solid #ddd",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      marginBottom: "20px",
                      textAlign: "center",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "20px",
                        fontWeight: "500",
                        color: "#fff",
                        margin: 0,
                      }}
                    >
                      {selectedDate
                        ? selectedDate.toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          })
                        : "No Date Selected"}
                    </h4>
                    <h4
                      style={{
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "#fff",
                        margin: "-7px 0 0 0",
                      }}
                    >
                      {selectedTimeSlot ? selectedTimeSlot.slottime : "No Slot"}
                    </h4>
                  </div>

                  {/* Available Mentors Section */}
                  <h4
                    style={{
                      fontSize: "17px",
                      textAlign: "center",
                      marginLeft: "0px",
                      fontWeight: "500",
                    }}
                  >
                    Available Mentors
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      gap: "10px",
                      fontSize: "14px",
                      textAlign: "left",
                      marginLeft: "0px",
                    }}
                  >
                    {AvailableMentors.map((mentor) => (
                      <div
                        key={mentor.mentorid}
                        onClick={() => {
                          if (mentor.showmentor !== false) {
                            setSelectedAvailableMentor(mentor);
                            setErrorMessage(""); // Clear error message
                          }
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "45%",
                          padding: "10px",
                          border:
                            selectedAvailableMentor?.mentorid ===
                            mentor.mentorid
                              ? "1px solid #4CAF50"
                              : "1px solid #ccc",
                          borderRadius: "8px",
                          cursor:
                            mentor.showmentor === false
                              ? "not-allowed"
                              : "pointer", // Disable cursor if mentor is grayed out
                          backgroundColor:
                            mentor.showmentor === false
                              ? "#D3D3D3"
                              : selectedAvailableMentor?.mentorid ===
                                mentor.mentorid
                              ? "#E8F5E9"
                              : "#fff",
                          opacity: mentor.showmentor === false ? 0.5 : 1, // Reduce opacity for grayed out mentor
                          boxShadow:
                            mentor.showmentor === false
                              ? "none"
                              : "0px 4px 6px rgba(0, 0, 0, 0.1)", // Remove shadow for grayed out
                          transition:
                            "background-color 0.3s, border 0.3s, opacity 0.3s",
                          marginBottom: "10px",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "500" }}>
                          {mentor.mentorname}
                        </span>
                      </div>
                    ))}
                  </div>
                  {errorMessage && (
                    <p
                      style={{
                        color: "red",
                        fontWeight: "400",
                        marginBottom: "0px",
                        marginTop: "10px", // Add spacing below the error message
                        textAlign: "center", // Center-align the text
                      }}
                    >
                      {errorMessage}
                    </p>
                  )}

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ display: "flex", gap: "5px" }}>
                      <button
                        style={{
                          ...modalcontinueButtonStyle,
                          cursor: AvailableMentors.every(
                            (mentor) => mentor.showmentor === false
                          )
                            ? "not-allowed"
                            : "pointer",
                          color: "#fff",
                        }}
                        disabled={AvailableMentors.every(
                          (mentor) => mentor.showmentor === false
                        )} // Disable button if all mentors are grayed out
                        onClick={(e) => {
                          if (!selectedAvailableMentor) {
                            setErrorMessage(
                              "Please select a mentor before booking an appointment."
                            );
                            return; // Prevent booking if no mentor is selected
                          }
                          setIsModalOpen(false);
                        }}
                      >
                        Book My Appointment
                      </button>
                    </div>
                  </div>

                  <button
                    onClick={handleCloseModal}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      background: "transparent",
                      border: "none",
                      fontSize: "40px",
                      color: "#000",
                      cursor: "pointer",
                    }}
                  >
                    &times;
                  </button>
                  <br />
                </Modal>

                <Modal
                  show={isAnotherModalOpen}
                  onRequestClose={handleCloseModal2}
                  contentLabel="Appointment Details"
                >
                  <div
                    style={{
                      padding: "15px 20px",
                      borderRadius: "5px",
                      backgroundColor: "#3A69B0",
                      border: "1px solid #ddd",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      marginBottom: "20px",
                      textAlign: "center",
                      width: "100%",
                      maxWidth: "1000px",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "20px",
                        fontWeight: "500",
                        color: "#fff",
                        margin: 0,
                      }}
                    >
                      Appointment Details
                    </h4>
                  </div>

                  {/* Booking Details in Label-Value Pair Table Format */}
                  {BookingDetails.length > 0 ? (
                    <div style={{ padding: "20px" }}>
                      <table
                        style={{
                          width: "100%",
                          borderCollapse: "collapse",
                          marginTop: "-17px",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                          backgroundColor: "#fff",
                        }}
                      >
                        <tbody>
                          {BookingDetails.map((detail, index) => (
                            <React.Fragment key={index}>
                              <tr style={{ borderBottom: "1px solid #ddd" }}>
                                <td
                                  style={{
                                    padding: "12px",
                                    fontSize: "17px",
                                    fontWeight: "bold",
                                    color: "#555",
                                    textAlign: "right",
                                    width: "30%",
                                    border: "1px solid black",
                                  }}
                                >
                                  Date&nbsp;
                                </td>
                                <td
                                  style={{
                                    padding: "12px",
                                    fontSize: "17px",
                                    color: "#333",
                                    textAlign: "left",
                                    border: "1px solid black",
                                  }}
                                >
                                  {new Date(detail.date).toLocaleDateString(
                                    "en-GB",
                                    {
                                      day: "2-digit",
                                      month: "long",
                                      year: "numeric",
                                    }
                                  )}
                                </td>
                              </tr>
                              <tr style={{ borderBottom: "1px solid #ddd" }}>
                                <td
                                  style={{
                                    padding: "12px",
                                    fontSize: "17px",
                                    fontWeight: "bold",
                                    color: "#555",
                                    textAlign: "right",
                                    border: "1px solid black",
                                  }}
                                >
                                  Time&nbsp;
                                </td>
                                <td
                                  style={{
                                    padding: "12px",
                                    fontSize: "17px",
                                    color: "#333",
                                    textAlign: "left",
                                    border: "1px solid black",
                                  }}
                                >
                                  {detail.slotname}
                                </td>
                              </tr>
                              <tr style={{ borderBottom: "1px solid #ddd" }}>
                                <td
                                  style={{
                                    padding: "12px",
                                    fontSize: "17px",
                                    fontWeight: "bold",
                                    color: "#555",
                                    textAlign: "right",
                                    border: "1px solid black",
                                  }}
                                >
                                  Mentor&nbsp;
                                </td>
                                <td
                                  style={{
                                    padding: "12px",
                                    fontSize: "16px",
                                    color: "#333",
                                    textAlign: "left",
                                    border: "1px solid black",
                                  }}
                                >
                                  {detail.mentorname}
                                </td>
                              </tr>
                              <tr style={{ borderBottom: "1px solid #ddd" }}>
                                <td
                                  style={{
                                    padding: "12px",
                                    fontSize: "17px",
                                    fontWeight: "bold",
                                    color: "#555",
                                    textAlign: "right",
                                    border: "1px solid black",
                                  }}
                                >
                                  Type&nbsp;
                                </td>
                                <td
                                  style={{
                                    padding: "12px",
                                    fontSize: "16px",
                                    color: "#333",
                                    textAlign: "left",
                                    border: "1px solid black",
                                  }}
                                >
                                  {detail.type}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                      {BookingDetails.some(
                        (detail) => detail.cancelmeeting === "Yes"
                      ) && (
                        <>
                          <div
                            style={{ textAlign: "center", marginTop: "20px" }}
                          >
                            {BookingDetails.filter(
                              (detail) => detail.cancelmeeting === "Yes"
                            ).map((detail, index) => (
                              <>
                                <button
                                  key={index}
                                  style={{
                                    padding: "4px 20px",
                                    fontSize: "16px",
                                    color: "#fff",
                                    backgroundColor: "#DD5D59", // red background
                                    border: "2px solid #DD5D59", // red border
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    margin: "5px", // Optional: to add space between multiple buttons
                                  }}
                                  onClick={(e) =>
                                    cancelappointment(
                                      e,
                                      detail.mentorid,
                                      detail.mentorname,
                                      detail.slotid,
                                      detail.slotname,
                                      detail.date
                                    )
                                  }
                                >
                                  Cancel Appointment
                                </button>
                                {detail.meetinglink && (
                                  <Link
                                    key={index}
                                    style={{
                                      padding: "4px 20px",
                                      fontSize: "16px",
                                      color: "#fff",
                                      backgroundColor: "#4971B2", // red background
                                      border: "2px solid #4971B2", // red border
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                      margin: "5px", // Optional: to add space between multiple buttons
                                    }}
                                    to={detail.meetinglink}
                                    target="_blank"
                                  >
                                    Join Meeting
                                  </Link>
                                )}
                                <div
                                  style={{
                                    padding: "5px 25px",
                                    // border: "1px solid #DD5D59",
                                  }}
                                >
                                  <label
                                    className="text-danger"
                                    style={{ textAlign: "center" }}
                                  >
                                    &#40; Notes : &nbsp;
                                    <span
                                      style={{
                                        textAlign: "left",
                                        color: "#555",
                                        fontWeight: "lighter",
                                      }}
                                    >
                                      Same day Appointment can not be cancelled
                                    </span>
                                    &nbsp;&#41;
                                  </label>
                                </div>
                              </>
                            ))}
                          </div>
                        </>
                      )}
                      {/* Conditionally render the Cancel Meeting button outside the table */}
                    </div>
                  ) : (
                    <p style={{ fontSize: "14px", color: "#555" }}>
                      No booking details available
                    </p>
                  )}

                  {/* Close Button */}
                  <button
                    onClick={handleCloseModal2}
                    style={{
                      position: "absolute",
                      top: "-7px",
                      right: "10px",
                      background: "transparent",
                      border: "none",
                      fontSize: "40px",
                      color: "#000",
                      cursor: "pointer",
                    }}
                  >
                    &times;
                  </button>
                </Modal>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingAppointments;
