import React, { useState } from "react";
import Drawer from "dashboard/components/drawer/Drawer";
import { BiHome, BiPhoneCall } from "react-icons/bi";
import { Select, TextArea } from "components/Form";
import { toast } from "react-hot-toast";
import useButtonLoader from "hooks/useButtonLoader";
import { sendHelp, subjectListForHelp, userCourses } from "services/dashboard";
import { errorResponse, failResponse } from "config/config";

const HelpDrawer = ({ show, handleClose }) => {
  const [allSubjects, setAllSubjects] = useState([]);
  const [allCourses, setAllCourses] = useState([]);

  const [helpData, setHelpData] = React.useState({
    subject: "",
    course: "",
    message: "",
  });

  const [errors, setErrors] = React.useState({
    subject: "",
    course: "",
    message: "",
  });

  const [buttonRef, setButtonLoading] = useButtonLoader("Submit");

  //   onchange handler
  const onChangeHandler = ({ target }) => {
    const { name, value } = target;
    setHelpData({ ...helpData, [name]: value });
  };

  //   validation function
  const handleValidation = () => {
    let errorField = {};
    let formIsValid = true;

    if (!helpData.subject) {
      errorField["subject"] = "Please Select Subject";
      formIsValid = false;
    }
    if (!helpData.course) {
      errorField["course"] = "Please Select Course";
      formIsValid = false;
    }

    if (!helpData.message) {
      errorField["message"] = "Please enter some message";
      formIsValid = false;
    }

    setErrors(errorField);
    return formIsValid;
  };

  //   help submit handler
  const helpSubmitHandler = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      try {
        setButtonLoading(true);
        const { subject, course, message } = helpData;
        const res = await sendHelp({
          subjectId: subject,
          courseId: course,
          message: message,
        });
        if (res.code === 200) {
          toast.success("Message Send Successfully");
          setButtonLoading(false);
          setHelpData({ subject: "", course: "", message: "" });
          handleClose();
        } else {
          failResponse(res);
          setButtonLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setButtonLoading(false);
      }
    } else {
      toast.error("Please fill all fields");
    }
  };

  // ----------------------- getting course detail with addons data -------------------------//
  React.useLayoutEffect(() => {
    // get all subjects
    const getAllSubjects = async () => {
      try {
        const res = await subjectListForHelp();

        if (res.code === 200) {
          setAllSubjects(res.data || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    // get all courses
    const getAllCourses = async () => {
      try {
        const res = await userCourses();
        if (res.code === 200) {
          setAllCourses(res.data.myCourses.rows || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    if (show) {
      getAllSubjects();
      getAllCourses();
    }
    // eslint-disable-next-line
  }, [show]);

  return (
    <>
      <Drawer
        show={show}
        handleClose={handleClose}
        title={"Help"}
        className={"help_offcanvas"}
      >
        <form className="form">
          <div className="form_field">
            <Select
              name="subject"
              placeholder="Select Subject "
              onChange={onChangeHandler}
              value={helpData.subject}
              className={`input form-control `}
              label={"Subject Type"}
              error={errors.subject}
              required
            >
              <option value="">Select</option>
              {allSubjects &&
                allSubjects.map(({ SubjectID, Subject }) => {
                  return (
                    <option value={SubjectID} key={SubjectID}>
                      {Subject}
                    </option>
                  );
                })}
            </Select>
          </div>
          <div className="form_field">
            <Select
              name="course"
              placeholder="Select Course"
              onChange={onChangeHandler}
              value={helpData.course}
              className={`input form-control `}
              label={"Course"}
              error={errors.course}
              required
            >
              <option value="">Select</option>
              {allCourses.map(({ CourseID, DisplayName }) => {
                return (
                  <option value={CourseID} key={CourseID}>
                    {DisplayName}
                  </option>
                );
              })}
            </Select>
          </div>
          <div className="form_field">
            <TextArea
              placeholder="Write something here…"
              onChange={onChangeHandler}
              value={helpData.message}
              name="message"
              className={`input form-control `}
              label={"Message"}
              error={errors.message}
              required
            />
          </div>

          <div className="button_wrap">
            <button
              className="dashboard_button fill"
              type="button"
              onClick={helpSubmitHandler}
              ref={buttonRef}
            >
              Submit
            </button>
          </div>

          <h6>Contact us</h6>

          <ul>
            <li>
              <span>
                <BiHome />
              </span>{" "}
              <p>
                {" "}
                12B, Bada Baazar Road, Old Rajinder Nagar, New Delhi - 110060s
              </p>
            </li>
            <li>
              <a href="telto:9810212719">
                <span>
                  <BiPhoneCall />
                </span>{" "}
                9810212719
              </a>
            </li>
          </ul>
        </form>
      </Drawer>
    </>
  );
};

export default HelpDrawer;
