import React from "react";
import "./Lecture.scss";
import { useCourse } from "context/context";
import NormalLectureSection from "./on-demand/NormalLectureSection";
import OnDemandSection from "./on-demand/OnDemandSection";
import LectureNotFound from "components/Not-Found/LectureNotFound";
// import { RiBook3Line } from "react-icons/ri";
// import { SiGoogletranslate } from "react-icons/si";
function Lecture() {
  const { course } = useCourse();
  const isLoading = !course?.courseName;
  return (
    <div className="dashboard lecture_page">
      {/* <div className="dashboard_banner spacing container">
        <div className="container">
          <div className="content">
            <h3>Lectures</h3>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam.
            </p>

            <div className="button_wrap">
							<button className="button">
								<SiGoogletranslate />
								HIndi-English
							</button>
							<button className="button">
								<RiBook3Line /> 10 subjects
							</button>
						</div>
          </div>
        </div>
      </div> */}
      <div className="dashboard_banner container text_banner">
        <div className="container">
          <h3 className="lecturetext hide-on-mobile">
            {isLoading ? (
              <>
                Recorded Lectures of{" "}
                <div className="loading-dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </>
            ) : (
              `Recorded Lectures of ${course.courseName}`
            )}
          </h3>
          <h3 className="lecturetext show-on-mobile">
            Lectures
          </h3>
        </div>
      </div>

      {/* {/ if lecture is available in offering and addons /} */}
      {course.coursePlatformEnabled === true && (
        <>
          {/* Normal lectures */}
          {!course.isLectureAvailable ? (
            <LectureNotFound note="Lecture feature is not available for this course" />
          ) : (
            !course?.isOnDemand && <NormalLectureSection />
          )}

          {/* On demand lectures */}
          {course?.isOnDemand && <OnDemandSection />}
        </>
      )}

      {course.coursePlatformEnabled === false && (
        <LectureNotFound note="This course is not available on web platform" />
      )}
      {/* {/ if lecture is not available in offering and addons /} */}
      {/* {!course.isLectureAvailable && (
        <LectureNotFound note="Lecture feature is not available for this course" />
      )} */}
    </div>
  );
}

export default Lecture;
