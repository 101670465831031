import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AppointmentBookingType = () => {
  const navigate = useNavigate();
  const [selectedAppointmentType, setselectedAppointmentType] = useState(null);
  const [validationError, setValidationError] = useState(""); // State for validation error message

  const handleAppointmentType = (type) => {
    setselectedAppointmentType(type);
    setValidationError(""); // Clear validation error when a type is selected
  };

  const navigation = () => {
    if (!selectedAppointmentType) {
      setValidationError(
        "Please select an appointment type before continuing."
      );
      return;
    }
    // Proceed with navigation if an appointment type is selected
    navigate("/dashboard/mentor-selection", {
      state: { selectedAppointmentType },
    });
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    minHeight: "100vh",
    padding: "20px",
    paddingTop:"40px",
    backgroundColor: "#f7f7f9",
    textAlign: "center",
  };

  const cardStyle = {
    maxWidth: "400px",
    width: "100%",
    padding: "20px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    textAlign: "center",
    marginTop: "40px",
    transform: "translateY(-25%)", // Increase translateY to move it further up
  };

  const headerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 0",
    backgroundColor: "#f7f7f9",
  };

  const headingStyle = {
    margin: "10px 0 10px 0",
    fontSize: "24px",
    color: "#333",
    textAlign: "center",
  };

  const buttonContainerStyle = {
    display: "flex",
    gap: "10px",
    marginTop: "12px",
    justifyContent: "center",
  };

  const buttonStyle = (type) => ({
    display: "flex",
    flexDirection: "column", // Set flexDirection to column
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 20px",
    borderRadius: "12px",
    border:
      selectedAppointmentType === type ? "1px solid #3A69B0" : "1px solid #ccc",
    backgroundColor:
      selectedAppointmentType === type ? "#e6f0ff" : "transparent",
    cursor: "pointer",
    color: "#181822",
    fontWeight: "500",
    height: "100px",
    width: "150px",
    marginBottom: "8px",
    position: "relative",
  });

  const checkboxStyle = (type) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    flexDirection: "column",
    border:
      selectedAppointmentType === type
        ? "1px solid #3A69B0"
        : "2px solid rgb(204, 204, 204)",
    backgroundColor:
      selectedAppointmentType === type ? "#3A69B0" : "transparent",
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "5px", // Add margin below checkbox
    gap: "10px",
  });

  const continueButtonStyle = {
    alignItems: "center",
    justifyContent: "center",
    width: "85%",
    padding: "0",
    borderRadius: "5px",
    backgroundColor: "#3A69B0",
    color: "#ffffff",
    fontWeight: "500",
    border: "none",
    cursor: "pointer",
    marginTop: "25px",
    height: "35px",
    fontSize: "14px",
  };

  return (
    <div>
      <div style={headerStyle}>
        <h4 style={headingStyle}>3 Easy steps to book your appointment</h4>
      </div>
      <div style={containerStyle}>
        <div style={cardStyle}>
          <h5
            style={{ marginTop: "15px", fontWeight: "bold", fontSize: "18px" }}
          >
            What is your appointment type
          </h5>
          <div style={buttonContainerStyle}>
            <button
              style={buttonStyle("Online")}
              onClick={() => handleAppointmentType("Online")}
              aria-label="Select Online Appointment"
            >
              <span style={checkboxStyle("Online")}>
                {selectedAppointmentType === "Online" ? "✓" : ""}
              </span>
              Online
            </button>
            <button
              style={buttonStyle("Offline")}
              onClick={() => handleAppointmentType("Offline")}
              aria-label="Select Offline Appointment"
            >
              <span style={checkboxStyle("Offline")}>
                {selectedAppointmentType === "Offline" ? "✔" : ""}
              </span>
              Offline
            </button>
          </div>
          {validationError && (
            <p style={{ color: "red", fontSize: "14px", marginTop: "10px" }}>
              {validationError}
            </p>
          )}
          <button style={continueButtonStyle} onClick={navigation}>
            Continue
          </button>
          <hr
            style={{
              border: "none",
              height: "1px",
              backgroundColor: "#ffffff",
            }}
          />
          <div style={{padding:"5px 25px",border:'1px solid #DD5D59'}}>
            <label className="text-danger" style={{textAlign:"left"}}>Notes : </label>
            <p style={{textAlign:"left"}}> 1. Same day Appointment can not be cancelled <br/>
            2. Student can book one meeting per week </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentBookingType;
