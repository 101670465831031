import React, { useState, useEffect } from "react";
import { Table, Modal, Button, Nav, Tab } from "react-bootstrap";
import { GetMyAppointmentList } from "services/mentor/mentor";
import { dateFormat } from "config/utils";
import Pagination from "react-js-pagination";
import "./MyAppointments.scss";
import ReactDatePicker from "react-datepicker";
import { FaCalendarAlt, FaCircleNotch } from "react-icons/fa";
import { FaEraser, FaUndo, FaStopCircle } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Outlet, useNavigate, useLocation } from "react-router-dom";


const MyAppointments = () => {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [selectedDate, setSelectedDate] = useState(""); // State for storing the selected date
  const [showPopup, setShowPopup] = useState(false); // State for popup visibility
  const [meetingMinutes, setMeetingMinutes] = useState(""); // State to store minutes of meeting data
  const recordsPerPage = 10; // Define the number of records per page
  const navigate = useNavigate();


  const handleShowPopup = (minutes, id) => {
    setMeetingMinutes(minutes);
    setShowPopup(true); // Show the popup
  };
  const handlenavigatetoDetails = (id, date) => {
    navigate(`/dashboard/appointment-detail-page/${id}/${date}`)
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
  };

  const handleDateClear = () => {
    setSelectedDate(null); // Clear the selected date
  };

  const CustomInput = ({ value, onClick, onClear }) => (
    <div className="input-appointment-date-picker">
      <input
        type="text"
        value={value || "Select Date"}
        onClick={onClick}
        readOnly
        className="input-with-icon"
      />
      <FaCalendarAlt className="calendar-icon" onClick={onClick} />
      <div className="clear-icon">
        {value && <FaUndo className="clear-icon" onClick={handleDateClear} />}
      </div>
    </div>
  );

  // Fetch appointments based on selected date and active page
  const getmyappointmentlist = async () => {
    try {
      setLoading(true);
      const params = { page: activePage };
      if (selectedDate) params.date = selectedDate; // Add the selected date to API params
      const res = await GetMyAppointmentList(params); // Pass page and selected date to the API
      if (res.code === 200) {
        const { rows } = res.data.appointments; // Extract appointments array
        const { count } = res.data.appointments; // Extract total count
        setRecords(rows || []); // Set records from appointments array
        setTotal(count); // Set total count
      } else {
        console.error("Error fetching appointments:", res.message);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getmyappointmentlist(); // Fetch data when the page or selected date changes
  }, [activePage, selectedDate]);

  // Handle date change
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value); // Update selected date state
  };

  return (
    <>
      <section className="courser_report spacing upload_answer_sheet">
        <div className="container">
          <div className="_box">
            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <h4>My Appointments</h4>

              {/* Date Picker */}
              <div className="d-flex">
                <div
                  className="appointment-date-picker"
                  style={{ marginRight: "10px" }}
                >
                  <ReactDatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd MMM yyyy"
                    maxDate={new Date()}
                    customInput={<CustomInput />} // Use the custom input component
                  />
                </div>
                <div>
                  <button className="student-details-btn">
                    <Link to={"student-details"}>
                      View Student Details
                    </Link>
                  </button>
                </div>
              </div>
            </div>

            <div className="table_wrap">
              <Table striped bordered hover className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Date</th>
                    <th>Slot Time</th>
                    <th>Meeting Type</th>
                    <th>Mentor Name</th>
                    <th>Minutes of Meeting</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Loading */}
                  {loading && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        Loading ...
                      </td>
                    </tr>
                  )}

                  {/* Main data row */}
                  {!loading &&
                    records?.length > 0 &&
                    records.map((data, i) => {
                      const sno = i + (activePage - 1) * recordsPerPage + 1;
                      return (
                        <tr key={i}>
                          <td>{sno}</td>
                          <td>{dateFormat(data.date)}</td>
                          <td>{data.slotname}</td>
                          <td>{data.type}</td>
                          <td>{data.mentorname}</td>
                          <td>
                            {data.MinutesCount > 0 ? (
                              <span
                                style={{ cursor: "pointer", color: "blue" }}
                                onClick={() =>
                                  handlenavigatetoDetails(data.id, data.date)
                                }
                              >
                                👁️
                              </span>
                            ) : (
                              "Not Available"
                            )}
                          </td>
                        </tr>
                      );
                    })}

                  {/* No records row */}
                  {!loading && records?.length <= 0 && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        No records available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              {/* Pagination */}
              {total > 10 && (
                <div className="pagination_wrap">
                  <Pagination
                    activePage={activePage}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    itemsCountPerPage={10}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    activeLinkClass={"active"}
                    onChange={(page) => setActivePage(page)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Popup modal */}
      <Modal show={showPopup} onHide={handleClosePopup} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Minutes of Meeting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: meetingMinutes }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyAppointments;
