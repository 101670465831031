import React, { useState } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import "../lecture/lecture-video/LectureVideo.scss";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import LiveLectureDetailSkeleton from "components/skeleton/LiveLectureDetailSkeleton";
import {
  liveLectureLinkCreation,
  momentDateFormat,
  timeFormatWith12Hour,
} from "config/utils";
import VideoCryptPlayer from "components/video-player/VideoCryptPlayer";
import { getUserIp } from "services/third-party";
import { liveLectureDetail } from "services/liveLecture";
import { errorResponse, failResponse, normalDecryptData } from "config/config";
import { useCourse } from "context/context";
import { gpsDisabled } from "assets/images";
import LectureNotFound from "components/Not-Found/LectureNotFound";

function LiveLectureVideo() {
  const [loading, setLoading] = useState(false);
  const [lectureDetail, setLectureDetail] = useState({});
  const [channelDetail, setChannelDetail] = useState({});
  const [userData, setUserData] = useState({});
  const [accessStatus, setAccessStatus] = useState(true);
  const [locationStatus, setLocationStatus] = useState(true);

  const { lectureId } = useParams();
  const mainLectureId = normalDecryptData(lectureId, "route")?.split(",");

  const { course } = useCourse();
  const location = useLocation();

  // ----------------------- getting lecture detail data -------------------------//
  React.useLayoutEffect(() => {
    // get lecture series detail
    const getLectureDetails = async (lat, long) => {
      try {
        setLoading(true);
        const { data: ip } = await getUserIp();
        const liveLectureId = mainLectureId[0];
        const res = await liveLectureDetail({
          liveLectureID: liveLectureId,
          courseId: course.courseId,
          latitude: lat,
          longitude: long,
        });

        if (res.code === 200) {
          setLectureDetail(res.data.liveDetails || {});
          if (res.data.liveDetails?.channel?.type === "AWS") {
            setChannelDetail(res.data.liveDetails?.channel || {});
          }
          if (res.data.liveDetails?.channel?.type === "VDO") {
            setChannelDetail(res.data.liveDetails?.channel || {});
          }
          if (res.data.liveDetails?.channel?.type === "APEX") {
            setChannelDetail(
              {
                ...res.data.liveDetails?.channel,
                link: res.data.liveDetails?.apexVideoDetail?.hls_url,
              } || {}
            );
          }
          setUserData({ ...userData, ip: ip.ip, ...res.data.user });
          setLoading(false);
        } else if (res.code === 101) {
          setAccessStatus(false);
          failResponse(res);
        } else {
          failResponse(res);
          setLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setLoading(false);
      }
    };

    if (course.courseId) {
      // checks gps is enabled
      if (mainLectureId[1] === "true") {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              getLectureDetails(latitude, longitude);
            },
            function (error) {
              if (error.code === error.PERMISSION_DENIED) {
                toast.error("Location access was denied by the user.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else if (error.code === error.POSITION_UNAVAILABLE) {
                toast.error("Location information is unavailable.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else if (error.code === error.TIMEOUT) {
                toast.error("The request to get user location timed out.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else {
                toast.error("An unknown error occurred.");
                setLocationStatus(false);
                setAccessStatus(false);
              }
            }
          );
        } else {
          toast.error("Geolocation is not supported by this browser.");
        }
      }

      // if gps enable is false
      if (mainLectureId[1] === "false") {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              getLectureDetails(latitude, longitude);
            },
            function (error) {
              if (error.code === error.PERMISSION_DENIED) {
                toast.error("Location access was denied by the user.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else if (error.code === error.POSITION_UNAVAILABLE) {
                toast.error("Location information is unavailable.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else if (error.code === error.TIMEOUT) {
                toast.error("The request to get user location timed out.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else {
                toast.error("An unknown error occurred.");
                setLocationStatus(false);
                setAccessStatus(false);
              }
            }
          );
        } else {
          toast.error("Geolocation is not supported by this browser.");
        }
        //getLectureDetails();
      }
    }

    // eslint-disable-next-line
  }, [course]);

  return (
    <>
      {accessStatus ? (
        <>
          {!location?.state?.auth ? (
            <Navigate to="/dashboard/home" />
          ) : (
            <section className="LectureVideo liveLecture_video spacing">
              <div className="container">
                {/* loading component */}
                {loading && <LiveLectureDetailSkeleton />}

                {/* main component */}
                {!loading && (
                  <>
                    <div className="course_video">
                      <div className="video_wrap">
                        {/* video crypt video player */}
                        {channelDetail?.link && (
                          <VideoCryptPlayer
                            src={channelDetail?.link}
                            userData={userData}
                          />
                        )}

                        <div className="about_video">
                          <div className="content">
                            <h5>{lectureDetail.LectureTitle}</h5>
                            <ul>
                              <li>
                                <FaRegCalendarAlt />
                                {`${momentDateFormat(
                                  lectureDetail.ScheduleDate
                                )} ${timeFormatWith12Hour(
                                  lectureDetail.StartTime
                                )}`}
                              </li>
                              <li className="red">
                                <FaRegCalendarAlt />
                                {`${momentDateFormat(
                                  lectureDetail.endDateTime
                                )} ${timeFormatWith12Hour(
                                  lectureDetail.EndTime
                                )}`}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </section>
          )}
        </>
      ) : (
        <section className="LectureVideo liveLecture_video spacing">
          <div className="container">
            <div className="course_video">
              <div className="video_wrap">
                <LectureNotFound
                  note={
                    locationStatus
                      ? `This live lecture is not allowed to play at this location`
                      : `location is required, please allow location`
                  }
                  image={gpsDisabled}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default LiveLectureVideo;
