import {
    decryptData,
    encryptData,
    errorResponse,
    failResponse,
  } from "config/config";
  import { instance as axios } from "config/axiosInstance";
  import { getBrowserName, isLoggedIn } from "config/utils";
  
  export const getMentorsList = async (data) => {
    return decryptData(
      await axios.get(
        `/get-mentor-list?reqData=${encryptData({
          ...data,
          platformType: getBrowserName(),
        })}`
      )
    );
  };
  export const getSlotList = async (data) => {
    return decryptData(
      await axios.get(
        `/get-slot-list?reqData=${encryptData({
          ...data,
          platformType: getBrowserName(),
        })}`
      )
    );
  };
  

  export const getAvailableSlots = async (data = {}) => {
    const token = isLoggedIn();
    return decryptData(
      await axios.get(
        `/get-available-slots?reqData=${encryptData({
          ...data,
          platformType: getBrowserName(),
          access_token: token,
        })}`
      )
    );
  };

  export const getSelectedMentorAvailableSlots = async (data = {}) => {
    const token = isLoggedIn();
    return decryptData(
      await axios.get(
        `/get-selected-mentor-available-slots?reqData=${encryptData({
          ...data,
          platformType: getBrowserName(),
          access_token: token,
        })}`
      )
    );
  };

  export const getMentorsSlots = async (data = {}) => {
    const token = isLoggedIn();
    return decryptData(
      await axios.get(
        `/get-selected-mentors-slots?reqData=${encryptData({
          ...data,
          platformType: getBrowserName(),
          access_token: token,
        })}`
      )
    );
  };

  export const BookMyAppointment = async (data) => {
    const token = isLoggedIn();
    return decryptData(
      await axios.post(`/book-my-appointment`, {
        reqData: encryptData({ ...data, access_token: token, platformType: getBrowserName() }),
      })
    );
  };
  
  export const CancelMyAppointment = async (data) => {
    const token = isLoggedIn();
    return decryptData(
      await axios.post(`/cancel-my-appointment`, {
        reqData: encryptData({ ...data, access_token: token, platformType: getBrowserName() }),
      })
    );
  };

  export const AddStudentMentorShipForm = async (data) => {
    const token = isLoggedIn();
    return decryptData(
      await axios.post(`/add-student-mentorship-form`, {
        reqData: encryptData({ ...data, access_token: token, platformType: getBrowserName() }),
      })
    );
  };

  export const getAppointmentDetailsBySlot = async (data = {}) => {
    const token = isLoggedIn();
    return decryptData(
      await axios.get(
        `/get-appointment-details-by-slot?reqData=${encryptData({
          ...data,
          platformType: getBrowserName(),
          access_token: token,
        })}`
      )
    );
  };
  // ***************************************** My Appointment ******************************************
  export const GetMyAppointmentStudentDetails = async (data = {}) => {
    const token = isLoggedIn();
    return decryptData(
      await axios.get(
        `/getStudentDetails?reqData=${encryptData({
          ...data,
          platformType: getBrowserName(),
          access_token: token,
        })}`
      )
    );
  };

  // **************************************** My Appointment - View Student Details ***************************************************
  export const GetMyAppointmentList = async (data = {}) => {
    const token = isLoggedIn();
    return decryptData(
      await axios.get(
        `/get-my-appointment-list?reqData=${encryptData({
          ...data,
          platformType: getBrowserName(),
          access_token: token,
        })}`
      )
    );
  };



  export const GetUpcomingAppointmentList = async (data = {}) => {
    const token = isLoggedIn();
    return decryptData(
      await axios.get(
        `/get-upcoming-appointment-list?reqData=${encryptData({
          ...data,
          platformType: getBrowserName(),
          access_token: token,
        })}`
      )
    );
  };
  export const GetUpcomingDates = async (data = {}) => {
    const token = isLoggedIn();
    return decryptData(
      await axios.get(
        `/get-upcoming-dates?reqData=${encryptData({
          ...data,
          platformType: getBrowserName(),
          access_token: token,
        })}`
      )
    );
  };
  export const GetAppointmentDetails = async (data = {}) => {
    const token = isLoggedIn();
    return decryptData(
      await axios.get(
        `/get-appointment-details?reqData=${encryptData({
          ...data,
          platformType: getBrowserName(),
          access_token: token,
        })}`
      )
    );
  };