import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import { FaBook, FaFileAlt, FaLightbulb, FaScroll, FaGlobeAmericas, FaUniversity, FaChartLine, FaBalanceScale } from "react-icons/fa"; // Import icons from react-icons
import "./Mains.scss"; // Import the CSS file for styling

const Mains = () => {
  // State to track mobile view
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // State to track grid layout based on screen size
  const [gridTemplateColumns, setGridTemplateColumns] = useState('1fr 1fr'); // Default to 2 columns for desktop

  const updateGridColumns = () => {
    const mobile = window.innerWidth <= 768;
    setIsMobile(mobile); // Check if screen is mobile size
    setGridTemplateColumns(mobile ? '1fr' : '1fr 1fr'); // Set to 1 column for mobile, 2 columns for desktop
  };

  useEffect(() => {
    updateGridColumns(); // Set initial grid columns

    // Attach event listener to handle window resize
    window.addEventListener('resize', updateGridColumns);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateGridColumns);
    };
  }, []);

  // Define the styles for the grid
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: gridTemplateColumns,
    gap: '20px', // Add spacing between cards
  };

  // Define the titles, paths, icons, and corresponding descriptions for the cards
  const cardData = [
    {
      title: "Syllabus",
      path: "/freeresources/FRMainsSyllabus",
      icon: FaBook,
      description: "Access the comprehensive syllabus to guide your Mains preparation."
    },
    // {
    //   title: "Essay",
    //   path: "/freeresources/FRMainsEssay",
    //   icon: FaFileAlt,
    //   description: "Explore essay topics and tips to enhance your writing skills for Mains."
    // },
    // {
    //   title: "PYQ Mains",
    //   path: "/freeresources/FRMainsPYQMains",
    //   icon: FaLightbulb,
    //   description: "Review previous year Mains questions to understand the exam pattern."
    // },
    // {
    //   title: "Hot Topics for Mains",
    //   path: "/freeresources/FRMainsHotTopicsforMains",
    //   icon: FaScroll,
    //   description: "Stay informed on the most important and trending topics for Mains."
    // },
    {
      title: "GS I",
      path: "/freeresources/FRMainsGS1",
      icon: FaGlobeAmericas,
      description: "Prepare for GS Paper I with resources covering History, Geography, and more."
    },
    {
      title: "GS II",
      path: "/freeresources/FRMainsGS2",
      icon: FaUniversity,
      description: "Focus on Governance, Constitution, and International Relations for GS Paper II."
    },
    {
      title: "GS III",
      path: "/freeresources/FRMainsGS3",
      icon: FaChartLine,
      description: "Get resources on Economy, Environment, and Science & Tech for GS Paper III."
    },
    {
      title: "GS IV",
      path: "/freeresources/FRMainsGS4",
      icon: FaBalanceScale,
      description: "Enhance your understanding of Ethics, Integrity, and Aptitude for GS Paper IV."
    },
  ];

  return (
    <div className="section1-container">
      <div className="card-container" style={gridStyle}>
        {cardData.map((card, index) => (
          <Link to={card.path} key={index} className="card">
            <h4 style={{ fontSize: "17px" }}>
              <card.icon style={{ marginRight: '10px', color: "#DD5D59" }} /> {card.title} {/* Use the icon component */}
            </h4>
            <h6 className="cardh6" style={{ fontWeight: "600", fontSize: "15px" }}>{card.description}</h6> {/* Display the description */}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Mains;
