import React, { useState, useEffect } from "react";
import { Table, Modal, Button, Nav, Tab } from "react-bootstrap";
import { GetMyAppointmentList, GetAppointmentDetails } from "services/mentor/mentor";
import { dateFormat } from "config/utils";
import Pagination from "react-js-pagination";
import "./AppointmentDetailPage.scss";
import ReactDatePicker from "react-datepicker";
import { FaCalendarAlt, FaCircleNotch } from "react-icons/fa";
import { FaEraser, FaUndo, FaStopCircle } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa";



const AppointmentDetailPage = () => {
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [total, setTotal] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [selectedDate, setSelectedDate] = useState(""); // State for storing the selected date
    const [showPopup, setShowPopup] = useState(false); // State for popup visibility
    const [meetingMinutes, setMeetingMinutes] = useState(""); // State to store minutes of meeting data
    const recordsPerPage = 10; // Define the number of records per page
    const { id, date } = useParams();


    const handleShowPopup = (minutes, id) => {
        setMeetingMinutes(minutes);
        setShowPopup(true); // Show the popup
    };

    const handleClosePopup = () => {
        setShowPopup(false); // Close the popup
    };

    const handleDateClear = () => {
        setSelectedDate(null); // Clear the selected date
    };

    const CustomInput = ({ value, onClick, onClear }) => (
        <div className="input-appointment-date-picker">
            <input
                type="text"
                value={value || "Select Date"}
                onClick={onClick}
                readOnly
                className="input-with-icon"
            />
            <FaCalendarAlt className="calendar-icon" onClick={onClick} />
            <div className="clear-icon">
                {value && <FaUndo className="clear-icon" onClick={handleDateClear} />}
            </div>
        </div>
    );

    // Fetch appointments based on selected date and active page
    const getappointmentdetails = async () => {
        try {
            setLoading(true);
            const res = await GetAppointmentDetails({ meetingid: id }); // Pass page and selected date to the API
            if (res.code === 200) {
                const { MinutesofMeetings } = res.data; // Extract appointments array
                setRecords(MinutesofMeetings || []); // Set records from appointments array
                console.log("This are the records", MinutesofMeetings)
            } else {
                console.error("Error fetching appointments:", res.message);
            }
        } catch (err) {
            console.error("Error:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getappointmentdetails(); // Fetch data when the page or selected date changes
    }, [activePage, id]);

    // Handle date change
    const handleDateChange = (e) => {
        setSelectedDate(e.target.value); // Update selected date state
    };

    return (
        <>
            <section className="courser_report spacing upload_answer_sheet">
                <div className="container">
                    <div className="_box">
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                                justifyContent: "space-between",
                            }}
                        >
                            <h4>Appointment Details</h4>
                            <button className="student-details-btn h-50">
                                <Link to={-1}>Back</Link>
                            </button>

                            {/* Date Picker */}

                        </div>


                        <div className="table_wrap">
                            {/* Loop through each item in MinutesofMeetings */}
                            {records?.length > 0 ? (
                                records.map((data, i) => (
                                    <div key={i} className="meeting_table">
                                        {/* Table for each record */}
                                        <Table striped bordered hover className="table">
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "left", color: "#DD5D59" }}>Minutes of Meeting ({dateFormat(date)})</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Main data row */}
                                                <tr>
                                                    <td style={{ textAlign: "left", color: "#3A69B0", fontWeight: "500" }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: data.minutesofmeeting,
                                                        }}
                                                    />
                                                </tr>
                                                {data.docfile && data.docfile !== null ? (
                                                    <tr>
                                                        <td style={{ textAlign: "left", backgroundColor: "rgb(230 227 222 / 44%)" }}>
                                                            {/* Wrap the icon in a link to open the PDF */}
                                                            <a href={data.docfile} target="_blank" rel="noopener noreferrer">
                                                                <FaFilePdf size={20} style={{ color: "red", textAlign: "left", cursor: "pointer" }} />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </tbody>

                                        </Table>
                                    </div>
                                ))
                            ) : (
                                <div>No records available</div>
                            )}

                            {/* Pagination */}
                            {total > 10 && (
                                <div className="pagination_wrap">
                                    <Pagination
                                        activePage={activePage}
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        itemsCountPerPage={10}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={5}
                                        activeLinkClass={"active"}
                                        onChange={(page) => setActivePage(page)}
                                    />
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </section>

            {/* Popup modal */}
            <Modal show={showPopup} onHide={handleClosePopup} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Minutes of Meeting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: meetingMinutes }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePopup}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AppointmentDetailPage;
