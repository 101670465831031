import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useMatch,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./Header.scss";
import Avatar from "../Normal/Avatar";
import { BsQuestionCircleFill } from "react-icons/bs";
import { FaBars, FaTimes } from "react-icons/fa";
import { Logo, HeaderLogoNew } from "assets/images";
import Drawer from "dashboard/components/drawer/Drawer";
import LectureDrawer from "dashboard/components/drawer/LectureDrawer";
import { userCourses } from "services/dashboard";
import { toast } from "react-hot-toast";
import { useCourse } from "context/context";
import HelpDrawer from "./HelpDrawer";
import { charLimit, courseAccess, momentDateFormat } from "config/utils";
import KYCPopup from "components/Popup/KYCPopup";
import { FaArrowRight } from "react-icons/fa";

import {
  decryptData,
  encryptData,
  errorResponse,
  failResponse,
} from "config/config";
import SuperTag from "components/SuperTag/SuperTag";
import CommonModal from "components/modal-box/CommonModal";
import NotificationDrawer from "./NotificationDrawer";

const Header = ({ courseDrawerShow, setCourseDrawerShow }) => {
  const location = useLocation();
  const isDashboardPage = location.pathname === "/dashboard/home";
  const isMemtorAppointmentType = location.pathname === "/dashboard/book-mentor-appointment";
  const isMemtorSelection = location.pathname === "/dashboard/mentor-selection";
  const isTopperCopiesPage = location.pathname === "/dashboard/toppers-copies";
  const testDetailMatch = useMatch("/dashboard/test-series/detail/:id");
  const testResultMatch = useMatch(
    "/dashboard/test-series/detail/:id/test-overview"
  );
  const lectureDetailMatch = useMatch("/dashboard/lecture/detail/:id");
  const liveLectureDetailMatch = useMatch("/dashboard/live-lecture/detail/:id");
  const onDemandLectureDetailMatch = useMatch(
    "/dashboard/demand-lecture/detail/:sectionId/:lectureId"
  );
  const toppersDetailMatch = useMatch("/dashboard/toppers-copies");
  const excludeDetailPage = useMatch("/course-detail/:name/:id");
  const excludeListingPage = useMatch("/course-listing");
  const checkHomePage = useMatch("/dashboard/home");

  const dashboardPageMatch = useMatch("/dashboard/home");
  const navigate = useNavigate();
  const [helpShow, setHelpShow] = useState(false);
  const [dashboardMenu, setDashboardMenu] = useState(false);
  const { setCourse, course, setNotificationCheck, setMentorfindstudentformflag, setMentorshipFlag } = useCourse();
  const lecturePageMatch = useMatch("/dashboard/lecture/:courseid");

  const [isDrawerDisabled, setIsDrawerDisabled] = useState(false);

  const [isLectureDrawerDisabled, setIsLectureDrawerDisabled] = useState(false);

  const [lectureDrawerShow, setLectureDrawerShow] = useState(false);

  const [allCourses, setAllCourses] = useState([]);
  const [allDefaultCourses, setAllDefaultCourses] = useState([]);

  const [descriptionModal, setDescriptionModal] = useState(false);


  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [selectedData, setSelectedData] = useState({
    title: "",
    description: "",
  });

  useEffect(() => {
    document.body.classList.toggle("menuOpen", dashboardMenu);
  }, [dashboardMenu]);


  // course selection handler
  const courseSelectionHandler = (selectedData, startDate) => {
    if (courseAccess(startDate)) {
      setCourse(selectedData);

      // set the course into local
      sessionStorage.setItem("course", encryptData(selectedData));

      setCourseDrawerShow(false);
      setLectureDrawerShow(false);
    } else {
      toast.error("this is an upcoming course");
    }
  };

  const navigationforbookappointment = () => {
    navigate('/dashboard/book-mentor-appointment'); // Use navigate to go back to the specified route
  };


  // open course drawer
  // const openCourseDrawer = () => {
  //   if (
  //     !testDetailMatch ||
  //     !testResultMatch ||
  //     !lectureDetailMatch ||
  //     !toppersDetailMatch ||
  //     !onDemandLectureDetailMatch ||
  //     !liveLectureDetailMatch
  //   ) {
  //     setCourseDrawerShow(true);

  //   }
  // };
  const openCourseDrawer = () => {
    if (
      !testDetailMatch ||
      !testResultMatch ||
      !lectureDetailMatch ||
      !toppersDetailMatch ||
      !onDemandLectureDetailMatch ||
      !liveLectureDetailMatch
    ) {
      if (lecturePageMatch) {
        setLectureDrawerShow(true);
      } else if (dashboardPageMatch) {
        setLectureDrawerShow(true);
      } else {
        setCourseDrawerShow(true);
      }
    }
  };

  const responsiveStyle = {
    display: "none", // Default to hide
    "@media(min-width: 768px)": {
      // Show on screens wider than 768px
      display: "inline",
    },
  };

  // ----------------------- getting all courses data -------------------------//
  React.useLayoutEffect(() => {
    // get all users courses
    const getAllCourses = async () => {
      try {
        const res = await userCourses();
        if (res.code === 200) {
          setAllCourses(res.data.myCourses.rows || []);
          setAllDefaultCourses(res.data.myCourses.defaultCourses || []);
          setNotificationCheck(res.data.notificationExist);
          setMentorfindstudentformflag(res.data.mentorfindstudent);
          const mentorshipFlag = res.data.myCourses.rows.some(
            (course) => course.Mentorship === "Y"
          );
          setMentorshipFlag(mentorshipFlag);
          if (res.data.kycflag === 0) {
            setShowWelcomePopup(true);
          }
          const firstCourses = res.data.myCourses.rows?.filter(
            (data) =>
              courseAccess(data.StartDate) &&
              (+data.isDefaultCourse === 0 || +data.isDefaultCourse === 2)
          );

          const defaultCourses = res.data.myCourses.defaultCourses?.filter(
            (data) =>
              courseAccess(data.StartDate) && +data.isDefaultCourse === 1
          );

          // demo courses management
          if (defaultCourses?.length > 0) {
            let selectedCourseData = {};
            if (sessionStorage.getItem("course")) {
              selectedCourseData = decryptData({
                data: sessionStorage.getItem("course"),
              });
            }

            // checks data is available in local or not
            if (selectedCourseData?.courseId) {
              const localCourseData = defaultCourses?.find(
                (data) => data.CourseID === selectedCourseData?.courseId
              );

              if (localCourseData?.CourseID) {
                // set data from local if available
                setCourse({
                  courseId: localCourseData?.CourseID,
                  courseName: localCourseData?.DisplayName,
                  isOnDemand: localCourseData?.OnDemand === "Y" ? true : false,
                  isGps: localCourseData?.isGps,
                  coursePlatformEnabled: localCourseData?.coursePlatformEnabled,
                  isLectureAvailable: localCourseData?.isLectureAvailable,
                  isTestAvailable: localCourseData?.isTestAvailable,
                  isPostal: localCourseData?.isPostal,
                  ExpLectures: localCourseData?.ExpLectures,
                  ismentorship: localCourseData?.Mentorship === "Y" ? true : false,
                });
                return;
              } else {
                setCourse({
                  courseId: defaultCourses[0].CourseID,
                  courseName: defaultCourses[0].DisplayName,
                  isOnDemand: defaultCourses[0].OnDemand === "Y" ? true : false,
                  isGps: defaultCourses[0]?.isGps,
                  coursePlatformEnabled:
                    defaultCourses[0]?.coursePlatformEnabled,
                  isLectureAvailable: defaultCourses[0]?.isLectureAvailable,
                  isTestAvailable: defaultCourses[0]?.isTestAvailable,
                  isPostal: defaultCourses[0]?.isPostal,
                  ExpLectures: defaultCourses[0]?.ExpLectures,
                  ismentorship: defaultCourses[0]?.Mentorship === "Y" ? true : false,
                });
              }
            } else {
              setCourse({
                courseId: defaultCourses[0].CourseID,
                courseName: defaultCourses[0].DisplayName,
                isOnDemand: defaultCourses[0].OnDemand === "Y" ? true : false,
                isGps: defaultCourses[0]?.isGps,
                coursePlatformEnabled: defaultCourses[0]?.coursePlatformEnabled,
                isLectureAvailable: defaultCourses[0]?.isLectureAvailable,
                isTestAvailable: defaultCourses[0]?.isTestAvailable,
                isPostal: defaultCourses[0]?.isPostal,
                ExpLectures: defaultCourses[0]?.ExpLectures,
                ismentorship: defaultCourses[0]?.Mentorship === "Y" ? true : false,
              });

              // set the course into local
              sessionStorage.setItem(
                "course",
                encryptData({
                  courseId: defaultCourses[0].CourseID,
                  courseName: defaultCourses[0].DisplayName,
                  isOnDemand: defaultCourses[0].OnDemand === "Y" ? true : false,
                  isGps: defaultCourses[0]?.isGps,
                  coursePlatformEnabled:
                    defaultCourses[0]?.coursePlatformEnabled,
                  isLectureAvailable: defaultCourses[0]?.isLectureAvailable,
                  isTestAvailable: defaultCourses[0]?.isTestAvailable,
                  isPostal: defaultCourses[0]?.isPostal,
                  ismentorship: defaultCourses[0]?.Mentorship === "Y" ? true : false,
                })
              );
            }
          }

          // purchased courses key management
          if (firstCourses?.length > 0) {
            let selectedCourseData = {};
            if (sessionStorage.getItem("course")) {
              selectedCourseData = decryptData({
                data: sessionStorage.getItem("course"),
              });
            }

            // checks data is available in local or not
            if (selectedCourseData?.courseId) {
              const localCourseData = firstCourses?.find(
                (data) => data.CourseID === selectedCourseData?.courseId
              );
              if (localCourseData?.CourseID) {
                // set data from local if available
                setCourse({
                  courseId: localCourseData?.CourseID,
                  courseName: localCourseData?.DisplayName,
                  isOnDemand: localCourseData?.OnDemand === "Y" ? true : false,
                  isGps: localCourseData?.isGps,
                  coursePlatformEnabled: localCourseData?.coursePlatformEnabled,
                  isLectureAvailable: localCourseData?.isLectureAvailable,
                  isTestAvailable: localCourseData?.isTestAvailable,
                  isPostal: localCourseData?.isPostal,
                  ExpLectures: localCourseData?.ExpLectures,
                  ismentorship: localCourseData?.Mentorship === "Y" ? true : false,
                });
              } else {
                setCourse({
                  courseId: firstCourses[0].CourseID,
                  courseName: firstCourses[0].DisplayName,
                  isOnDemand: firstCourses[0].OnDemand === "Y" ? true : false,
                  isGps: firstCourses[0].isGps,
                  coursePlatformEnabled: firstCourses[0]?.coursePlatformEnabled,
                  isLectureAvailable: firstCourses[0]?.isLectureAvailable,
                  isTestAvailable: firstCourses[0]?.isTestAvailable,
                  isPostal: firstCourses[0]?.isPostal,
                  ExpLectures: firstCourses[0]?.ExpLectures,
                  ismentorship: firstCourses[0]?.Mentorship === "Y" ? true : false,
                });
              }
            } else {
              setCourse({
                courseId: firstCourses[0].CourseID,
                courseName: firstCourses[0].DisplayName,
                isOnDemand: firstCourses[0].OnDemand === "Y" ? true : false,
                isGps: firstCourses[0].isGps,
                coursePlatformEnabled: firstCourses[0]?.coursePlatformEnabled,
                isLectureAvailable: firstCourses[0]?.isLectureAvailable,
                isTestAvailable: firstCourses[0]?.isTestAvailable,
                isPostal: firstCourses[0]?.isPostal,
                ExpLectures: firstCourses[0]?.ExpLectures,
                ismentorship: firstCourses[0]?.Mentorship === "Y" ? true : false,
              });

              // set the course into local
              sessionStorage.setItem(
                "course",
                encryptData({
                  courseId: firstCourses[0].CourseID,
                  courseName: firstCourses[0].DisplayName,
                  isOnDemand: firstCourses[0].OnDemand === "Y" ? true : false,
                  isGps: firstCourses[0]?.isGps,
                  coursePlatformEnabled: firstCourses[0]?.coursePlatformEnabled,
                  isLectureAvailable: firstCourses[0]?.isLectureAvailable,
                  isTestAvailable: firstCourses[0]?.isTestAvailable,
                  isPostal: firstCourses[0]?.isPostal,
                  ismentorship: firstCourses[0]?.Mentorship === "Y" ? true : false,
                })
              );
            }
          }
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    getAllCourses();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      testDetailMatch ||
      testResultMatch ||
      lectureDetailMatch ||
      toppersDetailMatch ||
      onDemandLectureDetailMatch ||
      liveLectureDetailMatch
    ) {
      setIsDrawerDisabled(true);
    } else {
      setIsDrawerDisabled(false);
    }
  }, [
    testDetailMatch,
    testResultMatch,
    lectureDetailMatch,
    toppersDetailMatch,
    liveLectureDetailMatch,
    onDemandLectureDetailMatch,
  ]);

  // remove the config from local if user change the journey
  useEffect(() => {
    if (!excludeDetailPage && !excludeListingPage) {
      sessionStorage.removeItem("config");
    }
  }, [excludeDetailPage, excludeListingPage]);

  // useEffect(() => {
  // 	if (lecturePageMatch) {
  // 		setIsLectureDrawerDisabled(true);
  // 	} else {
  // 		setIsLectureDrawerDisabled(false);
  // 	}
  // }, [lecturePageMatch]);

  // useEffect(() => {
  // 	if (!lecturePageMatch) {
  // 		sessionStorage.removeItem("config");
  // 	}
  // }, [lecturePageMatch]);

  useEffect(() => {
    if (!courseDrawerShow) {
      setDashboardMenu(false); // Close the user_nav when courseDrawerShow is false
    }
  }, [courseDrawerShow]);

  useEffect(() => {
    if (!lectureDrawerShow) {
      setDashboardMenu(false); // Close the user_nav when courseDrawerShow is false
    }
  }, [lectureDrawerShow]);

  return (
    <>
      {showWelcomePopup && <KYCPopup />}
      <div className="header_wrapper">
        <header className="userHeader">
          <div className="container">
            <div className="logo">
              <Link to="/">
                <img src={HeaderLogoNew} alt="Shubhra Viraj" loading="lazy" />
              </Link>
            </div>
            <nav className={`user_nav ${dashboardMenu ? "open" : ""}`}>
              <ul onClick={() => setDashboardMenu(false)}>
                <li>
                  <NavLink to="/dashboard/home">Dashboard</NavLink>
                </li>
                <li>
                  <NavLink to={`/dashboard/lecture/${course.courseId}`}>
                    Lectures
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/dashboard/test-series">
                    Test&nbsp;series
                  </NavLink>
                </li>
                {!isMemtorAppointmentType && !isMemtorSelection &&(<li>
                  <NavLink to="/dashboard/toppers-copies">
                    Topper's&nbsp;Copies
                  </NavLink>
                </li>)}
                <li>
                  <NavLink to="/dashboard/lecture-notes">
                    Lecture&nbsp;Notes
                  </NavLink>
                </li>

                {course.isPostal && !isMemtorAppointmentType && !isMemtorSelection &&(
                  <li>
                    <NavLink to="/dashboard/postal-folder-listing">
                      Postal&nbsp;Document
                    </NavLink>
                  </li>
                )}

                {!checkHomePage && (
                  <li>
                    <button
                      onClick={() => setCourseDrawerShow(true)}
                      type="button"
                      className="button"
                    >
                      {course?.courseName || "My Courses"}
                    </button>
                  </li>
                )}
              </ul>

              <ul>
                {!course.isPostal && (
                  <li>
                    <a
                      className="mobile-only"
                      style={{ fontSize: "16px", color: "#4971B2" }}
                      //onClick={() => setCourseDrawerShow(true)}
                      onClick={() => setLectureDrawerShow(true)}
                    >
                      Click to select courses
                    </a>
                  </li>
                )}
              </ul>
            </nav>
            <div className="user_section">
              <ul>
                {!course.isPostal &&
                  !isDashboardPage &&
                  !isMemtorAppointmentType && !isMemtorSelection &&
                  !isTopperCopiesPage && (
                    <li
                      className="changecoursetext hide-on-mobile"
                      style={{
                        color: "#7986CB",
                        fontWeight: "500",
                        fontSize: "13px",
                      }}
                    >
                      Click to change course &nbsp;
                      <FaArrowRight
                        style={{ color: "#7986CB", fontSize: "13px" }}
                      />
                    </li>
                  )}
                  
                <li>
                  <button
                    onClick={openCourseDrawer}
                    type="button"
                    className="button"
                    disabled={isDrawerDisabled}
                  >
                    {checkHomePage
                      ? "My Courses"
                      : charLimit(course?.courseName, 10) || "My Courses"}
                  </button>
                </li>
                


                <li>
                  <Link
                    onClick={() => {
                      setHelpShow(true);
                    }}
                  >
                    <BsQuestionCircleFill />
                  </Link>
                </li>

                <NotificationDrawer />
                <li>
                  <Avatar
                    type="dashboard"
                    onClick={() => setDashboardMenu(false)}
                  />
                </li>
              </ul>
            </div>
            <div
              className="mobile-button"
              onClick={() => setDashboardMenu(!dashboardMenu)}
            >
              <span className="open">
                <FaBars />
              </span>
              <span className="close">
                <FaTimes />
              </span>
            </div>
          </div>
        </header>
        <div
          className="mobile_overlay"
          onClick={() => setDashboardMenu(false)}
        ></div>
      </div>

      {/* LectureDrawer only displayed when on the lecture page */}
      {/* <LectureDrawer
				show={lectureDrawerShow}
				handleClose={() => setLectureDrawerShow(false)}
				title={"Lectures Drawer"}
			>
				 <div className="mycourses_listing">
          {allCourses?.length > 0 || allDefaultCourses?.length > 0 ? (
            <>
               {allCourses.length > 0 && (
                <div>
                  <h6 className="course_label">Purchased Courses</h6>
                  <hr />
                  <ul>
                    {allCourses.map(
                      (
                        {
                          CourseID,
                          DisplayName,
                          Description,
                          OnDemand,
                          EndDate,
                          StartDate,
                          isDefaultCourse,
                          isGps,
                          coursePlatformEnabled,
                          isLectureAvailable,
                          isTestAvailable,
                          isPostal,
                        },
                        i
                      ) => {
                        return (
                          <React.Fragment key={i}>
                             {+isDefaultCourse === 1 && (
                              <li
                                className={`${
                                  course.courseId === CourseID && "active"
                                } ${!course.courseId && "disabled"}`}
                                key={i}
                                onClick={() => {
                                  courseSelectionHandler(
                                    {
                                      courseId: CourseID,
                                      courseName: DisplayName,
                                      isOnDemand:
                                        OnDemand === "Y" ? true : false,
                                      isGps,
                                      coursePlatformEnabled,
                                      isLectureAvailable,
                                      isTestAvailable,
                                      isPostal,
                                    },
                                    StartDate
                                  );
                                }}
                              >
                                <Link>
                                  {DisplayName}
                                  <span>
                                    {momentDateFormat(StartDate)} -
                                    {momentDateFormat(EndDate)}
                                  </span>

                                  {course.courseId && (
                                    <span className="upcomingTag">
                                      Demo Course
                                    </span>
                                  )}
                                  <div className="description_wrapper">
                                    {isPostal && (
                                      <span className="postalTag">
                                        Postal Document Available
                                      </span>
                                    )}
                                    <button
                                      className="view_all"
                                      onClick={(e) => {
                                        setSelectedData({
                                          title: DisplayName,
                                          description: Description,
                                        });
                                        setDescriptionModal(true);
                                      }}
                                    >
                                      View description
                                    </button>
                                  </div>
                                </Link>
                              </li>
                            )}
                             {(+isDefaultCourse === 0 ||
                              +isDefaultCourse === 2) && (
                              <li
                                className={`${
                                  course.courseId === CourseID && "active"
                                } ${!courseAccess(StartDate) && "disabled"}`}
                                key={i}
                                onClick={() => {
                                  courseSelectionHandler(
                                    {
                                      courseId: CourseID,
                                      courseName: DisplayName,
                                      isOnDemand:
                                        OnDemand === "Y" ? true : false,
                                      isGps,
                                      coursePlatformEnabled,
                                      isLectureAvailable,
                                      isTestAvailable,
                                      isPostal,
                                    },
                                    StartDate
                                  );
                                }}
                              >
                                <Link>
                                  {DisplayName}
                                  <span>
                                    {momentDateFormat(StartDate)} -
                                    {momentDateFormat(EndDate)}
                                  </span>

                                  {!courseAccess(StartDate) && (
                                    <span className="upcomingTag">
                                      Upcoming
                                    </span>
                                  )}
                                  <div className="description_wrapper">
                                    <div className="postal">
                                      {isPostal && (
                                        <span className="postalTag">
                                          Postal Document Available
                                        </span>
                                      )}
                                    </div>

                                    <button
                                      className="view_all"
                                      onClick={(e) => {
                                        setSelectedData({
                                          title: DisplayName,
                                          description: Description,
                                        });
                                        setDescriptionModal(true);
                                      }}
                                    >
                                      View description
                                    </button>
                                  </div>
                                </Link>
                              </li>
                            )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </ul>
                </div>
              )}

               {allDefaultCourses.length > 0 && (
                <div>
                  <h6 className="course_label">Default Courses</h6>
                  <hr />
                  <ul>
                    {allDefaultCourses.map(
                      (
                        {
                          CourseID,
                          DisplayName,
                          Description,
                          OnDemand,
                          EndDate,
                          StartDate,
                          isDefaultCourse,
                          isGps,
                          coursePlatformEnabled,
                          isLectureAvailable,
                          isTestAvailable,
                          isPostal,
                        },
                        i
                      ) => {
                        return (
                          <React.Fragment key={i}>
                             {+isDefaultCourse === 1 && (
                              <li
                                className={`${
                                  course.courseId === CourseID && "active"
                                } ${!course.courseId && "disabled"}`}
                                key={i}
                                onClick={() => {
                                  courseSelectionHandler(
                                    {
                                      courseId: CourseID,
                                      courseName: DisplayName,
                                      isOnDemand:
                                        OnDemand === "Y" ? true : false,
                                      isGps,
                                      coursePlatformEnabled,
                                      isLectureAvailable,
                                      isTestAvailable,
                                      isPostal,
                                    },
                                    StartDate
                                  );
                                }}
                              >
                                <Link>
                                  {DisplayName}
                                  <span>
                                    {momentDateFormat(StartDate)} -
                                    {momentDateFormat(EndDate)}
                                  </span>

                                  {course.courseId && (
                                    <span className="upcomingTag">
                                      Demo Course
                                    </span>
                                  )}
                                  <div className="description_wrapper">
                                    {isPostal && (
                                      <span className="postalTag">
                                        Postal Document Available
                                      </span>
                                    )}
                                    <button
                                      className="view_all"
                                      onClick={(e) => {
                                        setSelectedData({
                                          title: DisplayName,
                                          description: Description,
                                        });
                                        setDescriptionModal(true);
                                      }}
                                    >
                                      View description
                                    </button>
                                  </div>
                                </Link>
                              </li>
                            )}
                             {(+isDefaultCourse === 0 ||
                              +isDefaultCourse === 2) && (
                              <li
                                className={`${
                                  course.courseId === CourseID && "active"
                                } ${!courseAccess(StartDate) && "disabled"}`}
                                key={i}
                                onClick={() => {
                                  courseSelectionHandler(
                                    {
                                      courseId: CourseID,
                                      courseName: DisplayName,
                                      isOnDemand:
                                        OnDemand === "Y" ? true : false,
                                      isGps,
                                      coursePlatformEnabled,
                                      isLectureAvailable,
                                      isTestAvailable,
                                      isPostal,
                                    },
                                    StartDate
                                  );
                                }}
                              >
                                <Link>
                                  {DisplayName}
                                  <span>
                                    {momentDateFormat(StartDate)} -
                                    {momentDateFormat(EndDate)}
                                  </span>

                                  {!courseAccess(StartDate) && (
                                    <span className="upcomingTag">
                                      Upcoming
                                    </span>
                                  )}
                                  <div className="description_wrapper">
                                    <div className="postal">
                                      {isPostal && (
                                        <span className="postalTag">
                                          Postal Document Available
                                        </span>
                                      )}
                                    </div>

                                    <button
                                      className="view_all"
                                      onClick={(e) => {
                                        setSelectedData({
                                          title: DisplayName,
                                          description: Description,
                                        });
                                        setDescriptionModal(true);
                                      }}
                                    >
                                      View description
                                    </button>
                                  </div>
                                </Link>
                              </li>
                            )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </ul>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="course-not-available">
                <div className="container">
                  <h5>Courses Not Available</h5>
                  <Link className="button" to="/course-listing">
                    Purchase
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
			</LectureDrawer> */}
      <LectureDrawer
        show={lectureDrawerShow}
        handleClose={() => setLectureDrawerShow(false)}
        title={"My Courses"}
      >
        <div className="mycourses_listing">
          {allCourses?.length > 0 || allDefaultCourses?.length > 0 ? (
            <>
              {/* Normal courses */}
              {allCourses.length > 0 && (
                <div>
                  <h6 className="course_label">Purchased Courses</h6>
                  <hr />
                  <ul>
                    {allCourses.map(
                      (
                        {
                          CourseID,
                          DisplayName,
                          Description,
                          OnDemand,
                          EndDate,
                          StartDate,
                          isDefaultCourse,
                          isGps,
                          coursePlatformEnabled,
                          isLectureAvailable,
                          isTestAvailable,
                          isPostal,
                          Mentorship,
                          CoursePlatform,
                        },
                        i
                      ) => {
                        const platformsToShow = [];
                        if (!coursePlatformEnabled && CoursePlatform) {
                          if (CoursePlatform.includes("Mobile")) {
                            platformsToShow.push("Mobile");
                          }
                          if (CoursePlatform.includes("Tablet")) {
                            platformsToShow.push("Tablet");
                          }
                        }

                        return (
                          <React.Fragment key={i}>
                            {/* Default or demo course list */}
                            {+isDefaultCourse === 1 && (
                              <li
                                className={`${course.courseId === CourseID && "active"
                                  } ${(!course.courseId ||
                                    !coursePlatformEnabled) &&
                                  "disabled"
                                  }`}
                                key={i}
                                onClick={() => {
                                  if (coursePlatformEnabled) {
                                    navigate(
                                      `/dashboard/lecture/${course.courseId}`
                                    );
                                    courseSelectionHandler(
                                      {
                                        courseId: CourseID,
                                        courseName: DisplayName,
                                        isOnDemand:
                                          OnDemand === "Y" ? true : false,
                                        isGps,
                                        coursePlatformEnabled,
                                        isLectureAvailable,
                                        isTestAvailable,
                                        isPostal,
                                        ismentorship:
                                          Mentorship === "Y" ? true : false,
                                      },
                                      StartDate
                                    );
                                  }
                                }}
                              >
                                <Link>
                                  {DisplayName}
                                  <span>
                                    {momentDateFormat(StartDate)} -{" "}
                                    {momentDateFormat(EndDate)}
                                  </span>

                                  {course.courseId && (
                                    <span className="upcomingTag">
                                      Demo Course
                                    </span>
                                  )}

                                  {!coursePlatformEnabled &&
                                    platformsToShow.length > 0 && (
                                      <div className="platform_indicator">
                                        {platformsToShow.map(
                                          (platform, index) => (
                                            <span
                                              key={index}
                                              className="upcomingTag"
                                            >
                                              {platform}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    )}

                                  <div className="description_wrapper">
                                    {isPostal && (
                                      <span className="postalTag">
                                        Postal Document Available
                                      </span>
                                    )}
                                    <button
                                      className="view_all"
                                      onClick={(e) => {
                                        navigate(
                                          `/dashboard/lecture/${course.courseId}`
                                        );
                                        setSelectedData({
                                          title: DisplayName,
                                          description: Description,
                                        });
                                        setDescriptionModal(true);
                                      }}
                                    >
                                      View description
                                    </button>
                                  </div>
                                </Link>
                              </li>
                            )}
                            {/* Purchased course list and assign course list */}
                            {(+isDefaultCourse === 0 ||
                              +isDefaultCourse === 2) && (
                                <li
                                  className={`${course.courseId === CourseID && "active"
                                    } ${(!courseAccess(StartDate) ||
                                      !coursePlatformEnabled) &&
                                    "disabled"
                                    }`}
                                  key={i}
                                  onClick={() => {
                                    if (coursePlatformEnabled) {
                                      navigate(
                                        `/dashboard/lecture/${course.courseId}`
                                      );
                                      courseSelectionHandler(
                                        {
                                          courseId: CourseID,
                                          courseName: DisplayName,
                                          isOnDemand:
                                            OnDemand === "Y" ? true : false,
                                          isGps,
                                          coursePlatformEnabled,
                                          isLectureAvailable,
                                          isTestAvailable,
                                          isPostal,
                                          ismentorship:
                                          Mentorship === "Y" ? true : false,
                                        },
                                        StartDate
                                      );
                                    }
                                  }}
                                >
                                  <Link>
                                    <span
                                      style={{
                                        marginTop: "18px",
                                        fontWeight: "600",
                                        fontSize: "medium",
                                      }}
                                    >
                                      {DisplayName}
                                    </span>

                                    <span>
                                      {momentDateFormat(StartDate)} -{" "}
                                      {momentDateFormat(EndDate)}
                                    </span>

                                    {!courseAccess(StartDate) && (
                                      <span className="upcomingTag">
                                        Upcoming
                                      </span>
                                    )}

                                    {!coursePlatformEnabled &&
                                      platformsToShow.length > 0 && (
                                        <div className="platform_indicator">
                                          {platformsToShow.map(
                                            (platform, index) => (
                                              <span
                                                key={index}
                                                className="platformTag"
                                              >
                                                {platform}
                                              </span>
                                            )
                                          )}
                                        </div>
                                      )}

                                    <div className="description_wrapper">
                                      <div className="postal">
                                        {isPostal && (
                                          <span className="postalTag">
                                            Postal Document Available
                                          </span>
                                        )}
                                      </div>
                                      <button
                                        className="view_all"
                                        onClick={(e) => {
                                          navigate(
                                            `/dashboard/lecture/${course.courseId}`
                                          );
                                          setSelectedData({
                                            title: DisplayName,
                                            description: Description,
                                          });
                                          setDescriptionModal(true);
                                        }}
                                      >
                                        View description
                                      </button>
                                    </div>
                                  </Link>
                                </li>
                              )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </ul>
                </div>
              )}

              {/* Default courses */}
              {allDefaultCourses.length > 0 && (
                <div>
                  <h6 className="course_label">Default Courses</h6>
                  <hr />
                  <ul>
                    {allDefaultCourses.map(
                      (
                        {
                          CourseID,
                          DisplayName,
                          Description,
                          OnDemand,
                          EndDate,
                          StartDate,
                          isDefaultCourse,
                          isGps,
                          coursePlatformEnabled,
                          isLectureAvailable,
                          isTestAvailable,
                          isPostal,
                          Mentorship,
                          CoursePlatform,
                        },
                        i
                      ) => {
                        const platformsToShow = [];
                        if (!coursePlatformEnabled && CoursePlatform) {
                          if (CoursePlatform.includes("Mobile")) {
                            platformsToShow.push("Mobile");
                          }
                          if (CoursePlatform.includes("Tablet")) {
                            platformsToShow.push("Tablet");
                          }
                        }

                        return (
                          <React.Fragment key={i}>
                            {/* Default or demo course list */}
                            {+isDefaultCourse === 1 && (
                              <li
                                className={`${course.courseId === CourseID && "active"
                                  } ${(!course.courseId ||
                                    !coursePlatformEnabled) &&
                                  "disabled"
                                  }`}
                                key={i}
                                onClick={() => {
                                  if (coursePlatformEnabled) {
                                    navigate(
                                      `/dashboard/lecture/${course.courseId}`
                                    );
                                    courseSelectionHandler(
                                      {
                                        courseId: CourseID,
                                        courseName: DisplayName,
                                        isOnDemand:
                                          OnDemand === "Y" ? true : false,
                                        isGps,
                                        coursePlatformEnabled,
                                        isLectureAvailable,
                                        isTestAvailable,
                                        isPostal,
                                        ismentorship:
                                          Mentorship === "Y" ? true : false,
                                      },
                                      StartDate
                                    );
                                  }
                                }}
                              >
                                <Link>
                                  {DisplayName}
                                  <span>
                                    {momentDateFormat(StartDate)} -
                                    {momentDateFormat(EndDate)}
                                  </span>

                                  {course.courseId && (
                                    <span className="upcomingTag">
                                      Demo Course
                                    </span>
                                  )}
                                  <div className="description_wrapper">
                                    {isPostal && (
                                      <span className="postalTag">
                                        Postal Document Available
                                      </span>
                                    )}
                                    <button
                                      className="view_all"
                                      onClick={(e) => {
                                        navigate(
                                          `/dashboard/lecture/${course.courseId}`
                                        );
                                        setSelectedData({
                                          title: DisplayName,
                                          description: Description,
                                        });
                                        setDescriptionModal(true);
                                      }}
                                    >
                                      View description
                                    </button>
                                  </div>
                                </Link>
                                {!coursePlatformEnabled &&
                                  platformsToShow.length > 0 && (
                                    <div className="platform_indicator">
                                      {platformsToShow.map(
                                        (platform, index) => (
                                          <span
                                            key={index}
                                            className="platformTag"
                                          >
                                            {platform}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  )}
                              </li>
                            )}
                            {/* Purchased course list and assign course list */}
                            {(+isDefaultCourse === 0 ||
                              +isDefaultCourse === 2) && (
                                <li
                                  className={`${course.courseId === CourseID && "active"
                                    } ${(!courseAccess(StartDate) ||
                                      !coursePlatformEnabled) &&
                                    "disabled"
                                    }`}
                                  key={i}
                                  onClick={() => {
                                    if (coursePlatformEnabled) {
                                      navigate(
                                        `/dashboard/lecture/${course.courseId}`
                                      );
                                      courseSelectionHandler(
                                        {
                                          courseId: CourseID,
                                          courseName: DisplayName,
                                          isOnDemand:
                                            OnDemand === "Y" ? true : false,
                                          isGps,
                                          coursePlatformEnabled,
                                          isLectureAvailable,
                                          isTestAvailable,
                                          isPostal,
                                          ismentorship:
                                            Mentorship === "Y" ? true : false,
                                        },
                                        StartDate
                                      );
                                    }
                                  }}
                                >
                                  <Link>
                                    {DisplayName}
                                    <span>
                                      {momentDateFormat(StartDate)} -
                                      {momentDateFormat(EndDate)}
                                    </span>

                                    {!courseAccess(StartDate) && (
                                      <span className="upcomingTag">
                                        Upcoming
                                      </span>
                                    )}
                                    <div className="description_wrapper">
                                      <div className="postal">
                                        {isPostal && (
                                          <span className="postalTag">
                                            Postal Document Available
                                          </span>
                                        )}
                                      </div>

                                      <button
                                        className="view_all"
                                        onClick={(e) => {
                                          navigate(
                                            `/dashboard/lecture/${course.courseId}`
                                          );
                                          setSelectedData({
                                            title: DisplayName,
                                            description: Description,
                                          });
                                          setDescriptionModal(true);
                                        }}
                                      >
                                        View description
                                      </button>
                                    </div>
                                  </Link>
                                  {!coursePlatformEnabled &&
                                    platformsToShow.length > 0 && (
                                      <div className="platform_indicator">
                                        {platformsToShow.map(
                                          (platform, index) => (
                                            <span key={index}>{platform}</span>
                                          )
                                        )}
                                      </div>
                                    )}
                                </li>
                              )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </ul>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="course-not-available">
                <div className="container">
                  <h5>Courses Not Available</h5>
                  <Link className="button" to="/course-listing">
                    Purchase
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </LectureDrawer>

      {/* Course Listing */}
      <Drawer
        show={courseDrawerShow}
        handleClose={() => {
          setCourseDrawerShow(false);
        }}
        title={"My Courses"}
      >
        <div className="mycourses_listing">
          {allCourses?.length > 0 || allDefaultCourses?.length > 0 ? (
            <>
              {/* normal courses */}
              {allCourses.length > 0 && (
                <div>
                  <h6 className="course_label">Purchased Courses</h6>
                  <hr />
                  <ul>
                    {allCourses.map(
                      (
                        {
                          CourseID,
                          DisplayName,
                          Description,
                          OnDemand,
                          EndDate,
                          StartDate,
                          isDefaultCourse,
                          isGps,
                          coursePlatformEnabled,
                          isLectureAvailable,
                          isTestAvailable,
                          isPostal,
                          Mentorship
                        },
                        i
                      ) => {
                        return (
                          <React.Fragment key={i}>
                            {/* // default or demo course list */}
                            {+isDefaultCourse === 1 && (
                              <li
                                className={`${course.courseId === CourseID && "active"
                                  } ${!course.courseId && "disabled"}`}
                                key={i}
                                onClick={() => {
                                  courseSelectionHandler(
                                    {
                                      courseId: CourseID,
                                      courseName: DisplayName,
                                      isOnDemand:
                                        OnDemand === "Y" ? true : false,
                                      isGps,
                                      coursePlatformEnabled,
                                      isLectureAvailable,
                                      isTestAvailable,
                                      isPostal,
                                      ismentorship:
                                        Mentorship === "Y" ? true : false,
                                    },
                                    StartDate
                                  );
                                }}
                              >
                                <Link>
                                  {DisplayName}
                                  <span>
                                    {momentDateFormat(StartDate)} -
                                    {momentDateFormat(EndDate)}
                                  </span>

                                  {course.courseId && (
                                    <span className="upcomingTag">
                                      Demo Course
                                    </span>
                                  )}
                                  <div className="description_wrapper">
                                    {isPostal && (
                                      <span className="postalTag">
                                        Postal Document Available
                                      </span>
                                    )}
                                    <button
                                      className="view_all"
                                      onClick={(e) => {
                                        setSelectedData({
                                          title: DisplayName,
                                          description: Description,
                                        });
                                        setDescriptionModal(true);
                                      }}
                                    >
                                      View description
                                    </button>
                                  </div>
                                </Link>
                              </li>
                            )}
                            {/* // purchased course list and assign course list*/}
                            {(+isDefaultCourse === 0 ||
                              +isDefaultCourse === 2) && (
                                <li
                                  className={`${course.courseId === CourseID && "active"
                                    } ${!courseAccess(StartDate) && "disabled"}`}
                                  key={i}
                                  onClick={() => {
                                    courseSelectionHandler(
                                      {
                                        courseId: CourseID,
                                        courseName: DisplayName,
                                        isOnDemand:
                                          OnDemand === "Y" ? true : false,
                                        isGps,
                                        coursePlatformEnabled,
                                        isLectureAvailable,
                                        isTestAvailable,
                                        isPostal,
                                        ismentorship:
                                        Mentorship === "Y" ? true : false,
                                      },
                                      StartDate
                                    );
                                  }}
                                >
                                  <Link>
                                    {DisplayName}
                                    <span>
                                      {momentDateFormat(StartDate)} -
                                      {momentDateFormat(EndDate)}
                                    </span>

                                    {!courseAccess(StartDate) && (
                                      <span className="upcomingTag">
                                        Upcoming
                                      </span>
                                    )}
                                    <div className="description_wrapper">
                                      <div className="postal">
                                        {isPostal && (
                                          <span className="postalTag">
                                            Postal Document Available
                                          </span>
                                        )}
                                      </div>

                                      <button
                                        className="view_all"
                                        onClick={(e) => {
                                          setSelectedData({
                                            title: DisplayName,
                                            description: Description,
                                          });
                                          setDescriptionModal(true);
                                        }}
                                      >
                                        View description
                                      </button>
                                    </div>
                                  </Link>
                                </li>
                              )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </ul>
                </div>
              )}

              {/* default courses */}
              {allDefaultCourses.length > 0 && (
                <div>
                  <h6 className="course_label">Default Courses</h6>
                  <hr />
                  <ul>
                    {allDefaultCourses.map(
                      (
                        {
                          CourseID,
                          DisplayName,
                          Description,
                          OnDemand,
                          EndDate,
                          StartDate,
                          isDefaultCourse,
                          isGps,
                          coursePlatformEnabled,
                          isLectureAvailable,
                          isTestAvailable,
                          isPostal,
                          Mentorship
                        },
                        i
                      ) => {
                        return (
                          <React.Fragment key={i}>
                            {/* // default or demo course list */}
                            {+isDefaultCourse === 1 && (
                              <li
                                className={`${course.courseId === CourseID && "active"
                                  } ${!course.courseId && "disabled"}`}
                                key={i}
                                onClick={() => {
                                  courseSelectionHandler(
                                    {
                                      courseId: CourseID,
                                      courseName: DisplayName,
                                      isOnDemand:
                                        OnDemand === "Y" ? true : false,
                                      isGps,
                                      coursePlatformEnabled,
                                      isLectureAvailable,
                                      isTestAvailable,
                                      isPostal,
                                      ismentorship:
                                        Mentorship === "Y" ? true : false,
                                    },
                                    StartDate
                                  );
                                }}
                              >
                                <Link>
                                  {DisplayName}
                                  <span>
                                    {momentDateFormat(StartDate)} -
                                    {momentDateFormat(EndDate)}
                                  </span>

                                  {course.courseId && (
                                    <span className="upcomingTag">
                                      Demo Course
                                    </span>
                                  )}
                                  <div className="description_wrapper">
                                    {isPostal && (
                                      <span className="postalTag">
                                        Postal Document Available
                                      </span>
                                    )}
                                    <button
                                      className="view_all"
                                      onClick={(e) => {
                                        setSelectedData({
                                          title: DisplayName,
                                          description: Description,
                                        });
                                        setDescriptionModal(true);
                                      }}
                                    >
                                      View description
                                    </button>
                                  </div>
                                </Link>
                              </li>
                            )}
                            {/* // purchased course list and assign course list*/}
                            {(+isDefaultCourse === 0 ||
                              +isDefaultCourse === 2) && (
                                <li
                                  className={`${course.courseId === CourseID && "active"
                                    } ${!courseAccess(StartDate) && "disabled"}`}
                                  key={i}
                                  onClick={() => {
                                    courseSelectionHandler(
                                      {
                                        courseId: CourseID,
                                        courseName: DisplayName,
                                        isOnDemand:
                                          OnDemand === "Y" ? true : false,
                                        isGps,
                                        coursePlatformEnabled,
                                        isLectureAvailable,
                                        isTestAvailable,
                                        isPostal,
                                        ismentorship:
                                        Mentorship === "Y" ? true : false,
                                      },
                                      StartDate
                                    );
                                  }}
                                >
                                  <Link>
                                    {DisplayName}
                                    <span>
                                      {momentDateFormat(StartDate)} -
                                      {momentDateFormat(EndDate)}
                                    </span>

                                    {!courseAccess(StartDate) && (
                                      <span className="upcomingTag">
                                        Upcoming
                                      </span>
                                    )}
                                    <div className="description_wrapper">
                                      <div className="postal">
                                        {isPostal && (
                                          <span className="postalTag">
                                            Postal Document Available
                                          </span>
                                        )}
                                      </div>

                                      <button
                                        className="view_all"
                                        onClick={(e) => {
                                          setSelectedData({
                                            title: DisplayName,
                                            description: Description,
                                          });
                                          setDescriptionModal(true);
                                        }}
                                      >
                                        View description
                                      </button>
                                    </div>
                                  </Link>
                                </li>
                              )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </ul>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="course-not-available">
                <div className="container">
                  <h5>Courses Not Available</h5>
                  <Link className="button" to="/course-listing">
                    Purchase
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </Drawer>

      {/* Help */}
      <HelpDrawer show={helpShow} handleClose={() => setHelpShow(false)} />

      {/* Notification drawer */}

      {/* Video modal */}
      <CommonModal
        show={descriptionModal}
        handleClose={() => setDescriptionModal(false)}
        className={"modal-lg"}
        title={selectedData?.title}
      >
        <SuperTag>{selectedData?.description}</SuperTag>
      </CommonModal>
    </>
  );
};

export default Header;
