import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getMentorsList,
  getSlotList,
  getAvailableSlots,
  BookMyAppointment,
  getAppointmentDetailsBySlot,
  getMentorsSlots,
  getSelectedMentorAvailableSlots,
  CancelMyAppointment,
} from "services/mentor/mentor";
import { errorResponse, failResponse } from "config/config";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import NoSlotsMessage from "./NoSlotsMessage";
import { useCourse } from "context/context";



const MentorSelection = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState(null);
  const [selectedMentorType, setselectedMentorType] = useState(null);
  const [selectedSlotOption, setSelectedSlotOption] = useState(null);
  const [mentorlength, setMentorlength] = useState(0);
  const [mentors, setMentors] = useState([]);
  const [slots, setSlots] = useState([]);
  const [AvailableSlots, setAvailableSlots] = useState([]);
  const [AvailableMentors, setAvailableMentors] = useState([]);
  const [SelectedAvailableSlots, setSelectedAvailableSlots] = useState([]);
  const [BookingDetails, setBookingDetails] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMentorAvailableDate, setSelectedMentorAvailableDate] =
    useState(null);
  const [selectedAvailableMentor, setSelectedAvailableMentor] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [dates, setDates] = useState([]);
  const [mentordates, setMentorDates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAnotherModalOpen, setIsAnotherModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { course } = useCourse();


  const location = useLocation();
  const { selectedAppointmentType } = location.state || {};

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleAppointmentType = (type, mentorname) => {
    setSelectedType(type);
    setselectedMentorType(mentorname);
  };

  useEffect(() => {
    if (mentordates.length > 0) {
      setSelectedMentorAvailableDate(mentordates[0].date);
      getselectedmentorslots(mentordates[0].date);
    }
  }, [mentordates]);

  const handleSlotOption = (option) => {
    setSelectedSlotOption(option);
    setSelectedMentorAvailableDate(null);
    setSelectedType(null);
    setselectedMentorType(null);
    if (selectedSlotOption === "my") {
      setSelectedDate(dates[0].date);
      getavailableslots(dates[0].date);
    } else if (selectedSlotOption === null) {
      setSelectedDate(dates[0].date);
      getavailableslots(dates[0].date);
    } else {
      setSelectedDate(null);
    }
  };

  const modalmentors = [
    { id: 1, name: "Shubhra Ranjan" },
    { id: 2, name: "Satya Prakash" },
  ];

  const handleBookingClick = (e) => {
    e.preventDefault();
    setShowConfirmation(true); // Show the confirmation modal
  };

  // Function to confirm the booking
  const confirmBooking = (e) => {
    e.preventDefault();
    setShowConfirmation(false); // Close the confirmation modal
    // Proceed with the booking logic here
    if (selectedSlotOption === "mentor") {
      bookappointment(
        e,
        selectedAvailableMentor?.mentorid,
        selectedAvailableMentor?.mentorname,
        selectedTimeSlot?.id,
        selectedTimeSlot?.slottime,
        selectedDate
      );
    } else {
      bookappointment(
        e,
        selectedType,
        selectedMentorType,
        selectedTimeSlot?.id,
        selectedTimeSlot?.slottime,
        selectedMentorAvailableDate
      );
    }
  };

  // Function to cancel the booking
  const cancelBooking = (e) => {
    setShowConfirmation(false); // Close the confirmation modal without booking
    if (selectedSlotOption === "mentor") {
      getavailableslots(selectedDate || selectedMentorAvailableDate);
      setSelectedTimeSlot(null);
      setSelectedType(null);
      setSelectedAvailableMentor(null);
    } else {
      getselectedmentorslots(selectedDate || selectedMentorAvailableDate);
      setSelectedTimeSlot(null);
    }
  };
  useEffect(() => {
    // Fetch the list of mentors
    const getmentorslist = async () => {
      try {
        const res = await getMentorsList({ courseid: course.courseId });
        if (res.code === 200) {
          setMentors(res.data.mentorslist || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };
    getmentorslist();
  }, []);

  useEffect(() => {
    // Fetch the list of mentors
    const getslotlist = async () => {
      try {
        const res = await getSlotList();
        if (res.code === 200) {
          setSlots(res.data.slots || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };
    getslotlist();
  }, []);

  const getavailableslots = async (date, slotid) => {
    try {
      const res = await getAvailableSlots({
        date: date,
        slotid: slotid || "",
        courseid: course.courseId
      });
      if (res.code === 200) {
        setAvailableSlots(res.data.availableslots || []);
        const mentorDetails = res.data.availableslots.map((slot) => ({
          mentorid: slot.mentorid,
          mentorname: slot.mentorname,
          showmentor: slot.showmentor,
        }));

        const uniqueMentors = Array.from(
          new Map(
            res.data.availableslots.map((slot) => [slot.mentorid, slot])
          ).values()
        );
        setAvailableMentors(uniqueMentors);
        console.log("AvailableMentors", mentorDetails);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };
  const availableSlotIds = new Set(AvailableSlots.map((slot) => slot.slotid));

  const mentorsslots = async (mentorid) => {
    try {
      const res = await getMentorsSlots({
        mentorid: mentorid,
      });
      if (res.code === 200) {
        setMentorlength(res.data.mentorslots.length);
        if (res.data.mentorslots.length === 0) {
          setMentorlength(res.data.mentorslots.length);
          return null;
        } else {
          setMentorlength(res.data.mentorslots.length);

          // Use a Set to keep track of unique dates
          const uniqueDates = new Set();
          const mentordates = res.data.mentorslots
            .filter((slot) => {
              const date = slot.date;
              if (uniqueDates.has(date)) {
                return false; // Skip duplicate dates
              }
              uniqueDates.add(date);
              return true;
            })
            .map((slot) => {
              const originalDate = new Date(slot.date);

              // Get the day name and date components
              const options = {
                weekday: "long",
                day: "2-digit",
                month: "short",
              };
              const formattedDate = originalDate.toLocaleDateString(
                "en-GB",
                options
              );

              // Split the formatted date into dayName, day, and month
              const [dayName, day, month] = formattedDate.split(" ");

              return {
                date: originalDate.toISOString().split("T")[0], // Store the date in 'YYYY-MM-DD' format
                dayName,
                day,
                month,
              };
            })
            .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort the dates in ascending order

          setMentorDates(mentordates);

          console.log("Unique Dates:", mentordates);
        }
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };

  const getselectedmentorslots = async (date) => {
    try {
      const res = await getSelectedMentorAvailableSlots({
        date: date,
        mentorid: selectedType,
      });
      if (res.code === 200) {
        setSelectedAvailableSlots(res.data.selectedmentorslots || []);
        const mentorDetails = res.data.selectedmentorslots.map((slot) => ({
          mentorid: slot.mentorid,
          mentorname: slot.mentorname,
          showmentor: slot.showmentor,
          slotid: slot.slotid,
          slotname: slot.slotname,
        }));

        setSelectedAvailableSlots(mentorDetails);
        const uniqueMentors = Array.from(
          new Map(
            res.data.selectedmentorslots.map((slot) => [slot.mentorid, slot])
          ).values()
        );
        setAvailableMentors(uniqueMentors);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };
  const selectedmentorslotids = new Set(
    SelectedAvailableSlots.map((slot) => slot.slotid)
  );

  const getappointmentdetailsbyslot = async (date, slotid) => {
    try {
      const res = await getAppointmentDetailsBySlot({
        date: date,
        slotid: slotid || "",
      });
      if (res.code === 200) {
        const bookingdetails = res.data.appointmentdetails.map((slot) => ({
          mentorid: slot.mentorid,
          mentorname: slot.mentorname,
          slotname: slot.slotname,
          slotid: slot.slotid,
          date: slot.date,
          type: slot.type,
          cancelmeeting: slot.cancelmeeting,
        }));

        setBookingDetails(bookingdetails);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };

  useEffect(() => {
    const generateDates = () => {
      const tempDates = [];
      const today = new Date();
      for (let i = 0; i < 7; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        const dayName = date.toLocaleDateString("en-US", { weekday: "short" });
        const day = date.getDate();
        const month = date.toLocaleDateString("en-US", { month: "short" });
        tempDates.push({ dayName, day, month, date });
      }
      setDates(tempDates);
    };
    generateDates();
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Detect mobile view (width <= 600px)
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it initially to set the correct state

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maincontainerstyle = {
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f7f7f9",
    minHeight: "90vh",
  };

  const outerContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f7f7f9",
    minHeight: "40vh",
  };

  const innerContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "880px",
  };

  const cardStyle1 = {
    width: "100%",
    padding: "20px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    textAlign: "center",
    marginTop: "20px",
    fontWeight: "500",
    display: "flex",
    gap: "100px",
    justifyContent: "center",
  };

  const cardStyle = {
    width: "101%",
    padding: "20px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    textAlign: "center",
    marginTop: "20px",
    fontWeight: "500",
    maxHeight: selectedSlotOption === "mentorselection" ? "450px" : "340px",
    overflowY: "auto",
  };

  const optionRowStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "10px",
    cursor: "pointer",
  };

  const checkboxSlotStyle = (isSelected) => ({
    width: "18px",
    height: isMobile ? "15px" : "18px",
    borderRadius: "50%",
    marginRight: "10px",
    border: isSelected
      ? "2px solid transparent"
      : "2px solid rgb(204, 204, 204)",
    backgroundColor: isSelected ? "#3A69B0" : "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff",
    fontSize: "12px",
    fontWeight: "bold",
  });

  const buttonContainerStyle = {
    display: "grid",
    gridTemplateColumns: isMobile ? "1fr" : "repeat(3, 1fr)",
    gap: "8px", // Adds space between items
  };

  const buttonContainerStyle1 = {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)", // Ensures 3 items per row
    gap: "8px", // Adds space between items
  };

  const optionStyle = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    margin: "10px",
    cursor: "pointer",
    backgroundColor: "#ffffff",
    transition: "background-color 0.3s",
    height: "75px",
    flex: "1 1 calc(33% - 20px)",
    boxSizing: "border-box",
  };

  const optionStyle1 = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "30px",
    margin: "10px",
    cursor: "pointer",
    backgroundColor: "#ffffff",
    transition: "background-color 0.3s",
    height: "fit-content",
    flex: "1 1 calc(33% - 20px)",
    boxSizing: "border-box",
    justifyContent: "center",
    fontWeight: "500",
    fontSize: "13px",
  };

  const selectedOptionStyle = {
    ...optionStyle,
    border: "1px solid #3A69B0",
    backgroundColor: "#e6f0ff",
  };

  const selectedOptionStyle1 = {
    ...optionStyle1,
    border: "1px solid #3A69B0",
    backgroundColor: "#e6f0ff",
    color: "#3A69B0",
    fontWeight: "500",
    fontSize: "13px",
  };

  const checkboxStyle = (isSelected) => ({
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: isSelected
      ? "2px solid transparent"
      : "2px solid rgb(204, 204, 204)",
    backgroundColor: isSelected ? "#3A69B0" : "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: "bold",
  });

  const buttonStyle = {
    padding: "8px 16px",
    borderRadius: "5px",
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
    margin: "0 5px",
    transition: "background-color 0.3s",
  };

  const continueButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#3A69B0",
    color: "#ffffff",
    border: "none",
  };

  const modalcontinueButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#DD5D59",
    color: "#ffffff",
    border: "none",
  };

  const backButtonStyle = {
    ...buttonStyle,
    backgroundColor: "transparent",
    color: "#3A69B0",
    border: "1px solid #3A69B0",
    width: "88px",
  };

  const dateSelectorContainer = {
    display: "flex",
    gap: "20px",
    overflowX: "auto",
    padding: "10px",
  };

  const dateStyle = (isSelected) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "12px 16px", // Increased padding for larger width
    minWidth: "100px", // Ensures a wider button
    cursor: "pointer",
    border: isSelected ? "1px solid #3A69B0" : "1px solid #ccc",
    borderRadius: "8px",
    backgroundColor: isSelected ? "#e6f0ff" : "#ffffff",
  });
  const grayOutStyle = {
    opacity: 0.5,
    backgroundColor: "rgb(208 208 208 / 76%)", // Change the background to light gray or any other color
    cursor: "not-allowed", // Make the cursor a "not-allowed" sign to indicate that the slot is not available
  };

  const handleBack = () => {
    if (selectedSlotOption === "mentorselection") {
      setSelectedSlotOption("my");
      setselectedMentorType(null);
      setSelectedMentorAvailableDate(null);
      setselectedMentorType(null);
      setSelectedType(null);
    } else {
      navigate(-1);
    }
  };

  const handlementorselected = () => {
    if (selectedSlotOption === "my") {
      setSelectedSlotOption("mentorselection");
      mentorsslots(selectedType);
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    getavailableslots(date);
    setSelectedTimeSlot(null);
  };

  const handleSelectedMentorDateSelect = (date) => {
    setSelectedMentorAvailableDate(date);
    getselectedmentorslots(date);
    setSelectedTimeSlot(null);
  };

  const handleTimeSlotSelection = (slot) => {
    setSelectedTimeSlot(slot);
    setIsModalOpen(true);
    setSelectedDate(selectedDate);
    getavailableslots(selectedDate, slot.id);
  };

  const handleTimeSlotSelection2 = (slot) => {
    setSelectedTimeSlot(slot);
    setIsAnotherModalOpen(true);
    setSelectedDate(selectedDate || selectedMentorAvailableDate);
    getappointmentdetailsbyslot(
      selectedDate || selectedMentorAvailableDate,
      slot.id
    );
  };

  const handleMentorSelectedTimeSlotSelection = (slot) => {
    setSelectedTimeSlot(slot);
    setSelectedDate(selectedDate);
  };

  const handleCloseModal2 = () => {
    setIsAnotherModalOpen(false);
    setSelectedTimeSlot(null);
    if (selectedSlotOption === "mentorselection") {
      setSelectedDate(null);
    } else {
      setSelectedDate(selectedDate);
    }
    setSelectedAvailableMentor(null);
    getavailableslots(selectedDate);
    setErrorMessage("");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTimeSlot(null);
    setSelectedDate(selectedDate);
    setSelectedAvailableMentor(null);
    getavailableslots(selectedDate);
    setErrorMessage("");
  };

  const bookappointment = async (
    e,
    mentorId,
    mentorName,
    slotId,
    slotName,
    date
  ) => {
    e.preventDefault();
    try {
      const res = await BookMyAppointment({
        mentorid: mentorId,
        mentorname: mentorName,
        slotid: slotId,
        slotname: slotName,
        date: date,
        type: selectedAppointmentType,
      });

      if (res.code === 200) {
        toast.success(res.message);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    } finally {
      // Clear selected values and refresh available slots immediately
      setSelectedAvailableMentor(null);
      setSelectedTimeSlot(null);
      setSelectedDate(date);
      if (selectedSlotOption === "mentor") {
        getavailableslots(date);
      } else {
        getselectedmentorslots(date);
      }
    }
  };

  const cancelappointment = async (
    e,
    mentorId,
    mentorName,
    slotId,
    slotName,
    date
  ) => {
    e.preventDefault();
    try {
      const res = await CancelMyAppointment({
        mentorid: mentorId,
        mentorname: mentorName,
        slotid: slotId,
        slotname: slotName,
        date: date,
      });

      if (res.code === 200) {
        toast.success(res.message);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    } finally {
      // Clear selected values and refresh available slots immediately
      setSelectedAvailableMentor(null);
      setSelectedTimeSlot(null);
      setIsAnotherModalOpen(false);
      // setSelectedDate(date);
      if (selectedSlotOption === "mentor") {
        getavailableslots(date);
      } else {
        getselectedmentorslots(date);
      }
    }
  };

  return (
    <div style={maincontainerstyle}>
      <div style={outerContainerStyle}>
        <div style={innerContainerStyle}>
          <h4 style={{ textAlign: "center" }}>
            3 Easy steps to book your appointment
          </h4>

          {(selectedSlotOption === "my" ||
            selectedSlotOption === "mentor" ||
            selectedSlotOption === null) && (
              <div style={cardStyle1}>
                <div
                  style={optionRowStyle}
                  onClick={() => handleSlotOption("my")}
                >
                  <div
                    style={checkboxSlotStyle(
                      selectedSlotOption === "my" ||
                      selectedSlotOption === "mentorselection"
                    )}
                  >
                    {selectedSlotOption === "my" ||
                      selectedSlotOption === "mentorselection"
                      ? "✔"
                      : ""}
                  </div>
                  <span>Choose Mentor's Slot</span>
                </div>
                <div
                  style={optionRowStyle}
                  onClick={() => handleSlotOption("mentor")}
                >
                  <div style={checkboxSlotStyle(selectedSlotOption === "mentor")}>
                    {selectedSlotOption === "mentor" ? "✔" : ""}
                  </div>

                  <span>Choose My Slot</span>
                </div>
              </div>
            )}

          {selectedSlotOption && (
            <div style={cardStyle}>
              {selectedSlotOption === "my" ? (
                <>
                  <h4
                    style={{
                      fontWeight: "bold",
                      fontSize: "17px",
                      textAlign: "left",
                      marginLeft: "12px",
                    }}
                  >
                    Select your Mentor
                  </h4>
                  <div style={mentors.length === 0 ? {} : buttonContainerStyle}>
                    {mentors.length === 0 ? (
                      <NoSlotsMessage
                      message={`No mentors available for the selected course.`}
                    />
                    ) : (
                      mentors.map((mentor) => (
                        <div
                          key={mentor.UserID}
                          style={
                            selectedType === mentor.UserID ? selectedOptionStyle : optionStyle
                          }
                          onClick={() =>
                            handleAppointmentType(mentor.UserID, mentor.MentorName)
                          }
                        >
                          <img
                            src={mentor.ProfilePic}
                            alt={mentor.MentorName}
                            style={{
                              borderRadius: "50%",
                              width: "40px",
                              height: "40px",
                              marginRight: "10px",
                              display: "inline-block",
                            }}
                            onError={(e) => {
                              e.target.onerror = null; // Prevents infinite loop if fallback fails
                              e.target.style.display = "none"; // Hide image element
                              e.target.nextSibling.style.display = "flex"; // Show initial letter fallback
                            }}
                          />
                          <div
                            style={{
                              borderRadius: "50%",
                              width: "40px",
                              height: "40px",
                              marginRight: "10px",
                              backgroundColor: "rgb(204 204 204 / 25%)",
                              color: "#333",
                              display: "none", // Hidden initially
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "20px",
                            }}
                          >
                            {mentor.MentorName.charAt(0).toUpperCase()}
                          </div>
                          <span style={{ flex: 1, textAlign: "center" }}>
                            {mentor.MentorName}
                          </span>
                          <div style={checkboxStyle(selectedType === mentor.UserID)}>
                            {selectedType === mentor.UserID ? "✔" : ""}
                          </div>
                        </div>
                      ))
                    )}
                  </div>



                </>
              ) : selectedSlotOption === "mentor" ? (
                <div>
                  <h4
                    style={{
                      fontWeight: "bold",
                      fontSize: "17px",
                      textAlign: "left",
                      marginLeft: "12px",
                    }}
                  >
                    Choose your date
                  </h4>
                  {/* Date Selection */}
                  <div style={dateSelectorContainer}>
                    {dates.map((dateObj, index) => (
                      <div
                        key={index}
                        style={dateStyle(selectedDate === dateObj.date)}
                        onClick={() => handleDateSelect(dateObj.date)}
                      >
                        <span style={{ fontSize: "12px" }}>
                          {dateObj.dayName}
                        </span>
                        <strong>
                          <span
                            style={{ fontSize: "14px" }}
                          >{`${dateObj.day} ${dateObj.month}`}</span>
                        </strong>
                      </div>
                    ))}
                  </div>

                  {/* Time Slot Selection */}
                  {selectedDate && (
                    <div style={{ marginTop: "15px" }}>
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "17px",
                          textAlign: "left",
                          marginLeft: "12px",
                        }}
                      >
                        Available Slots
                      </h4>
                      <div style={buttonContainerStyle1}>
                        {slots.map((slot) => {
                          // Check if the current slot is missing (not in availableSlotIds)
                          const isMissing = !availableSlotIds.has(slot.id);
                          const isShowMentor = AvailableSlots.some(
                            (availableSlot) =>
                              availableSlot.slotid === slot.id &&
                              availableSlot.showmentor === "your slot"
                          );

                          return (
                            <div
                              key={slot.id}
                              style={
                                isMissing
                                  ? {
                                    ...(selectedTimeSlot === slot
                                      ? selectedOptionStyle1
                                      : optionStyle1),
                                    ...grayOutStyle,
                                  }
                                  : selectedTimeSlot === slot
                                    ? selectedOptionStyle1
                                    : isShowMentor
                                      ? {
                                        ...optionStyle1,
                                        backgroundColor: "#ffc0cbb0",
                                        position: "relative", // Ensures tick is positioned at the right corner
                                      }
                                      : optionStyle1
                              }
                              onClick={() => {
                                if (!isMissing && !isShowMentor) {
                                  // Regular slot click
                                  handleTimeSlotSelection(slot);
                                  setIsModalOpen(true); // Open regular modal
                                } else if (isShowMentor) {
                                  // "Your slot" click
                                  handleTimeSlotSelection2(slot);
                                  setIsAnotherModalOpen(true); // Open different modal for "your slot"
                                }
                              }}
                            >
                              <span>{slot.slottime}</span>
                              {isShowMentor && (
                                <span
                                  style={{
                                    position: "absolute",
                                    right: "0px",
                                    top: "5px",
                                    transform: "translateY(-50%)",
                                    fontSize: "12px",
                                    color: "darkgreen",
                                    border: "1px solid darkgreen",
                                    borderRadius: "15px",
                                    width: "20px",
                                    backgroundColor: "whitesmoke",
                                    height: "20px",
                                  }}
                                >
                                  ✔
                                </span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <Modal
                    show={isModalOpen}
                    onRequestClose={handleCloseModal}
                    style={{
                      content: {
                        width: "400px",
                        margin: "auto",
                        padding: "20px",
                        borderRadius: "10px",
                        textAlign: "center",
                        position: "relative",
                        zIndex: 1000,
                      },
                      overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 999,
                      },
                    }}
                  >
                    {/* Date and Time Display with Card Styling */}
                    <div
                      style={{
                        padding: "15px 20px",
                        borderRadius: "8px",
                        backgroundColor: "#3A69B0",
                        border: "1px solid #ddd",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                    >
                      <h4
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                          color: "#fff",
                          margin: 0,
                        }}
                      >
                        {selectedDate
                          ? selectedDate.toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          })
                          : "No Date Selected"}
                      </h4>
                      <h4
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#fff",
                          margin: "-7px 0 0 0",
                        }}
                      >
                        {selectedTimeSlot
                          ? selectedTimeSlot.slottime
                          : "No Slot"}
                      </h4>
                    </div>

                    {/* Available Mentors Section */}
                    <h4
                      style={{
                        fontSize: "17px",
                        textAlign: "center",
                        marginLeft: "0px",
                        fontWeight: "500",
                      }}
                    >
                      Available Mentors
                    </h4>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: "10px",
                        fontSize: "14px",
                        textAlign: "left",
                        marginLeft: "0px",
                      }}
                    >
                      {AvailableMentors.map((mentor) => (
                        <div
                          key={mentor.mentorid}
                          onClick={() => {
                            if (mentor.showmentor !== false) {
                              setSelectedAvailableMentor(mentor);
                              setErrorMessage(""); // Clear error message
                            }
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "45%",
                            padding: "10px",
                            border:
                              selectedAvailableMentor?.mentorid ===
                                mentor.mentorid
                                ? "1px solid #4CAF50"
                                : "1px solid #ccc",
                            borderRadius: "8px",
                            cursor:
                              mentor.showmentor === false
                                ? "not-allowed"
                                : "pointer", // Disable cursor if mentor is grayed out
                            backgroundColor:
                              mentor.showmentor === false
                                ? "#D3D3D3"
                                : selectedAvailableMentor?.mentorid ===
                                  mentor.mentorid
                                  ? "#E8F5E9"
                                  : "#fff",
                            opacity: mentor.showmentor === false ? 0.5 : 1, // Reduce opacity for grayed out mentor
                            boxShadow:
                              mentor.showmentor === false
                                ? "none"
                                : "0px 4px 6px rgba(0, 0, 0, 0.1)", // Remove shadow for grayed out
                            transition:
                              "background-color 0.3s, border 0.3s, opacity 0.3s",
                            marginBottom: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ fontWeight: "500" }}>
                            {mentor.mentorname}
                          </span>
                        </div>
                      ))}
                    </div>
                    {errorMessage && (
                      <p
                        style={{
                          color: "red",
                          fontWeight: "400",
                          marginBottom: "0px",
                          marginTop: "10px", // Add spacing below the error message
                          textAlign: "center", // Center-align the text
                        }}
                      >
                        {errorMessage}
                      </p>
                    )}

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        marginTop: "20px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "5px" }}>
                        <button
                          style={{
                            ...modalcontinueButtonStyle,
                            cursor: AvailableMentors.every(
                              (mentor) => mentor.showmentor === false
                            )
                              ? "not-allowed"
                              : "pointer",
                            color: "#fff",
                          }}
                          disabled={AvailableMentors.every(
                            (mentor) => mentor.showmentor === false
                          )} // Disable button if all mentors are grayed out
                          onClick={(e) => {
                            if (!selectedAvailableMentor) {
                              setErrorMessage(
                                "Please select a mentor before booking an appointment."
                              );
                              return; // Prevent booking if no mentor is selected
                            }
                            setIsModalOpen(false);
                            setShowConfirmation(true);
                          }}
                        >
                          Book My Appointment
                        </button>
                      </div>
                    </div>

                    <button
                      onClick={handleCloseModal}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        background: "transparent",
                        border: "none",
                        fontSize: "40px",
                        color: "#000",
                        cursor: "pointer",
                      }}
                    >
                      &times;
                    </button>
                    <br />
                  </Modal>

                  <Modal
                    show={isAnotherModalOpen}
                    onRequestClose={handleCloseModal2}
                    contentLabel="Appointment Details"
                  >
                    <div
                      style={{
                        padding: "15px 20px",
                        borderRadius: "5px",
                        backgroundColor: "#3A69B0",
                        border: "1px solid #ddd",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        marginBottom: "20px",
                        textAlign: "center",
                        width: "100%",
                        maxWidth: "1000px",
                      }}
                    >
                      <h4
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                          color: "#fff",
                          margin: 0,
                        }}
                      >
                        Appointment Details
                      </h4>
                    </div>

                    {/* Booking Details in Label-Value Pair Table Format */}
                    {BookingDetails.length > 0 ? (
                      <div style={{ padding: "20px" }}>
                        <table
                          style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            marginTop: "-17px",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#fff",
                          }}
                        >
                          <tbody>
                            {BookingDetails.map((detail, index) => (
                              <React.Fragment key={index}>
                                <tr style={{ borderBottom: "1px solid #ddd" }}>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                      color: "#555",
                                      textAlign: "right",
                                      width: "30%",
                                      border: "1px solid black",
                                    }}
                                  >
                                    Date&nbsp;
                                  </td>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "17px",
                                      color: "#333",
                                      textAlign: "left",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {new Date(detail.date).toLocaleDateString(
                                      "en-GB",
                                      {
                                        day: "2-digit",
                                        month: "long",
                                        year: "numeric",
                                      }
                                    )}
                                  </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #ddd" }}>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                      color: "#555",
                                      textAlign: "right",
                                      border: "1px solid black",
                                    }}
                                  >
                                    Time&nbsp;
                                  </td>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "17px",
                                      color: "#333",
                                      textAlign: "left",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {detail.slotname}
                                  </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #ddd" }}>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                      color: "#555",
                                      textAlign: "right",
                                      border: "1px solid black",
                                    }}
                                  >
                                    Mentor&nbsp;
                                  </td>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "16px",
                                      color: "#333",
                                      textAlign: "left",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {detail.mentorname}
                                  </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #ddd" }}>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                      color: "#555",
                                      textAlign: "right",
                                      border: "1px solid black",
                                    }}
                                  >
                                    Type&nbsp;
                                  </td>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "16px",
                                      color: "#333",
                                      textAlign: "left",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {detail.type}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                        {/* Conditionally render the Cancel Meeting button outside the table */}
                        {BookingDetails.some(
                          (detail) => detail.cancelmeeting === "Yes"
                        ) && (
                            <>
                              <div
                                style={{ textAlign: "center", marginTop: "20px" }}
                              >
                                {BookingDetails.filter(
                                  (detail) => detail.cancelmeeting === "Yes"
                                ).map((detail, index) => (
                                  <>
                                    <button
                                      key={index}
                                      style={{
                                        padding: "4px 20px",
                                        fontSize: "16px",
                                        color: "#fff",
                                        backgroundColor: "#DD5D59", // red background
                                        border: "2px solid #DD5D59", // red border
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        margin: "5px", // Optional: to add space between multiple buttons
                                      }}
                                      onClick={(e) =>
                                        cancelappointment(
                                          e,
                                          detail.mentorid,
                                          detail.mentorname,
                                          detail.slotid,
                                          detail.slotname,
                                          detail.date
                                        )
                                      }
                                    >
                                      Cancel Appointment
                                    </button>
                                    <div
                                      style={{
                                        padding: "5px 25px",
                                        // border: "1px solid #DD5D59",
                                      }}
                                    >
                                      <label
                                        className="text-danger"
                                        style={{ textAlign: "center" }}
                                      >
                                        &#40; Notes : &nbsp;
                                        <span
                                          style={{
                                            textAlign: "left",
                                            color: "#555",
                                            fontWeight: "lighter",
                                          }}
                                        >
                                          Same day Appointment can not be
                                          cancelled
                                        </span>
                                        &nbsp;&#41;
                                      </label>
                                    </div>
                                  </>
                                ))}
                              </div>
                            </>
                          )}
                      </div>
                    ) : (
                      <p style={{ fontSize: "14px", color: "#555" }}>
                        No booking details available
                      </p>
                    )}

                    {/* Close Button */}
                    <button
                      onClick={handleCloseModal2}
                      style={{
                        position: "absolute",
                        top: "-7px",
                        right: "10px",
                        background: "transparent",
                        border: "none",
                        fontSize: "40px",
                        color: "#000",
                        cursor: "pointer",
                      }}
                    >
                      &times;
                    </button>
                  </Modal>
                </div>
              ) : selectedSlotOption === "mentorselection" ? (
                // Render content for "mentorselection" option
                <div>
                  <h1
                    style={{
                      fontSize: "17px",
                      textAlign: "left",
                      marginLeft: "12px",
                    }}
                  >
                    Choose your date
                  </h1>

                  {/* Check if mentordates is empty */}
                  {mentorlength === 0 ? (
                    <NoSlotsMessage
                      message={`No Time Slots Available for ${selectedMentorType} Mentor`}
                      submessage={"Please try selecting another mentor."}
                    />
                  ) : (
                    <div style={dateSelectorContainer}>
                      {mentordates.map((dateObj, index) => (
                        <div
                          key={index}
                          style={dateStyle(
                            selectedMentorAvailableDate === dateObj.date
                          )}
                          onClick={() =>
                            handleSelectedMentorDateSelect(dateObj.date)
                          }
                        >
                          <span style={{ fontSize: "12px" }}>
                            {dateObj.dayName}
                          </span>
                          <strong>
                            <span
                              style={{ fontSize: "14px" }}
                            >{`${dateObj.day} ${dateObj.month}`}</span>
                          </strong>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Time Slot Selection */}
                  {selectedMentorAvailableDate && slots.length === 0 ? (
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "20px",
                        fontSize: "16px",
                        color: "red",
                      }}
                    >
                      No Time Slots Available
                    </p>
                  ) : (
                    selectedMentorAvailableDate && (
                      <div style={{ marginTop: "15px" }}>
                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "17px",
                            textAlign: "left",
                            marginLeft: "12px",
                          }}
                        >
                          {`Available Slots for ${selectedMentorType}`}
                        </h4>
                        <div style={buttonContainerStyle1}>
                          {slots.map((slot) => {
                            // Check if the current slot is missing (not in availableSlotIds)
                            const isMissing = !selectedmentorslotids.has(
                              slot.id
                            );
                            const isShowMentor = SelectedAvailableSlots.some(
                              (availableSlot) =>
                                availableSlot.slotid === slot.id &&
                                availableSlot.showmentor === "your slot"
                            );

                            return (
                              <div
                                key={slot.id}
                                style={
                                  isMissing
                                    ? {
                                      ...(selectedTimeSlot === slot
                                        ? selectedOptionStyle1
                                        : optionStyle1),
                                      ...grayOutStyle,
                                    }
                                    : selectedTimeSlot === slot
                                      ? selectedOptionStyle1
                                      : isShowMentor
                                        ? {
                                          ...optionStyle1,
                                          backgroundColor: "#ffc0cbb0",
                                          position: "relative", // Ensures tick is positioned at the right corner
                                        }
                                        : optionStyle1
                                }
                                onClick={() => {
                                  if (!isMissing && !isShowMentor) {
                                    // Regular slot click
                                    handleMentorSelectedTimeSlotSelection(slot);
                                  } else if (isShowMentor) {
                                    // "Your slot" click
                                    handleTimeSlotSelection2(slot);
                                    setIsAnotherModalOpen(true); // Open different modal for "your slot"
                                  }
                                }}
                              >
                                <span>{slot.slottime}</span>
                                {isShowMentor && (
                                  <span
                                    style={{
                                      position: "absolute",
                                      right: "0px",
                                      top: "5px",
                                      transform: "translateY(-50%)",
                                      fontSize: "12px",
                                      color: "darkgreen",
                                      border: "1px solid darkgreen",
                                      borderRadius: "15px",
                                      width: "20px",
                                      backgroundColor: "whitesmoke",
                                      height: "20px",
                                    }}
                                  >
                                    ✔
                                  </span>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )
                  )}
                  <Modal
                    show={isAnotherModalOpen}
                    onRequestClose={handleCloseModal2}
                    contentLabel="Appointment Details"
                  >
                    <div
                      style={{
                        padding: "15px 20px",
                        borderRadius: "5px",
                        backgroundColor: "#3A69B0",
                        border: "1px solid #ddd",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        marginBottom: "20px",
                        textAlign: "center",
                        width: "100%",
                        maxWidth: "1000px",
                      }}
                    >
                      <h4
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                          color: "#fff",
                          margin: 0,
                        }}
                      >
                        Appointment Details
                      </h4>
                    </div>

                    {/* Booking Details in Label-Value Pair Table Format */}
                    {BookingDetails.length > 0 ? (
                      <div style={{ padding: "20px" }}>
                        <table
                          style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            marginTop: "-17px",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#fff",
                          }}
                        >
                          <tbody>
                            {BookingDetails.map((detail, index) => (
                              <React.Fragment key={index}>
                                <tr style={{ borderBottom: "1px solid #ddd" }}>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                      color: "#555",
                                      textAlign: "right",
                                      width: "30%",
                                      border: "1px solid black",
                                    }}
                                  >
                                    Date&nbsp;
                                  </td>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "17px",
                                      color: "#333",
                                      textAlign: "left",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {new Date(detail.date).toLocaleDateString(
                                      "en-GB",
                                      {
                                        day: "2-digit",
                                        month: "long",
                                        year: "numeric",
                                      }
                                    )}
                                  </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #ddd" }}>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                      color: "#555",
                                      textAlign: "right",
                                      border: "1px solid black",
                                    }}
                                  >
                                    Time&nbsp;
                                  </td>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "17px",
                                      color: "#333",
                                      textAlign: "left",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {detail.slotname}
                                  </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #ddd" }}>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                      color: "#555",
                                      textAlign: "right",
                                      border: "1px solid black",
                                    }}
                                  >
                                    Mentor&nbsp;
                                  </td>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "16px",
                                      color: "#333",
                                      textAlign: "left",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {detail.mentorname}
                                  </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #ddd" }}>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                      color: "#555",
                                      textAlign: "right",
                                      border: "1px solid black",
                                    }}
                                  >
                                    Type&nbsp;
                                  </td>
                                  <td
                                    style={{
                                      padding: "12px",
                                      fontSize: "16px",
                                      color: "#333",
                                      textAlign: "left",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {detail.type}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                        {/* Conditionally render the Cancel Meeting button outside the table */}
                        {BookingDetails.some(
                          (detail) => detail.cancelmeeting === "Yes"
                        ) && (
                            <div
                              style={{ textAlign: "center", marginTop: "20px" }}
                            >
                              {BookingDetails.filter(
                                (detail) => detail.cancelmeeting === "Yes"
                              ).map((detail, index) => (
                                <>
                                  <button
                                    key={index}
                                    style={{
                                      padding: "4px 20px",
                                      fontSize: "16px",
                                      color: "#fff",
                                      backgroundColor: "#DD5D59", // red background
                                      border: "2px solid #DD5D59", // red border
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                      margin: "5px", // Optional: to add space between multiple buttons
                                    }}
                                    onClick={(e) =>
                                      cancelappointment(
                                        e,
                                        detail.mentorid,
                                        detail.mentorname,
                                        detail.slotid,
                                        detail.slotname,
                                        detail.date
                                      )
                                    }
                                  >
                                    Cancel Appointment
                                  </button>
                                  <div
                                    style={{
                                      padding: "5px 25px",
                                      // border: "1px solid #DD5D59",
                                    }}
                                  >
                                    <label
                                      className="text-danger"
                                      style={{ textAlign: "center" }}
                                    >
                                      &#40; Notes : &nbsp;
                                      <span
                                        style={{
                                          textAlign: "left",
                                          color: "#555",
                                          fontWeight: "lighter",
                                        }}
                                      >
                                        Same day Appointment can not be cancelled
                                      </span>
                                      &nbsp;&#41;
                                    </label>
                                  </div>
                                </>
                              ))}
                            </div>
                          )}
                      </div>
                    ) : (
                      <p style={{ fontSize: "14px", color: "#555" }}>
                        No booking details available
                      </p>
                    )}

                    {/* Close Button */}
                    <button
                      onClick={handleCloseModal2}
                      style={{
                        position: "absolute",
                        top: "-7px",
                        right: "10px",
                        background: "transparent",
                        border: "none",
                        fontSize: "40px",
                        color: "#000",
                        cursor: "pointer",
                      }}
                    >
                      &times;
                    </button>
                  </Modal>
                </div>
              ) : null}
            </div>
          )}

          {selectedSlotOption && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                position: "relative",
                marginTop: "20px",
              }}
            >
              <div style={{ display: "flex", gap: "5px" }}>
                <button style={backButtonStyle} onClick={handleBack}>
                  Back
                </button>
                {selectedSlotOption === "mentorselection" &&
                  selectedMentorAvailableDate &&
                  selectedTimeSlot && (
                    <button
                      style={{
                        ...continueButtonStyle,
                        backgroundColor: "#3A69B0",
                      }}
                      onClick={handleBookingClick} // Show confirmation before booking
                    >
                      Book My Appointment
                    </button>
                  )}
                {selectedSlotOption !== "mentorselection" &&
                  selectedSlotOption !== "mentor" && (
                    <button
                      style={continueButtonStyle}
                      onClick={handlementorselected}
                    >
                      Continue
                    </button>
                  )}
              </div>
            </div>
          )}

          {/* Confirmation Modal */}
          {showConfirmation && (
            <div
              style={{
                position: "fixed",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                backgroundColor: "rgba(0,0,0,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  padding: "30px",
                  borderRadius: "15px",
                  width: "400px",
                  textAlign: "center",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <h3 style={{ marginBottom: "20px", fontSize: "18px" }}>
                  Are you sure you want to book the appointment?
                </h3>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "15px",
                  }}
                >
                  <button
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#28a745",
                      color: "white",
                      border: "none",
                      borderRadius: "30px",
                      cursor: "pointer",
                      fontSize: "16px",
                      transition: "0.3s",
                      width: "120px",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#218838")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#28a745")
                    }
                    onClick={(e) => {
                      confirmBooking(e);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#dc3545",
                      color: "white",
                      border: "none",
                      borderRadius: "30px",
                      cursor: "pointer",
                      fontSize: "16px",
                      transition: "0.3s",
                      width: "120px",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#c82333")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#dc3545")
                    }
                    onClick={(e) => {
                      cancelBooking(e);
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MentorSelection;
